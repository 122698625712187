import { useState, useRef } from 'react';

import { useLocale } from 'context/locale-context';

import { Button } from 'primereact/button';

import { InputContainer, InputLabel, InputErrorMessage } from 'components/Inputs';

interface ChooseClinicLogoProps {
  logo: File | null;
  setLogo: React.Dispatch<React.SetStateAction<File | null>>;
  previewLogo: string;
  setPreviewLogo: React.Dispatch<React.SetStateAction<string>>;
}

export const ChooseClinicLogo = ({
  logo,
  setLogo,
  previewLogo,
  setPreviewLogo,
}: ChooseClinicLogoProps) => {
  const { getLocaleOption } = useLocale();

  const [logoError, setLogoError] = useState(false);

  const fileRef = useRef<any>(null);

  const changeFileHandler = (e: any) => {
    const file = e.target.files[0];

    if (file.type.includes('image')) {
      setLogo(file);
      setLogoError(false);

      const imgURL = URL.createObjectURL(file);
      setPreviewLogo(imgURL);
    } else {
      setLogoError(true);
    }
  };

  return (
    <div className='col-12 lg:col-4 p-0'>
      <InputContainer>
        <InputLabel error={logoError}>{getLocaleOption('clinicLogoInfo')}</InputLabel>
        <div className='inline-block mt-1'>
          <input
            type='file'
            name='clinicLogo'
            id='clinic-logo'
            style={{ display: 'none' }}
            ref={fileRef}
            onChange={changeFileHandler}
            accept='image/*'
          />
          <Button
            label={logo ? logo.name : getLocaleOption(`chooseLogo`)}
            onClick={() => fileRef.current.click()}
          />
        </div>
        <div className='block'>
          {logoError && (
            <InputErrorMessage message={getLocaleOption('onlyImageAllowedError')} />
          )}
        </div>
      </InputContainer>
      {previewLogo && <img src={previewLogo} alt='clinic-logo' height='100px' />}
    </div>
  );
};
