import { EntityState } from './';
import { ChatStream } from './Chat';

interface UserRole {
  createdBy: string;
  createdOn: Date;
  entityState: EntityState;
  id: number;
  name: string;
  rowVersion: number;
  updatedBy: string;
  updatedOn: Date;
}

export type UserMessages = {
  chatStreamId?: number;
  unreadMessages?: number;
  totalUnreadMessagesActive?: number;
  totalUnreadMessagesArchived?: number;
}[];

export interface User {
  allowedToLogin: boolean; // Flag to indicate, whether the User is allowed to Login
  archived: boolean;
  createdBy: string; // The user, who created this Entity
  createdOn: string; // The created Timestamp for this Entity
  deletedBy: string; // The user, who deleted this Entity
  deletedOn: string; // The deleted Timestamp for this Entity
  entityState: string; // The current Entity State Enum: [ INVALID, CREATED, VALID, DELETED ]
  id: number; // The database generated Id for this Entity
  mfaEnabled: boolean;
  privileges: string[];
  rolePrivileges: string[];
  roles: UserRole[];
  rowVersion: number; // The current Version of this Entity; Used for (safe) Update Operations
  updatedBy: string; // The user, who updated this Entity
  updatedOn: string; // ($date-time)The updated Timestamp for this Entity
  username: string; // minLength: 0 maxLength: 50 Username of the User
  person?: Person;
  partnerPerson?: Person;
  partnerUserId: number;
  userState: UserState;
  clinicId: number;
  coupleId?: number;
  questionnaireFinishDate?: Date;
  partnerPatientKey: string;
  lastActionOn?: Date;
  chatStreams?: {
    size: number;
    items: ChatStream[];
  };
  messages?: UserMessages;
  totalUnreadMessages: number;
  totalUnreadMessagesActive?: number;
  totalUnreadMessagesArchived?: number;
  remarks?: string;
}

export type UserPrivileges =
  | 'IGNORE'
  | 'SECURITY_CHANGE_OWN_PASSWORD'
  | 'SECURITY_CHANGE_FOREIGN_PASSWORD'
  | 'VIS_MENU__ADMINISTRATION'
  | 'VIS_MENU__ADMIN__QUESTIONNAIRE_SPEC'
  | 'VIS_MENU__ADMIN__QUESTIONNAIRE'
  | 'VIS_MENU__ADMIN__CLINIC'
  | 'VIS_MENU__ADMIN_DIAGNOSTIC__FINDINGS'
  | 'VIS_MENU__DASHBOARD'
  | 'VIS_MENU__MY_QUESTIONNAIRES'
  | 'VIS_MENU__ADMIN__USERS'
  | 'VIS_MENU__MY_PROFILE'
  | 'ASSIGN_SAME_CLINIC_QUESTIONNAIRE'
  | 'ANSWER_SAME_CLINIC_QUESTIONNAIRE'
  | 'DELETE_SAME_CLINIC_QUESTIONNAIRE'
  | 'SEE_ALL_QUESTIONNAIRE'
  | 'SEE_SAME_CLINIC_QUESTIONNAIRE'
  | 'SEE_ALL_USER_QUESTIONNAIRE'
  | 'SEE_SAME_CLINIC_USER_QUESTIONNAIRE'
  | 'SEE_FOREIGN_FILES'
  | 'DOWNLOAD_FOREIGN_FILES'
  | 'INVITE_CLINIC_USER'
  | 'INVITE_USER'
  | 'REMIND_USER'
  | 'ACCESS_USERS_ALL'
  | 'UNRESTRICTED'
  | 'DISABLE_USER'
  | 'ARCHIVE_USER'
  | 'REACTIVATE_USER'
  | 'DELETE_USER'
  | 'DELETE_CLINIC'
  | 'UPLOAD_FOREIGN_FILES'
  | 'IS_CLIENT'
  | 'IS_CLINIC'
  | 'IS_MEDITEX_CLINIC'
  | 'IS_FERTILLY'
  | 'IS_FERTILLY_ADMIN';

export enum UserState {
  INVITED = 'INVITED',
  REGISTERED = 'REGISTERED',
  ANAMNESIS_INCOMPLETE = 'ANAMNESIS_INCOMPLETE',
  ANAMNESIS_COMPLETE = 'ANAMNESIS_COMPLETE',
  ANAMNESIS_SUBMITTED = 'ANAMNESIS_SUBMITTED',
  ANAMNESIS_TRANSFERRED = 'ANAMNESIS_TRANSFERRED',
  ANAMNESIS_PARTIALLY_TRANSFERRED = 'ANAMNESIS_PARTIALLY_TRANSFERRED',
}

enum DesiredTherapy {
  UNKNOWN = 'UNKNOWN',
}

export enum FamilyState {
  UNKNOWN = 'UNKNOWN',
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  IN_RELATIONSHIP = 'IN_RELATIONSHIP',
}

export enum Sex {
  UNKNOWN = 'UNKNOWN',
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  VARIOUS = 'VARIOUS',
}

export enum Salutation {
  NO_SALUTATION = 'NO_SALUTATION',
  MRS = 'MRS',
  MR = 'MR',
  DR = 'DR',
}

export interface Person {
  birthday: string;
  cellular: string;
  city: string;
  country: string;
  createdBy: string;
  createdOn: string;
  deletedBy: string;
  deletedOn: string;
  desiredTherapy: DesiredTherapy;
  email: string;
  entityState: EntityState;
  familyState: FamilyState;
  firstName: string;
  healthInsurance: string;
  id: number;
  lastName: string;
  occupation: string;
  phone: string;
  placeOfBirth: string;
  rowVersion: number;
  salutation: Salutation;
  sex: Sex;
  zipCode: string;
  street: string;
  updatedBy: string;
  updatedOn: string;
  surveyAttributeTreatmentPlace: string;
}

export enum UserType {
  USER = 'USER',
  ADMIN = 'ADMIN',
  FERTILLY = 'FERTILLY',
  FERTILLY_ADMIN = 'FERTILLY_ADMIN',
  CLINIC = 'CLINIC',
}

export interface Couple {
  coupleName: string;
  createdBy: string;
  createdOn: string;
  entityState: EntityState;
  id: number;
  deletedBy: string;
  deletedOn: string;
  users: {
    size: number;
    items: User[];
  };
}
