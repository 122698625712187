export const makeIOSCompatibleDate = (date: any) => {
  // 08 30 1995 date format that is passed
  // var date = new Date(2011, 01, 07); // date format that works on ios

  const [mm, dd, yyyy] = date.split(' ');
  var arr = `${yyyy}-${mm}-${dd} 00:00:00`.split(/[- :]/),
    // @ts-ignore: Unreachable code error
    parse = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);

  return parse;
};

const getISODate = (date: any) => {
  try {
    const CURRENT_DATE = new Date(date);
    // iso string will give day back ? why ? add one day to it for quick fix
    const followingDay = new Date(CURRENT_DATE.getTime() + 86400000); // + 1 day in ms
    return followingDay.toISOString();
  } catch (err) {
    // get iso date can return value if date is used as input mask in string input, in calendar inputs it will return correct values always
    return false;
  }
};

export default getISODate;
