import { useState, useEffect } from 'react';

import { Input, InputContainer } from './';

import { useLocale } from '../../context/locale-context';

interface BMIProps {
  error?: boolean;
  weight: number;
  height: number;
  updateBMI: (obj: { kg: number; cm: number; bmi: number }) => void;
  noGutter?: boolean;
}

export const BMI = ({ error, updateBMI, weight, height, noGutter }: BMIProps) => {
  const { getLocaleOption } = useLocale();

  const [KGValue, setKGValue] = useState(`${weight}`);
  const [CMValue, setCMValue] = useState(`${height}`);
  const [bmi, setBmi] = useState('0');

  useEffect(() => {
    // weight : (height)²
    const kg = parseInt(KGValue);
    const cm = parseInt(CMValue);

    if (kg === 0 || cm === 0) return;

    const meter = cm / 100;

    const bmi = kg / (meter * meter);

    setBmi(bmi.toFixed(2));

    updateBMI({ kg, cm, bmi });
  }, [KGValue, CMValue, updateBMI]);

  return (
    <InputContainer noGutter={noGutter}>
      <Input
        id='bmi-kg'
        label={getLocaleOption('weight') + ' (kg)'}
        type='number'
        value={KGValue}
        setValue={setKGValue}
        useNumberGrouping={false}
        error={error}
        minValue={1}
      />
      <Input
        id='bmi-cm'
        label={getLocaleOption('height') + ' (cm)'}
        type='number'
        value={CMValue}
        setValue={setCMValue}
        useNumberGrouping={false}
        error={error}
        minValue={1}
      />
      <p className='text-xl'>
        BMI:
        <span className='font-semibold'> {bmi}</span>
      </p>
    </InputContainer>
  );
};
