import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './assets/theme/fertilly/fertilly.css';

import Router from './Router';
import AuthContext from './context/auth-context';
import ClinicsContext from './context/clinics-context';
import LogoContext from './context/logo-context';

import './App.scss';

const App = () => {
  return (
    <AuthContext>
      <ClinicsContext>
        <LogoContext>
          <Router />
        </LogoContext>
      </ClinicsContext>
    </AuthContext>
  );
};

export default App;
