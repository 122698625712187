import { useLocale } from 'context/locale-context';

import Layout from 'components/Layout/Layout';
import { Chat } from 'components/Chat';

import { Clinic, User } from 'shared/interfaces';

interface MessagesInterface {
  user: User;
  assignedClinic: Clinic | undefined;
}

const Messages = ({ user, assignedClinic }: MessagesInterface) => {
  const { getLocaleOption } = useLocale();

  return (
    <Layout>
      <h1 className='mb-5'>
        <span className='font-light'>{getLocaleOption('clinic')} </span>
        {assignedClinic?.name}
      </h1>
      <div style={{ height: '80vh' }}>
        <Chat chatStreams={user.chatStreams?.items} chatMessages={user.messages} />
      </div>
    </Layout>
  );
};

export default Messages;
