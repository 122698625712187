import React, { ReactComponentElement } from 'react';

import { useLocale } from 'context/locale-context';
import { useAuth } from 'context/auth-context';

import Layout from 'components/Layout/Layout';
import DeleteButton from './DeleteButton';
import TwoFactorAuthentication from './TwoFactorAuthentication';

interface AccountSettingsItems {
  title: string;
  description?: string;
  actionComponent: ReactComponentElement<any> | null;
}

const AccountSettings = () => {
  const { getLocaleOption } = useLocale();
  const { user } = useAuth();

  const items: AccountSettingsItems[] = [
    {
      title: getLocaleOption('2faTitle'),
      description: getLocaleOption('2faInfoDescription'),
      actionComponent: user ? (
        <TwoFactorAuthentication mfaEnabled={user.mfaEnabled} />
      ) : null,
    },
    {
      title: getLocaleOption('deleteAccount'),
      actionComponent: user ? <DeleteButton /> : null,
    },
  ];

  return (
    <Layout>
      <h3 className='m-0 p-0 break-all col-4'>{getLocaleOption('accountSettings')}</h3>
      <div className='mt-4'>
        {items.map((item, index) => (
          <div className='card' key={`account-settings-${index}`}>
            <div className='p-3 pl-0 pr-0 flex justify-content-between'>
              <div className='flex flex-wrap align-content-center'>
                <h4 className='m-0'>{item.title}</h4>
                <p className='mt-3'>{item.description}</p>
              </div>
              <div className='flex flex-wrap justify-content-end align-content-center col-2 p-0'>
                {item.actionComponent}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default AccountSettings;
