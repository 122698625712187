export const infoContent = [
  {
    id: 691389173,
    preview: require('assets/images/infoPreviews/Ablauf der Eizellentnahme.jpg'),
    title: 'eggRetrieval',
    description: 'eggRetrievalDescription',
  },
  {
    id: 691388364,
    preview: require('assets/images/infoPreviews/Risiken in der Kinderwunschbehandlung.jpg'),
    title: 'risksOfFertilityTreatment',
    description: 'risksOfFertilityTreatmentDescription',
  },
  {
    id: 691353414,
    preview: require('assets/images/infoPreviews/Alter _ Erfolgschancen.jpg'),
    title: 'ageAndChancesOfSuccess',
    description: 'ageAndChancesOfSuccessDescription',
  },
  {
    id: 691359154,
    preview: require('assets/images/infoPreviews/Kryokonservierung.jpg'),
    title: 'cryopreservation',
    description: 'cryopreservationDescription',
  },
  {
    id: 691359988,
    preview: require('assets/images/infoPreviews/Mann.jpg'),
    title: 'diagnosticsForMen',
    description: 'diagnosticsForMenDescription',
  },
  {
    id: 691349700,
    preview: require('assets/images/infoPreviews/Blastozystenkultur.jpg'),
    title: 'blastocystCulture',
    description: 'blastocystCultureDescription',
  },
  {
    id: 691359048,
    preview: require('assets/images/infoPreviews/Lifestyle, Ernährung _ Supplementation.jpg'),
    title: 'lifestyleNutritionAndSupplementation',
    description: 'lifestyleNutritionAndSupplementationDescription',
  },
  {
    id: 691347957,
    preview: require('assets/images/infoPreviews/Single Embryotransfer.jpg'),
    title: 'singleEmbryoTransfer',
    description: 'singleEmbryoTransferDescription',
  },
];
