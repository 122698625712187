export enum Token {
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  ACCESS_TOKEN_VALID_UNTIL = 'ACCESS_TOKEN_VALID_UNTIL',
  REFRESH_TOKEN_VALID_UNTIL = 'REFRESH_TOKEN_VALID_UNTIL',
  LOCALE_TOKEN = 'LOCALE_TOKEN',
}

export const getToken = (type: Token) => {
  try {
    const token = localStorage.getItem(type);
    return token ? token : undefined;
  } catch (err) {
    return undefined;
  }
};

export const getAllTokens = () => {
  try {
    const accessToken = localStorage.getItem(Token.ACCESS_TOKEN);
    const refreshToken = localStorage.getItem(Token.REFRESH_TOKEN);
    let accessTokenValidUntil = localStorage.getItem(Token.ACCESS_TOKEN_VALID_UNTIL);
    let refreshTokenValidUntil = localStorage.getItem(Token.REFRESH_TOKEN_VALID_UNTIL);
    if (
      !accessToken ||
      !refreshToken ||
      !accessTokenValidUntil ||
      !refreshTokenValidUntil
    ) {
      return undefined;
    }

    accessTokenValidUntil = new Date(accessTokenValidUntil).toISOString();
    refreshTokenValidUntil = new Date(refreshTokenValidUntil).toISOString();

    return {accessToken, refreshToken, accessTokenValidUntil, refreshTokenValidUntil};
  } catch (err) {
    return undefined;
  }
};

export const setToken = (tokenName: Token, token: string) => {
  try {
    localStorage.setItem(tokenName, token);
  } catch (err) {
    return false;
  }
};

export const setAllTokens = (
  accessToken: string,
  refreshToken: string,
  accessTokenValidUntil: string,
  refreshTokenValidUntil: string
) => {
  try {


    localStorage.setItem(Token.ACCESS_TOKEN, accessToken);
    localStorage.setItem(Token.REFRESH_TOKEN, refreshToken);
    localStorage.setItem(Token.ACCESS_TOKEN_VALID_UNTIL, accessTokenValidUntil);
    localStorage.setItem(Token.REFRESH_TOKEN_VALID_UNTIL, refreshTokenValidUntil);
  } catch (err) {
    return false;
  }
};

export const removeTokens = () => {
  try {
    localStorage.removeItem(Token.ACCESS_TOKEN);
    localStorage.removeItem(Token.REFRESH_TOKEN);
    localStorage.removeItem(Token.ACCESS_TOKEN_VALID_UNTIL);
    localStorage.removeItem(Token.REFRESH_TOKEN_VALID_UNTIL);
  } catch (err) {
    return false;
  }
};
