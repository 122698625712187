import { Fragment } from 'react';
import Overlay from '../../../components/Overlay';
import { TableUser } from '../UsersList';
import UserRemarks from './UserRemarks';

const UserListInterfaceBody = ({ users, setUsers, label, user }: {users: TableUser[], user: TableUser, setUsers: React.Dispatch<React.SetStateAction<TableUser[]>>, label: string}) => {
  const remarks: string | undefined = user.remarks;
  return (
    <Fragment>
      <Overlay
        style={{ width: '400px' }}
        className={remarks ? 'p-button-text' : ''}
        label={label}
      >
        <UserRemarks user={user} users={users} setUsers={setUsers} />
      </Overlay>
    </Fragment>
  )
};

export default UserListInterfaceBody;
