import { useLocale } from 'context/locale-context';

import { ConfirmDialog } from 'primereact/confirmdialog';

import { Input } from 'components/Inputs';

import {
  AddUpdateGroupDialogState,
  UserGroupsDialogOptions,
} from './HandleUserGroups.types';

interface HandleUserGroupsDialogProps {
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  dialogType: AddUpdateGroupDialogState;
}

export const HandleUserGroupsDialog = ({
  visible,
  onClose,
  onSubmit,
  inputValue,
  setInputValue,
  dialogType,
}: HandleUserGroupsDialogProps) => {
  const { getLocaleOption } = useLocale();

  return (
    <ConfirmDialog
      visible={visible}
      onHide={onClose}
      header={getLocaleOption(
        dialogType === UserGroupsDialogOptions.add ? 'addGroup' : 'renameGroup'
      )}
      icon='pi pi-save'
      message={getLocaleOption(
        dialogType === UserGroupsDialogOptions.add ? 'addGroup' : 'renameGroup'
      )}
      footer={
        <>
          <Input
            id='confirm-dialog-input'
            value={inputValue}
            setValue={setInputValue}
            placeholder={getLocaleOption('groupName')}
          />
          <button
            className='p-button p-component p-confirm-dialog-reject p-button-text'
            onClick={onClose}
          >
            <span className='p-button-label p-c'>{getLocaleOption('cancel')}</span>
          </button>
          <button
            disabled={!inputValue}
            className='p-button p-component p-confirm-dialog-accept'
            onClick={onSubmit}
          >
            <span className='p-button-label p-c'>
              {getLocaleOption(
                dialogType === UserGroupsDialogOptions.add ? 'add' : 'update'
              )}
            </span>
          </button>
        </>
      }
    />
  );
};
