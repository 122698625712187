import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';

import { useAPI } from 'context/api-context';
import { useSettings } from 'context/settings-context';
import { useClinics } from 'context/clinics-context';
import { useLocale } from 'context/locale-context';

import { Button } from 'primereact/button';

import Layout from 'components/Layout/Layout';
import { Input, uploadFileHandler } from 'components/Inputs';
import { ChooseClinicLogo } from './ChooseClinicLogo';
import { HandleUserGroups } from './HandleUserGroups';

import {
  CheckedElement,
  Clinic as ClinicInterface,
  ClinicProperties,
} from 'shared/interfaces';
import { HandleClinicInterface } from './HandleClinicInterface';
import { InputTextarea } from 'primereact/inputtextarea';

const clinicInterfaceValues = (
  getLocaleOption: (option: string) => string
): CheckedElement[] => [
  {
    name: ClinicProperties.noInterface,
    title: getLocaleOption(ClinicProperties.noInterface.toLocaleLowerCase()),
    checked: true,
  },
  {
    name: ClinicProperties.meditex,
    title: getLocaleOption(ClinicProperties.meditex.toLocaleLowerCase()),
    checked: false,
  },
  {
    name: ClinicProperties.vrepro,
    title: getLocaleOption(ClinicProperties.vrepro.toLocaleLowerCase()),
    checked: false,
  },
  {
    name: ClinicProperties.gdt,
    title: getLocaleOption(ClinicProperties.gdt.toLocaleLowerCase()),
    checked: false,
  },
];

const CreateEditClinic = () => {
  const { fetchAPI, fetchFileAPI } = useAPI();
  const { showToast } = useSettings();
  const { getClinics } = useClinics();
  const { getLocaleOption } = useLocale();

  const location = useLocation();

  const state = location.state as ClinicInterface;

  const [fetching, setFetching] = useState(false);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);

  const [clinicProperty, setClinicProperty] = useState(
    clinicInterfaceValues(getLocaleOption)
  );
  const [secretKey, setSecretKey] = useState('');
  const [secretKeyError, setSecretKeyError] = useState(false);

  const [logo, setLogo] = useState<File | null>(null);
  const [previewLogo, setPreviewLogo] = useState('');

  const [terms, setTerms] = useState('');

  useEffect(() => {
    if (state) {
      const setClinicData = async () => {
        setName(state.name);

        const checkedClinicInterface = clinicInterfaceValues(getLocaleOption).map(
          (i) => ({
            name: i.name,
            title: i.title,
            checked: state.clinicProperty === i.name,
          })
        );
        setClinicProperty(checkedClinicInterface);

        state.clinicProperty !== ClinicProperties.noInterface &&
          state.secretKey &&
          setSecretKey(state.secretKey);

        setTerms(state.termsAndConditions);

        if (state.logoFileUUID) {
          const logoBlob = await fetchFileAPI(`/files/uuid=${state.logoFileUUID}/data/`);

          const imgURL = URL.createObjectURL(logoBlob);
          setPreviewLogo(imgURL);
        }
      };

      setClinicData();
    } else {
      setName('');
      setSecretKey('');
      setPreviewLogo('');
      setTerms('');
    }
  }, [state, fetchFileAPI, getLocaleOption]);

  useEffect(() => {
    if (name !== '') setNameError(false);
    if (secretKey !== '') setSecretKeyError(false);
  }, [name, setNameError, secretKey, secretKeyError]);

  const checkedClinicProperty = clinicProperty.find((i) => i.checked)?.name;
  const hasInterface = checkedClinicProperty !== ClinicProperties.noInterface;

  useEffect(() => {
    if (state) {
      checkedClinicProperty !== ClinicProperties.noInterface &&
      checkedClinicProperty !== state.clinicProperty
        ? setSecretKey('')
        : setSecretKey(state.secretKey);
    }
  }, [checkedClinicProperty, state]);

  const handleSubmit = async () => {
    if (!name) return setNameError(true);
    if (hasInterface && secretKey === '') return setSecretKeyError(true);

    setFetching(true);

    let uploadedLogo: any = null;

    if (logo) {
      uploadedLogo = await uploadFileHandler([logo]);
    }

    const url = state ? `/clinics/${state.id}/` : '/clinics/';
    const logoFileUUID = logo
      ? uploadedLogo.items[0].uuid
      : state
      ? state.logoFileUUID
      : null;

    const json = await fetchAPI(url, {
      method: 'POST',
      withToken: true,
      body: JSON.stringify({
        name,
        clinicProperty: checkedClinicProperty,
        secretKey: hasInterface ? secretKey : '',
        logoFileUUID,
        termsAndConditions: terms,
      }),
    });

    if (json.id) {
      showToast({
        type: 'success',
        title: getLocaleOption('clinicCreated'),
        text: getLocaleOption('clinicCreatedSuccess'),
      });

      getClinics();
    } else {
      showToast({
        type: 'error',
        title: getLocaleOption('error'),
        text: getLocaleOption('somethingWentWrong'),
      });
    }

    !hasInterface && setSecretKey('');
    setFetching(false);
  };

  return (
    <Layout>
      <h2 className='mb-5 mt-0'>
        {state ? getLocaleOption('edit') : getLocaleOption('create')}{' '}
        {getLocaleOption('clinic')}
      </h2>
      <div className="card">
        <Input
          value={name}
          setValue={setName}
          id='clinic-name'
          label={getLocaleOption('clinicName')}
          error={nameError}
        />
        <div className='flex justify-between flex-column lg:flex-row col-12 p-0'>
          <HandleClinicInterface
            clinicProperty={clinicProperty}
            setClinicProperty={setClinicProperty}
            hasInterface={hasInterface}
            checkedClinicProperty={checkedClinicProperty}
            secretKey={secretKey}
            setSecretKey={setSecretKey}
            secretKeyError={secretKeyError}
          />
        </div>
        <div className='flex justify-between flex-column lg:flex-row col-12 p-0 mb-5'>
          <ChooseClinicLogo
            logo={logo}
            setLogo={setLogo}
            previewLogo={previewLogo}
            setPreviewLogo={setPreviewLogo}
          />

          {state && <HandleUserGroups clinicId={state.id} />}
        </div>
        <div>
          <h3>{getLocaleOption('termsAndConditions')}</h3>
          <InputTextarea
            rows={10}
            value={terms}
            onChange={(e) => setTerms(e.target.value)}
            className='block w-full mb-2'
          />
        </div>
        <div className='flex justify-content-end'>
          <Button
            label={getLocaleOption(state ? 'edit' : 'submit')}
            onClick={handleSubmit}
            loading={fetching}
          />
        </div>
      </div>
    </Layout>
  );
};

export default CreateEditClinic;
