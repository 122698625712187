import { EntityState } from './';

export enum QuestionnaireType {
  FILLING = 'FILLING',
  BUILDING = 'BUILDING',
}

export enum PersonType {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  UNKNOWN = 'UNKNOWN',
}

export enum SurveyType {
  MEDICAL = 'MEDICAL',
  MEDICAL_PDF = 'MEDICAL_PDF',
  BASE_DATA = 'BASE_DATA',
}

export interface Questionnaire {
  edit?: boolean;
  clinicId: number;
  clinicName: string;
  createdBy?: string; // The user, who created this Entity
  createdOn?: Date; // The created Timestamp for this Entity
  deletedBy?: string; // The user, who deleted this Entity
  deletedOn?: Date; // The deleted Timestamp for this Entity
  entityState?: EntityState; // The current Entity State
  surveyJSJson: string;
  answers?: string;
  id: number | null; // The database generated Id for this Entity
  name: string;
  rowVersion?: number; // The current Version of this Entity; Used for (safe) Update Operations
  updatedBy?: string; // The user, who updated this Entity
  updatedOn?: Date; // The updated Timestamp for this Entity
  personTypeId?: string;
  completed?: boolean;
  completedOn?: string;
  partnerLastSubmissionId?: string;
  partnerLastSubmissionOn?: string;
  partnerPatientId?: string;
  surveyType: SurveyType;
}
