import { useEffect, useState, useMemo, useCallback } from 'react';
import { useParams } from 'react-router';

import { Model } from 'survey-core';

import { useAPI } from 'context/api-context';
import { useLocale } from 'context/locale-context';

import { Questionnaire as QuestionnaireInterface } from 'shared/interfaces';

import 'survey-core/survey.min.css';

export const useQuestionnaireRunner = (
  userId?: number,
  additionalCallback?: () => Promise<void>
) => {
  const { id } = useParams();
  const { fetchAPI } = useAPI();
  const { locale } = useLocale();

  const [fetching, setFetching] = useState(false);
  const [surveyModel, setSurveyModel] = useState<QuestionnaireInterface | null>(null);
  const [surveyCompleted, setSurveyCompleted] = useState(false);
  const [currentSurveyCompleted, setCurrentSurveyCompleted] = useState(false);
  const [noQuestionnaireData, setNoQuestionnaireData] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setFetching(true);
      setCurrentSurveyCompleted(false);
      setNoQuestionnaireData(false);

      const response = await fetchAPI(
        userId
          ? `/usersurveys/?user_id=${userId}&survey_type=BASE_DATA&map_json=true`
          : `/usersurveys/${id}/`,
        {
          withToken: true,
        }
      );

      const surveyResponse: QuestionnaireInterface = userId
        ? response.items[0]
        : response;

      if (surveyResponse) {
        setSurveyModel(surveyResponse);
        setCurrentSurveyCompleted(!!surveyResponse?.completed);
      } else {
        setNoQuestionnaireData(true);
      }

      setFetching(false);
    }

    fetchData();
  }, [id, fetchAPI, userId]);

  const survey = useMemo(
    () => new Model(surveyModel?.surveyJSJson),
    [surveyModel?.surveyJSJson]
  );

  useEffect(() => {
    survey.sendResultOnPageNext = true;
    survey.data = surveyModel?.answers && JSON.parse(surveyModel.answers);
  }, [survey, surveyModel?.answers]);

  useEffect(() => {
    survey.locale = locale;
  }, [survey, locale]);

  const saveSurveyData = useCallback(
    async (sender: any) => {
      const answers = sender.data;
      const completed = sender.isCompleted;

      answers.pageNo = sender.currentPageNo;

      setSurveyCompleted(completed);
      setCurrentSurveyCompleted(completed);

      await fetchAPI(`/usersurveys/${id || surveyModel?.id}/`, {
        method: 'PUT',
        withToken: true,
        body: JSON.stringify({
          ...surveyModel,
          completed,
          answers: JSON.stringify(answers),
        }),
      });

      additionalCallback && await additionalCallback();
    },
    [additionalCallback, fetchAPI, id, surveyModel]
  );

  useEffect(() => {
    survey.onPartialSend.add(saveSurveyData);
    survey.onComplete.add(saveSurveyData);

    return () => {
      survey.onPartialSend.remove(saveSurveyData);
      survey.onComplete.remove(saveSurveyData);
    };
  }, [saveSurveyData, survey.onComplete, survey.onPartialSend]);

  return {
    fetching,
    surveyModel,
    survey,
    surveyCompleted,
    currentSurveyCompleted,
    noQuestionnaireData,
  };
};
