import { useState } from 'react';

import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';

import { useLocale } from 'context/locale-context';

import { useAPI } from '../../../context/api-context';
import { ILazyLoadingParams, TableUser, TableUserFilters } from '../UsersList';

interface ExportTableProps {
  params: ILazyLoadingParams;
}

const ExportTable = ({ params }: ExportTableProps) => {
  const { getLocaleOption } = useLocale();

  const [exporting, setExporting] = useState(false);
  const { fetchFileAPI } = useAPI();

  const exportHandler = async () => {
    if (!exporting) {
      setExporting(true);

      const getSortingFieldHTTPName = (field: keyof TableUser): string => {
        return ({
          'id': 'ID,',
          'firstName': 'FIRST_NAME',
          'lastName': 'LAST_NAME',
          'username': 'EMAIL',
          'birthday': 'BIRTH_DATE',
          'createdOn': 'INVITE_DATE',
          'lastActionOn': 'LAST_ACTION_DATE',
          'totalUnreadMessages': 'MESSAGE_UNREAD',
          'partnerPatientKey': 'PATIENT_ID',
          'userState': 'STATUS',
        } as any)[field];
      };

      const filters: TableUserFilters = params.filters as TableUserFilters;
      const filterText: string = (filters['global'] as any).value || '';
      // field filter values
      const firstName: string = filters.firstName.value;
      const lastName: string = filters.lastName.value;
      const email: string = filters.username.value;
      const birthday: string = filters.birthday.value;
      const partnerPatientKey: string = filters.partnerPatientKey.value;
      const userState: string = filters.userState.value;
      const totalUnreadMessages: string = filters.totalUnreadMessages.value;
      const sortField: string | undefined= getSortingFieldHTTPName(params.sortField as keyof TableUser);
      const sortOrder: number | undefined | null = params.sortOrder;
      const sortOrderOption: 'ASC' | 'DESC' = sortOrder === 1 ? 'ASC' : 'DESC';

      const queryParams: string = [
        'filter_text=' + filterText,
        'page=' + params.page,
        'size=' + params.rows,
        'filter_archived=' + params.showOnlyArchived,
        !!sortField ? `sort=${sortField}:${sortOrderOption}` : undefined,
        // field filter params
        firstName ? `filter_first_name=${firstName}` : undefined,
        lastName ? `filter_last_name=${lastName}` : undefined,
        email ? `filter_email=${email}` : undefined,
        birthday ? `filter_birth_date=${birthday}` : undefined,
        partnerPatientKey ? `filter_patient_id=${partnerPatientKey}` : undefined,
        userState ? `filter_states=${userState}` : undefined,
        totalUnreadMessages ? `filter_messages_read_states=${totalUnreadMessages}` : undefined,
      ]
        .filter(Boolean)
        .join('&');

      const response: Blob = await fetchFileAPI(`/users/_xls/?${queryParams}`);
      const downloadLink: HTMLAnchorElement = document.createElement('a');
      const fileName: string = `${new Date().toLocaleDateString().replace('/', '_')}_${getLocaleOption('users')}.xlsx`;
      downloadLink.href = window.URL.createObjectURL(response);
      downloadLink.setAttribute('download', fileName);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      setExporting(false);
    }
  };

  return (
    <div className='export-users'>
      <Button
        type='button'
        icon='pi pi-file-excel'
        className='p-button-success mr-2'
        data-pr-tooltip='Export Table'
        onClick={exportHandler}
      />
      <Tooltip target='.export-users>button' position='bottom' />
    </div>
  );
};

export default ExportTable;
