import { createContext, useState, useContext, useRef, useCallback } from 'react';

import { Toast as PrimeToast } from 'primereact/toast';

import Dialog from '../components/Dialog';

import { useLocale } from '../context/locale-context';

interface Settings {
  error: boolean;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  errorHandler: (err: unknown) => void;
  showToast: (toast: ToastProps) => void;
  somethingWentWrongToast: () => void;
}

interface ToastProps {
  type: 'success' | 'info' | 'warn' | 'error';
  title: string;
  text: string;
  timeout?: number;
  cb?: () => void;
  onHide?: () => void;
}

const DEFAULT_TOAST_TIMEOUT = 3000;

export const SettingsContext = createContext<Settings>(null!);

const SettingsContextProvider: React.FC = ({ children }) => {
  const { getLocaleOption } = useLocale();

  const [error, setError] = useState(false);
  const [errorObject, setErrorObject] = useState<any>();

  const errorHandler = (err: unknown) => {
    console.error(err);
    setError(true);
    setErrorObject(err);
  };

  const toastRef: any = useRef(null);

  const showToast: (toast: ToastProps) => void = useCallback(
    ({ type, title, text, timeout }) => {
      toastRef.current!.show({
        severity: type,
        summary: title,
        detail: text,
        life: timeout ? timeout : DEFAULT_TOAST_TIMEOUT,
      });
    },
    []
  );

  const somethingWentWrongToast = useCallback(
    () =>
      showToast({
        type: 'error',
        title: getLocaleOption('error'),
        text: getLocaleOption('somethingWentWrong'),
      }),
    [getLocaleOption, showToast]
  );

  return (
    <SettingsContext.Provider
      value={{
        error,
        setError,
        errorHandler,
        showToast,
        somethingWentWrongToast,
      }}
    >
      <PrimeToast ref={toastRef} />
      <Dialog header={errorObject} visible={error} onHide={() => setError(false)}>
        <p>{getLocaleOption('serverProblem')}</p>
      </Dialog>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => useContext(SettingsContext);

export default SettingsContextProvider;
