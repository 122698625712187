import { createContext, useState, useContext, useEffect, ReactNode } from 'react';

import { useAPI } from './api-context';
import { useClinics } from './clinics-context';

import fertillyLogo from 'assets/logo.svg';
import { ClinicLogoOptionCodes, LogoResponse } from '../shared/interfaces/';

interface Logo {
  logo: string | null;
}

interface LogoContextProviderProps {
  children: ReactNode;
}

const LogoContextDefault = {
  logo: null,
};

export const LogoContext = createContext<Logo>(LogoContextDefault);

const LogoContextProvider = ({ children }: LogoContextProviderProps) => {
  const { assignedClinic } = useClinics();
  const { fetchAPI } = useAPI();

  const [logo, setLogo] = useState<string | null>(null);

  useEffect(() => {
    const getLogo = async () => {
      if (assignedClinic?.showLogo === ClinicLogoOptionCodes.none) {
        localStorage.setItem('logo', ClinicLogoOptionCodes.none);
        localStorage.removeItem('id');
        return;
      }

      if (assignedClinic?.showLogo === ClinicLogoOptionCodes.fertilly) {
        localStorage.setItem('logo', ClinicLogoOptionCodes.fertilly);
        localStorage.removeItem('id');
        setLogo(fertillyLogo);
        return;
      }

      if (
        assignedClinic?.showLogo === ClinicLogoOptionCodes.clinic &&
        assignedClinic?.id
      ) {
        const clinicLogo: LogoResponse = await fetchAPI(
          `/clinics/${assignedClinic.id}/logofile/`,
          {
            withToken: true,
          }
        );

        const result = `data:image/png;base64,${clinicLogo.data}`;
        setLogo(result);
        localStorage.setItem('logo', ClinicLogoOptionCodes.clinic);
        localStorage.setItem('id', assignedClinic.id.toString());
        return;
      }
    };

    getLogo();
  }, [assignedClinic?.id, assignedClinic?.showLogo, fetchAPI]);

  return (
    <LogoContext.Provider
      value={{
        logo,
      }}
    >
      {children}
    </LogoContext.Provider>
  );
};

export const useLogo = () => useContext(LogoContext);

export default LogoContextProvider;
