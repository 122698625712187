import { useLocale } from '../../context/locale-context';

interface InputLabelProps {
  id?: string;
  error?: boolean;
}

export const InputLabel = ({
  id,
  error,
  children,
}: React.PropsWithChildren<InputLabelProps>) => (
  <label
    htmlFor={id}
    className={`block font-semibold mb-1 text-xl ${error ? 'p-error' : ''}`}
  >
    {children}
  </label>
);

interface InputContainerProps {
  noGutter?: boolean;
}

export const InputContainer = ({
  children,
  noGutter = false,
}: React.PropsWithChildren<InputContainerProps>) => (
  <div className={`p-fluid ${!noGutter ? 'grid' : 'grid-nogutter'}`}>
    <div className='col-12 mb-1'>{children}</div>
  </div>
);

interface InputErrorMessageProps {
  message?: string;
}

export const InputErrorMessage = ({
  message,
}: React.PropsWithChildren<InputErrorMessageProps>) => {
  const { getLocaleOption } = useLocale();

  return (
    <small className='absolute p-error'>
      {message ? message : getLocaleOption('fieldRequired')}
    </small>
  );
};

export * from './Input';
export * from './Calendar';
export * from './Checkbox';
export * from './Dropdown';
export * from './Radio';
export * from './Signature';
export * from './FileUpload';
export * from './MultiValue';
export * from './Divider';
export * from './BMI';
export * from './Editor';
