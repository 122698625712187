import { createContext, useState, useContext, useEffect, useCallback } from 'react';

import { addLocale, localeOption } from 'primereact/api';

import Auth from '../locales/Auth.json';
import Inputs from '../locales/Inputs.json';
import Questionnaire from '../locales/Questionnaire.json';
import General from '../locales/General.json';
import Users from '../locales/User.json';
import Components from '../locales/Components.json';
import Clinics from '../locales/Clinics.json';
import Settings from '../locales/Settings.json';
import EuropeanCountries from '../locales/EuropeanCountries.json';
import Videos from '../locales/Videos.json';

import { Token, setToken, getToken } from '../shared/helpers/localStorage';

const LOCALE_NAME = 'fertilly';

export enum Locales {
  EN = 'en',
  DE = 'de',
  FR = 'fr',
  IT = 'it',
  ES = 'es',
  TR = 'tr',
  AR = 'ar',
  PT = 'pt',
  NL = 'nl',
}

interface Locale {
  locale: Locales;
  changeLocale: (locale: Locales) => void;
  getLocaleOption: (option: string) => string;
}

export const LocaleContext = createContext<Locale>(null!);

const LocaleContextProvider: React.FC = ({ children }) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const [locale, setLocale] = useState(Locales.DE);

  const changeLocale = (locale: Locales) => {
    setLocale(locale);
    setToken(Token.LOCALE_TOKEN, locale);
  };

  useEffect(() => {
    const json = {
      ...Auth,
      ...Inputs,
      ...Questionnaire,
      ...General,
      ...Users,
      ...Components,
      ...Clinics,
      ...Settings,
      ...EuropeanCountries,
      ...Videos,
    };

    addLocale(LOCALE_NAME, json);

    const token = getToken(Token.LOCALE_TOKEN);

    if (token === Locales.EN) setLocale(Locales.EN);
    if (token === Locales.DE) setLocale(Locales.DE);
    if (token === Locales.FR) setLocale(Locales.FR);
    if (token === Locales.IT) setLocale(Locales.IT);
    if (token === Locales.ES) setLocale(Locales.ES);
    if (token === Locales.TR) setLocale(Locales.TR);
    if (token === Locales.AR) setLocale(Locales.AR);
    if (token === Locales.PT) setLocale(Locales.PT);
    if (token === Locales.NL) setLocale(Locales.NL);

    setFirstLoad(false);
  }, []);

  const getLocaleOption = useCallback(
    (option: string) => {
      try {
        const getOption = localeOption(option, LOCALE_NAME);

        switch (locale) {
          case Locales.DE:
            return getOption.de || getOption.en;
          case Locales.FR:
            return getOption.fr || getOption.en;
          case Locales.IT:
            return getOption.it || getOption.en;
          case Locales.ES:
            return getOption.es || getOption.en;
          case Locales.TR:
            return getOption.tr || getOption.en;
          case Locales.AR:
            return getOption.ar || getOption.en;
          case Locales.PT:
            return getOption.pt || getOption.en;
          case Locales.NL:
            return getOption.nl || getOption.en;
          default:
            return getOption.en;
        }
      } catch (err) {
        return 'Translation not found';
      }
    },
    [locale]
  );

  return (
    <LocaleContext.Provider
      value={{
        locale,
        changeLocale,
        getLocaleOption,
      }}
    >
      {!firstLoad ? children : null}
    </LocaleContext.Provider>
  );
};

export const useLocale = () => useContext(LocaleContext);

export const getLocaleOptionInFunction: (option: string, locale: Locales) => string = (
  option,
  locale
) => {
  try {
    const getOption = localeOption(option, LOCALE_NAME);

    switch (locale) {
      case Locales.DE:
        return getOption.de || getOption.en;
      case Locales.FR:
        return getOption.fr || getOption.en;
      case Locales.IT:
        return getOption.it || getOption.en;
      case Locales.ES:
        return getOption.es || getOption.en;
      case Locales.TR:
        return getOption.tr || getOption.en;
      case Locales.AR:
        return getOption.ar || getOption.en;
      case Locales.PT:
        return getOption.pt || getOption.en;
      case Locales.NL:
        return getOption.nl || getOption.en;
      default:
        return getOption.en;
    }
  } catch (err) {
    return 'Translation not found';
  }
};

export default LocaleContextProvider;
