import React, { useState } from 'react';

import { useClinics } from 'context/clinics-context';
import { useAPI } from 'context/api-context';

import { DropdownChangeParams, Dropdown } from 'primereact/dropdown';

import {
  Clinic as ClinicInterface,
  ClinicLogoOptionCodes,
  ClinicLogoOptionNames,
} from 'shared/interfaces';

interface ChangeLogoOptionsProps {
  clinic: ClinicInterface;
  clinics: ClinicInterface[];
  setClinics: React.Dispatch<React.SetStateAction<ClinicInterface[]>>;
}

const getLogoOptionCode = (option: string) => {
  switch (option) {
    case ClinicLogoOptionNames.none:
      return ClinicLogoOptionCodes.none;
    case ClinicLogoOptionNames.clinic:
      return ClinicLogoOptionCodes.clinic;
    case ClinicLogoOptionNames.fertilly:
      return ClinicLogoOptionCodes.fertilly;
    default:
      return ClinicLogoOptionCodes.none;
  }
};

const getLogoOptionName = (option: string) => {
  switch (option) {
    case ClinicLogoOptionCodes.none:
      return ClinicLogoOptionNames.none;
    case ClinicLogoOptionCodes.clinic:
      return ClinicLogoOptionNames.clinic;
    case ClinicLogoOptionCodes.fertilly:
      return ClinicLogoOptionNames.fertilly;
    default:
      return ClinicLogoOptionNames.none;
  }
};

const logoOptionsDefault = [
  ClinicLogoOptionNames.none,
  ClinicLogoOptionNames.clinic,
  ClinicLogoOptionNames.fertilly,
];

const ChangeLogoOptions = ({ clinic, clinics, setClinics }: ChangeLogoOptionsProps) => {
  const { getClinics } = useClinics();
  const { fetchAPI } = useAPI();

  const [logoOptions, setLogoOptions] = useState(getLogoOptionName(clinic.showLogo));

  const handleChange = async (e: DropdownChangeParams) => {
    const { value } = e;

    setLogoOptions(value);

    const updatedClinic = {
      ...clinic,
      showLogo: getLogoOptionCode(value),
    };

    const response = await fetchAPI(`/clinics/${clinic.id}/`, {
      method: 'POST',
      withToken: true,
      body: JSON.stringify(updatedClinic),
    });

    const map = clinics.map((c) => (c.id === clinic.id ? response : c));
    setClinics(map);
    getClinics();
  };

  return (
    <div className='w-8'>
      <Dropdown
        value={logoOptions}
        options={logoOptionsDefault}
        onChange={handleChange}
      />
    </div>
  );
};

export default ChangeLogoOptions;
