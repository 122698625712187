import { Link } from 'react-router-dom';

import { useLocale } from 'context/locale-context';

const DashboardButton = () => {
  const { getLocaleOption } = useLocale();

  return (
    <Link
      to='/questionnaires/patient'
      className='p-button p-component mb-3 text-center text-2xl'
    >
      {getLocaleOption('dashboardButton')}
    </Link>
  );
};

export default DashboardButton;
