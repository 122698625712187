import { CalendarDate } from '../../components/Inputs';
import { CheckedElement, EntityState, UserFile } from './';

export enum FragmentType {
  PAGE_BREAK = 'PAGE_BREAK',
  HEADER = 'HEADER',
  DESCRIPTION = 'DESCRIPTION',
  DIVIDER = 'SEPARATOR',
  QUESTION__YES_NO = 'QUESTION__YES_NO',
  QUESTION__MULTI_VALUE = 'QUESTION__MULTI_VALUE',
  QUESTION__TEXT = 'QUESTION__TEXT',
  QUESTION__NUMBER_LONG = 'QUESTION__NUMBER_LONG',
  QUESTION__DATE = 'QUESTION__DATE',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  FILE = 'QUESTION__MULTI_FILE',
  MULTI_DATE = 'QUESTION__MULTI_DATE',
  MULTI_TEXT = 'QUESTION__MULTI_TEXT',
  SIGNATURE = 'SIGNATURE',
  BMI = 'QUESTION__BMI',
}

export enum ComponentType {
  DAY = 'DAY',
  MONTH = 'MONTH',
  TEXT_FIELD = 'TEXT_FIELD',
  TEXT_AREA = 'TEXT_AREA',
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  DROPDOWN = 'DROPDOWN',
}

// fragment object we get from server
export interface Fragment {
  createdBy?: string; // The user, who created this Entity
  createdOn?: Date; // The created Timestamp for this Entity
  deletedBy?: string; // The user, who deleted this Entity
  deletedOn?: Date; // The deleted Timestamp for this Entity
  entityState?: EntityState; // The current Entity State
  id: number | null; // The database generated Id for this Entity
  mandatory: boolean; // Indicator to flag, whether User MUST provide a non-empty value
  name: string; // Name of the Fragment
  printName?: string; // abbreviated representation of the question for printing in the PDF
  rowVersion?: number; // The current Version of this Entity; Used for (safe) Update Operations
  sequentialNumber?: number; // Sequential Number of the Fragment
  text: string; // Text of the Fragment
  printText?: string; // Text for the printing in the PDF
  type: FragmentType | null;
  updatedBy?: string; // The user, who updated this Entity
  updatedOn?: Date; // The updated Timestamp for this Entity
  componentType?: ComponentType;
  options?: {
    id?: number;
    value: string;
    selected?: boolean;
  }[];
  minValue?: string | number | Date;
  maxValue?: string | number | Date;
  files?: UserFile[];
  value?: string;
  values?: {
    id: number;
    value: string;
  }[];
  weightInKG?: number;
  heightInCM?: number;
  clinicTerms?: string;
  clinicLogo?: string;
  flagShowInExecutiveSummary?: boolean;
}

// updated fragment object
export interface FragmentInterface extends Fragment {
  page?: number;
  dateValue?: CalendarDate;
  checkedOptions?: CheckedElement[];
  yesNoOptions?: CheckedElement[];
  error?: boolean;
  errorMessage?: string;
  inputMask?: boolean;
  mask?: string;
  textMultiValues?: string[];
  dateMultiValues?: CalendarDate[];
  signatureFileId?: string;
  signatureText?: string;
  minDateValue?: string;
  maxDateValue?: string;
  /*
        Component type values:
        1) QUESTION__DATE:
            - DAY
            - MONTH
        2) QUESTION__TEXT:
            - TEXT_FIELD
            - TEXT_AREA
        3) QUESTION__MULTI_VALUE:
            - RADIO
            - CHECKBOX
            - DROPDOWN
    */

  // types used for building questionnaire - only exist on building fragment
  editing?: boolean;
  componentOptions?: CheckedElement[];
  builderError?: boolean; // different than ordinary error, it will be shown differently for different components
  builderId?: string;
}

export interface FragmentAnswer {
  rowVersion: number;
  id: number;
  type: FragmentType;
  text: string;
  value?: string | number | CalendarDate | boolean;
  options?: {
    id?: number;
    selected: boolean;
  }[];
}
