import React, { useState, useEffect, useMemo } from 'react';

import { useAPI } from 'context/api-context';
import { useSettings } from 'context/settings-context';
import { useLocale } from 'context/locale-context';
import { useClinics } from '../context/clinics-context';

import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';

import Layout from 'components/Layout/Layout';
import { Dropdown, Input } from 'components/Inputs';
import ContentResponsive from '../components/Layout/ContentResponsive';

import { CheckedElement, Email } from 'shared/interfaces';

const ADMIN_ID = 100000000000000;

const Mails = () => {
  const { fetchAPI } = useAPI();
  const { showToast } = useSettings();
  const { getLocaleOption } = useLocale();
  const { clinics } = useClinics();
  const adminOption = useMemo(() => {
    return {
      id: ADMIN_ID,
      name: 'Admin',
      title: 'Admin',
      checked: true,
    };
  }, []);

  const [emails, setEmails] = useState<Email[]>([]);
  const [options, setOptions] = useState<CheckedElement[]>([adminOption]);

  useEffect(() => {
    if (!!clinics) {
      const checkedElements: CheckedElement[] = clinics.map((clinic) => {
        return {
          id: clinic.id,
          name: clinic.name,
          title: clinic.name,
          checked: false,
        };
      });
      setOptions([...checkedElements, adminOption]);
    }
  }, [clinics, adminOption]);

  useEffect(() => {
    let callUrl: string = '/mailtemplates/';
    const checkedElement = options.find((op) => op.checked);
    if (!!checkedElement && checkedElement.id !== ADMIN_ID) {
      callUrl = `/mailtemplates/clinics/${checkedElement.id}`;
    }

    const getEmails = async () => {
      const response = await fetchAPI(callUrl, {
        withToken: true,
      });

      if (response.items) {
        setEmails(response.items);
        return;
      }
    };

    getEmails();
  }, [fetchAPI, options]);

  const changeBodyHandler = (e: React.ChangeEvent<HTMLTextAreaElement>, id: number) => {
    const stateMails = [...emails];
    const map = stateMails.map((mail) => {
      return {
        ...mail,
        body: mail.id === id ? e.target.value : mail.body,
      };
    });
    setEmails(map);
  };

  const changeSubjectHandler = (subject: string, id: number) => {
    const stateMails = [...emails];
    const map = stateMails.map((mail) => {
      return {
        ...mail,
        subject: mail.id === id ? subject : mail.subject,
      };
    });
    setEmails(map);
  };

  const getTitle = (mail: Email) => {
    switch (mail.mailType) {
      case 'CLINIC_INVITATION':
        return getLocaleOption('clinicInvitationMail');
      case 'INVITATION':
        return getLocaleOption('invitationMail');
      case 'PASSWORD_RESET':
        return getLocaleOption('passwordResetMail');
      case 'REGISTRATION':
        return getLocaleOption('registrationMail');
      case 'REMINDER':
        return getLocaleOption('reminderMail');
      case 'CHAT_MESSAGE_NOTIFICATION':
        return getLocaleOption('chatMessageNotificationMail');
      case 'DOCUMENT_UPLOAD_NOTIFICATION':
        return getLocaleOption('documentUploadNotificationMail');
      case 'MFA_SECRET_LOGIN':
        return getLocaleOption('2FACodeMail');
      case 'FERTILLY_USER_INVITATION':
        return getLocaleOption('fertillyUserInvitation');
      default:
        return '';
    }
  };

  const saveHandler = async (id: number) => {
    const email = emails.find((mail) => mail.id === id)!;

    const save = await fetchAPI(`/mailtemplates/${id}/`, {
      method: 'POST',
      withToken: true,
      body: JSON.stringify({
        body: email.body,
        rowVersion: 0,
        subject: email.subject,
      }),
    });

    if (save.id) {
      showToast({
        type: 'success',
        title: getLocaleOption('success'),
        text: getLocaleOption('emailEditedSuccess'),
      });
    }
  };

  const rolloutTemplate = async (id: number) => {
    const save = await fetchAPI(`/mailtemplates/${id}/clinics/rollout`, {
      method: 'POST',
      withToken: true,
    });

    if (!!save) {
      showToast({
        type: 'success',
        title: getLocaleOption('success'),
        text: getLocaleOption('emailTemplateRolledOutSuccess'),
      });
    }
  };

  const renderRolloutBUtton = (mailId: number) => {
    const checkedElement = options.find((op) => op.checked);

    return (checkedElement && checkedElement?.id === ADMIN_ID) || !checkedElement ? (
      <Button
        className='ml-3 p-button-danger'
        label={getLocaleOption('rolloutTemplate')}
        onClick={() => rolloutTemplate(mailId)}
      />
    ) : null;
  };

  return (
    <Layout>
      <div className="card pb-0">
        <ContentResponsive className='col-3 p-0'>
          <Dropdown
            label={getLocaleOption('chooseClinic')}
            options={options}
            setOptions={(op) => {
              setOptions(op as CheckedElement[]);
            }}
            error={false}
          />
        </ContentResponsive>
      </div>
      <ContentResponsive>
        {emails.map((mail) => (
          <div key={mail.id} className='mb-5'>
            <h3>{getTitle(mail)}</h3>
            <div className="card">
              <Input
                value={mail.subject}
                setValue={(value) => changeSubjectHandler(value as string, mail.id)}
                id={`mail-${mail.id}`}
                label={getLocaleOption('emailSubject')}
              />
              <InputTextarea
                rows={10}
                value={mail.body}
                onChange={(e) => changeBodyHandler(e, mail.id)}
                className='block w-full mb-2'
              />
              <Button
                label={getLocaleOption('save')}
                onClick={() => saveHandler(mail.id)}
              />
              {renderRolloutBUtton(mail.id)}
            </div>
          </div>
        ))}
      </ContentResponsive>
    </Layout>
  );
};

export default Mails;
