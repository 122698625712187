import { useState, useRef, useEffect } from 'react';

import SignaturePad from 'react-signature-pad-wrapper';

import { Button } from 'primereact/button';
// import { TabView, TabPanel } from 'primereact/tabview';

// import { InputErrorMessage, uploadFileHandler, Input } from './';
import { InputErrorMessage, uploadFileHandler } from './';

import { FileList } from '../../shared/interfaces/File';

import { useSettings } from '../../context/settings-context';
import { useLocale } from '../../context/locale-context';

interface SignatureProps {
  id: string;
  label: string;
  value: {
    signatureFileId?: string;
    signatureText: string;
  };
  error?: boolean;
  setValue: React.Dispatch<any>;
  noGutter?: boolean;
}

const dataUrlToFile = async (dataUrl: string, fileName: string): Promise<File> => {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: 'image/png' });
};

const SIGNATURE_FILE_NAME = 'signature.png';

export const Signature = ({
  error,
  label,
  value,
  setValue,
  noGutter,
}: SignatureProps) => {
  const { showToast } = useSettings();
  const { getLocaleOption } = useLocale();

  const [err, setErr] = useState(error);
  useEffect(() => setErr(error), [error]);

  const [fetching, setFetching] = useState(false);
  // const [activeIndex, setActiveIndex] = useState(0);

  const signatureRef = useRef(null) as React.MutableRefObject<any>;

  const finishUploadSignature = (files: FileList) => {
    setValue({
      signatureFileId: files.items[0].uuid,
      signatureText: value.signatureText,
    });
    showToast({
      type: 'success',
      title: getLocaleOption('success'),
      text: getLocaleOption('signatureUploaded'),
    });
  };

  const saveHandler = async () => {
    setFetching(true);
    // const image = await dataUrlToFile(signatureTextDataURL, SIGNATURE_FILE_NAME);
    // const upload = await uploadFileHandler([image]);
    // finishUploadSignature(upload);
    // setFetching(false);

    if (!signatureRef.current) return;

    if (signatureRef.current.isEmpty()) {
      setErr(true);
      setFetching(false);
      return;
    } // there should be function to update error

    const img = signatureRef.current.toDataURL('image/png');

    const file = await dataUrlToFile(img, SIGNATURE_FILE_NAME);

    const upload = await uploadFileHandler([file]);

    finishUploadSignature(upload);

    setFetching(false);
    setErr(false);

    // if(activeIndex === 0) {
    //     if(!signatureRef.current) return;

    //     if(signatureRef.current.isEmpty()) return; // there should be function to update error

    //     const img = signatureRef.current.toDataURL('image/png');

    //     const file = await dataUrlToFile(img, SIGNATURE_FILE_NAME);

    //     const upload = await uploadFileHandler([file]);

    //     finishUploadSignature(upload);

    // } else {
    //     const image = await dataUrlToFile(signatureTextDataURL, SIGNATURE_FILE_NAME);
    //     const upload = await uploadFileHandler([ image ]);
    //     console.log(upload)
    //     finishUploadSignature(upload);
    // }
  };

  const clearHandler = () => {
    if (!signatureRef.current) return;
    setErr(false);
    signatureRef.current.clear();
    // setValue({ signatureFileId: undefined, signatureText: '' });
  };

  // const [signatureTextDataURL, setSignatureTextDataURL] = useState('');

  // const canvasRef = useRef<any>(null);
  // const imageRef = useRef<any>(null);

  // useEffect(() => {
  //   const getImage = () => {
  //     const font = '60px Juliette';

  //     const canvas = canvasRef.current as HTMLCanvasElement;
  //     const tCtx = canvas.getContext('2d')!; //Hidden canvas
  //     const imageElem = imageRef.current! as HTMLImageElement; //Image element

  //     tCtx.font = font;

  //     tCtx.canvas.width = tCtx.measureText(value.signatureText).width;

  //     tCtx.font = font;

  //     tCtx.fillText(value.signatureText, 0, 100);

  //     imageElem.src = tCtx.canvas.toDataURL();

  //     setSignatureTextDataURL(tCtx.canvas.toDataURL());
  //   };

  //   if (canvasRef.current && imageRef.current) getImage();
  // }, [value.signatureText, canvasRef, imageRef]);

  // useEffect(() => {
  //     if(canvasRef.current && imageRef.current) setValue({ ...value, signatureText: value.signatureText });
  // }, [ canvasRef, imageRef ]);

  // <TabPanel header="Signature" >
  //                 <InputContainer noGutter={noGutter}>
  //                     {/* <InputLabel error={error}>{label}</InputLabel> */}
  //                     <div className="border-1" ref={parentRef}>
  //                         {width && <SignatureCanvas canvasProps={{ width: `${width}px` , height: 263.5, className: 'sigCanvas' }} ref={signatureRef} />}
  //                     </div>
  //                 </InputContainer>
  //             </TabPanel>

  return (
    <div className='flex flex-column'>
      <h3 style={{ textAlign: 'center' }}>{getLocaleOption('sign')}</h3>
      <SignaturePad options={{ backgroundColor: '#fff' }} ref={signatureRef} />

      {/* <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
    //  commented layout here
      <TabPanel header={getLocaleOption('type')}>
      <div className='signature-label'>
      <Input
       value={value.signatureText}
       setValue={(val) => setValue({ ...value, signatureText: val })}
       id='signature-text'
       label={getLocaleOption('typeSignature')}
       />
      </div>
      <canvas id='textCanvas' height='200' ref={canvasRef} />
      <img id='image' style={{ display: 'none' }} alt='' ref={imageRef} />
      </TabPanel>
      </TabView> */}

      <div className='flex justify-content-center m-3 '>
        <Button
          label={getLocaleOption('save')}
          className='mr-1'
          onClick={saveHandler}
          loading={fetching}
        />
        <Button label={getLocaleOption('clear')} className='' onClick={clearHandler} />
      </div>
      <div className='flex justify-content-center' style={{ fontSize: '1.5rem' }}>
        {err && <InputErrorMessage />}
      </div>
    </div>
  );
};

export default Signature;
