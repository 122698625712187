import { useState } from 'react';

import { useAPI } from 'context/api-context';
import { useSettings } from 'context/settings-context';
import { useLocale } from 'context/locale-context';
import { useFetchUserGroups } from './useFetchUserGroups';

import { ListBox } from 'primereact/listbox';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';

import { InputLabel } from 'components/Inputs';
import { HandleUserGroupsDialog } from './HandleUserGroupsDialog';

import { ChatStream } from 'shared/interfaces/Chat';
import { DeleteResponse } from 'shared/interfaces';
import {
  AddUpdateGroupDialogState,
  HandleUserGroupsProps,
  UserGroupsDialogOptions,
} from './HandleUserGroups.types';

export const HandleUserGroups = ({ clinicId }: HandleUserGroupsProps) => {
  const { fetchAPI } = useAPI();
  const { somethingWentWrongToast } = useSettings();
  const { getLocaleOption } = useLocale();
  const { fetchUserGroups, userGroups } = useFetchUserGroups(clinicId);

  const [addUpdateGroupDialogState, setAddUpdateGroupDialogState] =
    useState<AddUpdateGroupDialogState>(null);
  const [inputValue, setInputValue] = useState('');
  const [selectedUserGroup, setSelectedUserGroup] = useState<ChatStream | null>(null);
  const [deleteGroupDialogVisible, setDeleteGroupDialogVisible] = useState(false);

  const handleOpenAddUpdateGroupDialog = (update?: boolean) => {
    if (update && selectedUserGroup) {
      setAddUpdateGroupDialogState(UserGroupsDialogOptions.update);
      setInputValue(selectedUserGroup.name);
    } else {
      setAddUpdateGroupDialogState(UserGroupsDialogOptions.add);
    }
  };

  const handleOpenAddUserGroupDialog = () => handleOpenAddUpdateGroupDialog();

  const handleOpenUpdateUserGroup = () => handleOpenAddUpdateGroupDialog(true);

  const handleCloseAddUpdateGroupDialog = () => {
    setAddUpdateGroupDialogState(null);
    setInputValue('');
    selectedUserGroup && setSelectedUserGroup(null);
  };

  const handleAddUpdateUserGroup = async (update?: boolean) => {
    if (update && selectedUserGroup?.name === inputValue) {
      handleCloseAddUpdateGroupDialog();
      return;
    }

    const response: ChatStream = await fetchAPI(
      update && selectedUserGroup
        ? `/chatstreams/${selectedUserGroup.id}/`
        : `/chatstreams/clinics/${clinicId}/`,
      {
        withToken: true,
        method: update ? 'PUT' : 'POST',
        body: JSON.stringify({ name: inputValue }),
      }
    );

    if (response.id) {
      fetchUserGroups();
    } else {
      somethingWentWrongToast();
    }

    handleCloseAddUpdateGroupDialog();
  };

  const handleSubmit = () => {
    if (addUpdateGroupDialogState) {
      handleAddUpdateUserGroup(
        addUpdateGroupDialogState === UserGroupsDialogOptions.update
      );
    }
  };

  const handleOpenDeleteGroupDialog = () => setDeleteGroupDialogVisible(true);

  const handleCloseDeleteGroupDialog = () => {
    setDeleteGroupDialogVisible(false);
    setSelectedUserGroup(null);
  };

  const handleDeleteGroup = async () => {
    if (selectedUserGroup) {
      const response: DeleteResponse = await fetchAPI(
        `/chatstreams/${selectedUserGroup.id}/`,
        {
          withToken: true,
          method: 'DELETE',
        }
      );

      if (response.deleted) {
        fetchUserGroups();
      } else {
        somethingWentWrongToast();
      }

      handleCloseDeleteGroupDialog();
    }
  };

  return (
    <>
      <div className='col-12 lg:col-8 p-0 lg:pl-4 flex'>
        <div className='inline-flex flex-column'>
          <InputLabel>{getLocaleOption('userGroups')}</InputLabel>
          <Button
            className='mt-1 mb-2'
            label={getLocaleOption('addGroup')}
            onClick={handleOpenAddUserGroupDialog}
            disabled={userGroups.length >= 25}
          />
          <Button
            className='mb-2'
            label={getLocaleOption('renameGroup')}
            onClick={handleOpenUpdateUserGroup}
            disabled={!selectedUserGroup}
          />
          <Button
            className='p-button-danger'
            label={getLocaleOption('removeGroup')}
            onClick={handleOpenDeleteGroupDialog}
            disabled={!selectedUserGroup}
          />
        </div>
        <ListBox
          value={selectedUserGroup}
          onChange={(e) => setSelectedUserGroup(e.value)}
          options={userGroups}
          optionLabel='name'
          itemTemplate={(option: ChatStream) => <p>{option.name}</p>}
          className='flex-1 ml-4 border-round-md border-1 border-300'
          listStyle={{ maxHeight: '192px' }}
        />
      </div>
      <HandleUserGroupsDialog
        visible={!!addUpdateGroupDialogState}
        onClose={handleCloseAddUpdateGroupDialog}
        onSubmit={handleSubmit}
        inputValue={inputValue}
        setInputValue={setInputValue}
        dialogType={addUpdateGroupDialogState}
      />
      <ConfirmDialog
        visible={deleteGroupDialogVisible}
        onHide={handleCloseDeleteGroupDialog}
        header={getLocaleOption('confirmation')}
        icon='pi pi-exclamation-triangle'
        message={
          <p>
            {getLocaleOption('areYouSureWantToRemoveTheGroup')}
            <span className='font-bold'>
              {selectedUserGroup?.name || 'current group'}
            </span>
          </p>
        }
        rejectLabel={getLocaleOption('cancel')}
        acceptLabel={getLocaleOption('confirm')}
        accept={handleDeleteGroup}
      />
    </>
  );
};
