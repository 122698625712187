import React from 'react';

import { Locales } from 'context/locale-context';

import EuropeanCountries from 'shared/json/EuropeanCountries.json';
import { getDate, getPersonBirthday } from 'shared/helpers/makeDate';

import { Person as IPerson, Person } from 'shared/interfaces/User';
import { CheckedElement, FamilyState, Salutation, Sex, User, User as UserInterface } from 'shared/interfaces';
import { PersonItem } from './User.types';

function checkFamilyStatus(status: string) {
  return status === FamilyState.IN_RELATIONSHIP
    ? 'in_relationship'
    : status?.toLocaleLowerCase();
}

const getCountries = (
  person: IPerson | undefined,
  locale: Locales,
  countryName: string | number | undefined
): CheckedElement[] => {
  const euCountries = EuropeanCountries as { [key: string]: string }[];

  return euCountries
  .sort((a, b) => a[locale].localeCompare(b[locale]))
  .map((country) => {
    return {
      title: country['en'],
      name: country[locale],
      checked: countryName
        ? countryName.toString() === country['en']
        : person?.country === country['en']
    };
  });
};

export const userOptions = (
  user: UserInterface,
  getLocaleOption: (option: string) => string
) => [
  {
    title: 'name',
    value: user.person?.firstName
  },
  {
    title: 'lastName',
    value: user.person?.lastName
  },
  {
    title: 'salutation',
    value:
      user.person?.salutation &&
      getLocaleOption(user.person?.salutation.toLocaleLowerCase())
  },
  {
    title: 'placeOfBirth',
    value: user.person?.placeOfBirth
  },
  {
    title: 'birthday',
    value: getPersonBirthday(user.person?.birthday)
  },
  {
    title: 'gender',
    value: user.person?.sex && getLocaleOption(user.person?.sex.toLocaleLowerCase())
  },
  {
    title: 'familyStatus',
    value:
      user.person?.familyState &&
      getLocaleOption(checkFamilyStatus(user.person?.familyState))
  },
  {
    title: 'street',
    value: user.person?.street
  },
  {
    title: 'zip',
    value: user.person?.zipCode
  },
  {
    title: 'city',
    value: user.person?.city
  },
  {
    title: 'country',
    value: user.person?.country && getLocaleOption(user.person.country)
  },
  {
    title: 'phone',
    value: user.person?.phone
  },
  {
    title: 'mobile',
    value: user.person?.cellular
  },
  {
    title: 'occupation',
    value: user.person?.occupation
  }
];

interface termsAndPrivatPolicyTranslations {
  locale: Locales;
  termsLink: string;
  privacyPolicyLink: string;
  agreement: string;
  termsAndConditions: string;
  and: string;
  privacyPolicy: string;
  finalText: string;
}

const translations = (locale: string): termsAndPrivatPolicyTranslations | undefined => {
  return [
    {
      locale: Locales.EN,
      termsLink: `https://www.fertilly.com/en/general-terms-and-conditions/`,
      privacyPolicyLink: 'https://www.fertilly.com/en/privacy-policy/',
      agreement: 'I agree to the ',
      termsAndConditions: 'Terms of Service ',
      and: 'and the ',
      privacyPolicy: 'Privacy Policy',
      finalText: ''
    },
    {
      locale: Locales.DE,
      termsLink: 'https://www.fertilly.com/de/agb/',
      privacyPolicyLink: 'https://www.fertilly.com/de/datenschutz/',
      agreement: 'Ich akzeptiere ',
      termsAndConditions: 'die AGB ',
      and: 'und die ',
      privacyPolicy: 'Datenschutz-Bestimmungen',
      finalText: ''
    },
    {
      locale: Locales.FR,
      termsLink: 'https://www.fertilly.com/en/general-terms-and-conditions/',
      privacyPolicyLink: 'https://www.fertilly.com/en/privacy-policy/',
      agreement: 'J\'accepte les ',
      termsAndConditions: 'conditions d\'utilisation ',
      and: 'et la ',
      privacyPolicy: 'politique de confidentialité',
      finalText: 'de Fertilly GmbH'
    },
    {
      locale: Locales.IT,
      termsLink: 'https://www.fertilly.com/it/condizioni-generali/',
      privacyPolicyLink: 'https://www.fertilly.com/it/riservatezza-dei-dati/',
      agreement: 'Accetto i ',
      termsAndConditions: 'termini di servizio ',
      and: 'e la ',
      privacyPolicy: 'policy sulla Privacy',
      finalText: ''
    },
    {
      locale: Locales.ES,
      termsLink: 'https://www.fertilly.com/en/general-terms-and-conditions/',
      privacyPolicyLink: 'https://www.fertilly.com/en/privacy-policy/',
      agreement: 'Acepto ',
      termsAndConditions: 'las condiciones de servicio ',
      and: 'y la ',
      privacyPolicy: 'política de privacidad',
      finalText: ''
    },
    {
      locale: Locales.TR,
      termsLink: 'https://www.fertilly.com/en/general-terms-and-conditions/',
      privacyPolicyLink: 'https://www.fertilly.com/en/privacy-policy/',
      agreement: 'Gizlilik ',
      termsAndConditions: 'Politikasını ve Kullanım ',
      and: 'y la ',
      privacyPolicy: 'Şartlarını kabul',
      finalText: 'ediyorum'
    },
    {
      locale: Locales.AR,
      termsLink: 'https://www.fertilly.com/en/general-terms-and-conditions/',
      privacyPolicyLink: 'https://www.fertilly.com/en/privacy-policy/',
      agreement: 'أوافق على شروط ',
      termsAndConditions: 'شروط الاستخدام ',
      and: 'و ',
      privacyPolicy: 'سياسة الخصوصية',
      finalText: ''
    },
    {
      locale: Locales.PT,
      termsLink: 'https://www.fertilly.com/en/general-terms-and-conditions/',
      privacyPolicyLink: 'https://www.fertilly.com/en/privacy-policy/',
      agreement: 'Aceito as ',
      termsAndConditions: 'condições de serviço ',
      and: 'e a ',
      privacyPolicy: 'política de privacidade',
      finalText: ''
    },
    {
      locale: Locales.NL,
      termsLink: 'https://www.fertilly.com/en/general-terms-and-conditions/',
      privacyPolicyLink: 'https://www.fertilly.com/en/privacy-policy/',
      agreement: 'Ik accepteer ',
      termsAndConditions: 'algemene voorwaarden ',
      and: 'en ',
      privacyPolicy: 'privacybeleid',
      finalText: ''
    }
  ].find(translation => translation.locale === locale);
};

export const getCheckboxTitle = (locale: Locales, showClinicTermsAndConditions: boolean) => {
  const CLINIC_TERMS = '/clinic/terms-and-conditions';
  const translation = translations(locale);

  return showClinicTermsAndConditions ? (
    <>
      {translation?.agreement}
      <a href={CLINIC_TERMS} target='_blank' rel='noreferrer noopener'>
        {translation?.termsAndConditions} {translation?.and} {translation?.privacyPolicy}
      </a>
      {` ${translation?.finalText}`}
    </>
  ) : (
    <>
      {translation?.agreement}
      <a href={`${translation?.termsLink}`}
         target='_blank' rel='noreferrer noopener'>
        {translation?.termsAndConditions}
      </a>{' '}
      {translation?.and}
      <a href={`${translation?.privacyPolicyLink}`}
         target='_blank' rel='noreferrer noopener'>
        {translation?.privacyPolicy}
      </a>
      {` ${translation?.finalText}`}
    </>
  );
};

export const CLINIC = 'CLINIC';
const MEDITEX_CLINIC = 'MEDITEX_CLINIC';
const VREPRO_CLINIC = 'VREPRO_CLINIC';
const FERTILY_USER = 'FERTILLY_USER';
const ADMIN_CLINIC_USER_TYPES = new Set();
ADMIN_CLINIC_USER_TYPES.add(CLINIC);
ADMIN_CLINIC_USER_TYPES.add(MEDITEX_CLINIC);
ADMIN_CLINIC_USER_TYPES.add(VREPRO_CLINIC);
ADMIN_CLINIC_USER_TYPES.add(FERTILY_USER);

export const isClinicOrFertillyUser = (user: User | undefined) =>
  !!user?.roles.filter((role) => ADMIN_CLINIC_USER_TYPES.has(role.name)).length;

function buildInputsMap(inputs: PersonItem[]) {
  const result: Record<string, PersonItem> = {};

  inputs.forEach((e) => (result[e.id] = e));

  return result;
}

function updateDropdownOptionsLocale(
  options: CheckedElement[],
  getLocaleOption: (option: string) => string
) {
  return options.map((e) => ({
    ...e,
    name: getLocaleOption(e.title.toLowerCase())
  }));
}

export const getInputs = (
  person: Person | undefined,
  locale: Locales,
  getLocaleOption: (option: string) => string,
  clinicOrFertillyUser: boolean,
  inputs: PersonItem[]
): PersonItem[] => {
  const inputsMap = buildInputsMap(inputs);

  return [
    {
      id: 'firstName',
      type: 'string',
      value: inputsMap['firstName']?.value || person?.firstName || '',
      label: getLocaleOption('name'),
      error: false,
      mandatory: true
    },
    {
      id: 'lastName',
      type: 'string',
      value: inputsMap['lastName']?.value || person?.lastName || '',
      label: getLocaleOption('surname'),
      error: false,
      mandatory: true
    },
    {
      id: 'salutation',
      type: 'dropdown',
      options: inputsMap['salutation']?.options
        ? updateDropdownOptionsLocale(inputsMap['salutation'].options, getLocaleOption)
        : [
          {
            title: Salutation.MRS,
            name: getLocaleOption('mrs'),
            checked: person?.salutation === Salutation.MRS
          },
          {
            title: Salutation.MR,
            name: getLocaleOption('mr'),
            checked: person?.salutation === Salutation.MR
          },
          (clinicOrFertillyUser && {
            title: Salutation.DR,
            name: getLocaleOption('dr'),
            checked: person?.salutation === Salutation.DR
          }) as CheckedElement,
          {
            title: Salutation.NO_SALUTATION,
            name: getLocaleOption('no_salutation'),
            checked: person?.salutation === Salutation.NO_SALUTATION
          }
        ].filter(Boolean),
      label: getLocaleOption('salutation'),
      error: false,
      mandatory: true,
      filterBy: 'name'
    },
    {
      noRender: ['1', CLINIC],
      id: 'email',
      type: 'string',
      value: inputsMap['email']?.value || person?.email || '',
      label: getLocaleOption('email'),
      error: false,
      mandatory: true
    },
    {
      noRender: [CLINIC],
      id: 'placeOfBirth',
      type: 'string',
      value: inputsMap['placeOfBirth']?.value || person?.placeOfBirth || '',
      label: getLocaleOption('placeOfBirth'),
      error: false,
      mandatory: true
    },
    {
      noRender: [CLINIC],
      id: 'birthDate',
      type: 'string',
      value: inputsMap['birthDate']?.value || getDate(person?.birthday) || '',
      label:
        getLocaleOption('birthday') + locale === Locales.EN
          ? ' DD/MM/YYYY'
          : ' TT/MM/YYYY',
      error: false,
      mandatory: true,
      inputMask: true,
      mask: '99/99/9999',
      withISODate: true
    },
    {
      noRender: [CLINIC],
      id: 'sex',
      type: 'dropdown',
      options: inputsMap['sex']?.options
        ? updateDropdownOptionsLocale(inputsMap['sex'].options, getLocaleOption)
        : [
          {
            title: Sex.UNKNOWN,
            name: getLocaleOption('unknown'),
            checked: person?.sex === Sex.UNKNOWN
          },
          {
            title: Sex.MALE,
            name: getLocaleOption('male'),
            checked: person?.sex === Sex.MALE
          },
          {
            title: Sex.FEMALE,
            name: getLocaleOption('female'),
            checked: person?.sex === Sex.FEMALE
          },
          {
            title: Sex.VARIOUS,
            name: getLocaleOption('various'),
            checked: person?.sex === Sex.VARIOUS
          }
        ],
      label: getLocaleOption('gender'),
      error: false,
      mandatory: true,
      filterBy: 'name'
    },
    {
      noRender: [CLINIC],
      id: 'familyState',
      type: 'dropdown',
      options: inputsMap['familyState']?.options
        ? updateDropdownOptionsLocale(inputsMap['familyState'].options, getLocaleOption)
        : [
          {
            title: FamilyState.UNKNOWN,
            name: getLocaleOption('unknown'),
            checked: person?.familyState === FamilyState.UNKNOWN
          },
          {
            title: FamilyState.SINGLE,
            name: getLocaleOption('single'),
            checked: person?.familyState === FamilyState.SINGLE
          },
          {
            title: FamilyState.MARRIED,
            name: getLocaleOption('married'),
            checked: person?.familyState === FamilyState.MARRIED
          },
          {
            title: FamilyState.IN_RELATIONSHIP,
            name: getLocaleOption('in_relationship'),
            checked: person?.familyState === FamilyState.IN_RELATIONSHIP
          }
        ],
      label: getLocaleOption('familyStatus'),
      error: false,
      mandatory: true,
      filterBy: 'name'
    },
    {
      noRender: [CLINIC],
      id: 'street',
      type: 'string',
      value: inputsMap['street']?.value || person?.street || '',
      label: getLocaleOption('street'),
      error: false,
      mandatory: true
    },
    {
      noRender: [CLINIC],
      id: 'zipCode',
      type: 'string',
      value: inputsMap['zipCode']?.value || person?.zipCode || '',
      label: getLocaleOption('zip'),
      error: false,
      mandatory: true,
      maxlength: 12
    },
    {
      noRender: [CLINIC],
      id: 'city',
      type: 'string',
      value: inputsMap['city']?.value || person?.city || '',
      label: getLocaleOption('city'),
      error: false,
      mandatory: true
    },
    {
      noRender: [CLINIC],
      id: 'country',
      type: 'dropdown',
      options: getCountries(
        person,
        locale,
        inputsMap['country']?.options?.find((c) => c.checked)?.name
      ),
      label: getLocaleOption('country'),
      error: false,
      mandatory: true,
      filterBy: 'name'
    },
    {
      noRender: [CLINIC],
      id: 'phone',
      type: 'string',
      value: inputsMap['phone']?.value || person?.phone || '',
      label: getLocaleOption('phone'),
      error: false,
      mandatory: false
    },
    {
      noRender: [CLINIC],
      id: 'cellular',
      type: 'string',
      value: inputsMap['cellular']?.value || person?.cellular || '',
      label: getLocaleOption('mobile'),
      error: false,
      mandatory: true
    },
    {
      noRender: [CLINIC],
      id: 'occupation',
      type: 'string',
      value: inputsMap['occupation']?.value || person?.occupation || '',
      label: getLocaleOption('occupation'),
      error: false,
      mandatory: false
    }
  ];
};

export const getInputsImmutable = (
  inputs: PersonItem[],
  clinicOrFertillyUser: boolean
) => {
  const stateInputs = [...inputs];
  return stateInputs.filter((input) => {
    if (input.noRender) {
      let render = true;
      // no render for 1 | 2 | Clinic
      input.noRender.forEach((option) => {
        if (option === '1') render = false;
        if (option === CLINIC && clinicOrFertillyUser) render = false;
      });

      if (!render) return null;
    }
    return input;
  });
};
