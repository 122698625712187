import { Editor as PrimeEditor, EditorTextChangeParams } from 'primereact/editor';

import { InputLabel, InputContainer, InputErrorMessage } from './';

interface EditorProps {
  text: string;
  setText: React.Dispatch<React.SetStateAction<string>>;
  label: string;
  error?: boolean;
  noGutter?: boolean;
  style?: {};
}

export const Editor = ({ text, setText, label, error, noGutter, style }: EditorProps) => {
  const changeTextHandler = (text: EditorTextChangeParams) => {
    try {
      setText(text.htmlValue || '');
    } catch (err) {}
  };

  const header = (
    <span className='ql-formats'>
      {/* <select className="ql-size">
                <option selected></option>
                <option value="large"></option>
                <option value="huge"></option>
            </select> */}
      <button className='ql-bold' aria-label='Bold'></button>
      <button className='ql-italic' aria-label='Italic'></button>
      <button className='ql-underline' aria-label='Underline'></button>
      <button className='ql-list' value='bullet'></button>
      <button className='ql-list' value='ordered'></button>
      <button className='ql-align' value=''></button>
      <button className='ql-align' value='center'></button>
      <button className='ql-align' value='right'></button>
    </span>
  );

  return (
    <InputContainer noGutter={noGutter}>
      <InputLabel error={error}>{label}</InputLabel>
      <PrimeEditor
        style={style}
        headerTemplate={header}
        value={`${text}`}
        onTextChange={changeTextHandler}
      />
      {error && <InputErrorMessage />}
    </InputContainer>
  );
};

export default Editor;
