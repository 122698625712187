import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import SettingsContext from './context/settings-context';
import APIContext from './context/api-context';
import LocaleContext from './context/locale-context';

// context order:
// locales > settings > api > auth > clinics

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <LocaleContext>
        <SettingsContext>
          <APIContext>
            <App />
          </APIContext>
        </SettingsContext>
      </LocaleContext>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
