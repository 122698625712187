import { useLocale } from 'context/locale-context';

import { Dropdown, Input } from 'components/Inputs';

import { CheckedElement, ClinicProperties } from 'shared/interfaces';

const getSecretKeyTitle = (clinicProperty: string | number | undefined) => {
  switch (clinicProperty) {
    case ClinicProperties.meditex:
      return 'MediTEX clinic ID';

    case ClinicProperties.vrepro:
      return 'VRepro clinic secret';

    case ClinicProperties.gdt:
      return 'GDT';

    default:
      return 'Secret key';
  }
};

interface HandleClinicInterfaceProps {
  clinicProperty: CheckedElement[];
  setClinicProperty: React.Dispatch<React.SetStateAction<CheckedElement[]>>;
  hasInterface: boolean;
  checkedClinicProperty: string | number | undefined;
  secretKey: string;
  setSecretKey: React.Dispatch<React.SetStateAction<string>>;
  secretKeyError: boolean;
}

export const HandleClinicInterface = ({
  clinicProperty,
  setClinicProperty,
  hasInterface,
  checkedClinicProperty,
  secretKey,
  setSecretKey,
  secretKeyError,
}: HandleClinicInterfaceProps) => {
  const { getLocaleOption } = useLocale();

  return (
    <>
      <div className='col-12 lg:col-4 p-0'>
        <Dropdown
          label={getLocaleOption('clinicInterface')}
          options={clinicProperty}
          setOptions={setClinicProperty}
        />
      </div>
      {hasInterface && (
        <div className='col-12 lg:col-8 p-0 lg:pl-4'>
          <Input
            id='secret-key'
            label={getSecretKeyTitle(checkedClinicProperty)}
            value={secretKey}
            setValue={setSecretKey}
            error={secretKeyError}
          />
        </div>
      )}
    </>
  );
};
