import { useLocale } from 'context/locale-context';
import { useAuth } from 'context/auth-context';

import AppSubmenu from './AppSubmenu';
import { MenuItem } from './Layout';

interface AppMenuProps {
  model: MenuItem[];
  menuMode: string;
  onMenuClick: () => void;
  active: boolean;
  mobileMenuActive: boolean;
  onMenuitemClick: (event?: any) => void;
  onRootMenuitemClick: () => void;
  logo: string | null;
}

const AppMenu = ({
  logo,
  active,
  menuMode,
  mobileMenuActive,
  model,
  onMenuClick,
  onMenuitemClick,
  onRootMenuitemClick,
}: AppMenuProps) => {
  const { getLocaleOption } = useLocale();
  const { logout } = useAuth();

  return (
    <div className='layout-sidebar' onClick={onMenuClick}>
      {logo && (
        <div className='logo min-h-4'>
          <img id='app-logo' width={160} src={logo} alt='app menu logo' />
        </div>
      )}

      <div
        className={`layout-menu-container flex flex-column justify-content-between ${
          logo ? 'pt-0' : ''
        } `}
      >
        <AppSubmenu
          items={model}
          menuMode={menuMode}
          parentMenuItemActive
          menuActive={active}
          mobileMenuActive={mobileMenuActive}
          root
          onMenuitemClick={onMenuitemClick}
          onRootMenuitemClick={onRootMenuitemClick}
        />
        <ul className='layout-menu' role='menu'>
          <ul className='layout-menu' role='menu'>
            <li className='' role='menuitem'>
              <button className='p-ripple p-link' onClick={logout}>
                <i className='layout-menuitem-icon pi pi-fw pi-sign-out' />
                <span className='layout-menuitem-text'>{getLocaleOption('signOut')}</span>
              </button>
            </li>
          </ul>
        </ul>
      </div>
    </div>
  );
};

export default AppMenu;
