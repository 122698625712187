import {
  TabView,
  TabPanel,
  TabViewTabChangeParams,
  TabViewProps,
} from 'primereact/tabview';

import { ChatStream } from 'shared/interfaces/Chat';
import { UserMessages } from 'shared/interfaces';

interface ChatTabsProps extends TabViewProps {
  activeTabIndex: number;
  handleTabChange: (e: TabViewTabChangeParams) => void;
  chatStreams: ChatStream[];
  chatMessages: UserMessages;
}

export const ChatTabs = ({
  activeTabIndex,
  handleTabChange,
  chatStreams,
  chatMessages,
  ...otherProps
}: ChatTabsProps) => {
  return (
    <div className='chat-tabs'>
      <TabView activeIndex={activeTabIndex} onTabChange={handleTabChange} {...otherProps}>
        {chatStreams.map((c) => {
          const unreadMessages = chatMessages.find(
            (e) => e.chatStreamId === c.id
          )?.unreadMessages;

          return (
            <TabPanel
              key={c.id}
              header={`${c.name} ${
                unreadMessages && unreadMessages > 0 ? `(${unreadMessages})` : ''
              }`}
            />
          );
        })}
      </TabView>
    </div>
  );
};
