export const hasLowerCase = (str: string) => {
  return /[a-z]/.test(str);
};

export const hasUpperCase = (str: string) => {
  return /[A-Z]/.test(str);
};

export const hasNumber = (str: string) => {
  return /\d/.test(str);
};
