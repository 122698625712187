import { useState, useEffect } from 'react';

import { useAPI } from 'context/api-context';
import { useLocale } from 'context/locale-context';
import { useClinics } from 'context/clinics-context';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';

import DownloadFile from './DownloadFile';
import { TransferUserFile } from './TransferUserFile';

import makeDate from 'shared/helpers/makeDate';
import { UserFile } from 'shared/interfaces';
import { useAuth } from '../../../context/auth-context';
import FileRemarksBody from './FileRemarksBody';

interface QuestionnairesProps {
  userId: number;
  fetchFiles: Date;
}

const UserFiles = ({ userId, fetchFiles }: QuestionnairesProps) => {
  const { fetchAPI } = useAPI();
  const { getLocaleOption, locale } = useLocale();
  const { isInterfaceEnabled } = useClinics();
  const { checkUserPrivileges } = useAuth();
  const isSeePrivateDocumentsAllowed = checkUserPrivileges('DOWNLOAD_FOREIGN_FILES');

  const [fetching, setFetching] = useState(false);
  const [files, setFiles] = useState<UserFile[]>([]);
  const [globalFilter, setGlobalFilter] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const getFiles = async () => {
      setFetching(true);

      const response = await fetchAPI(`/users/${userId}/files/`, {
        withToken: true,
      });

      if (response.error) {
        setErrorMessage(response.message);
        return;
      }

      if (response.items) {
        setFiles(response.items);
      }

      setFetching(false);
    };

    getFiles();

    return () => setFiles([]);
  }, [userId, fetchAPI, fetchFiles]);

  const searchHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setGlobalFilter(e.currentTarget.value);
  };

  const tableHeaderTemplate = (
    <div className='table-header flex align-items-center justify-content-between'>
      <h5 className='mx-0 my-1'>{getLocaleOption('files')}</h5>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          onInput={searchHandler}
          placeholder={`${getLocaleOption('search')}...`}
        />
      </span>
    </div>
  );

  const fileNameBodyTemplate = (file: UserFile) => (
    <span className='font-bold'>{getLocaleOption(file.name)}</span>
  );

  const fileUploadedFromBodyTemplate = (file: UserFile) => (
    <span className='font-bold'>{`${file.createdBy} (${getLocaleOption(file.origin?.toLowerCase())})`}</span>
  );

  const fileUploadedOnBodyTemplate = (file: UserFile) =>
    file.createdOn && makeDate(new Date(file.createdOn), locale);

  const transferUserFileBodyTemplate = (file: UserFile) => {
    return <TransferUserFile data={file} files={files} setFiles={setFiles} />;
  };

  const downLoadFileBodyTemplate = (file: UserFile) => (
    <DownloadFile value={file.value} />
  );

  return errorMessage ? (
    <h5 className='mb-0'>{errorMessage}</h5>
  ) : (
    <DataTable
      value={files}
      responsiveLayout='scroll'
      showGridlines
      paginator
      paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
      globalFilterFields={['name']}
      currentPageReportTemplate={getLocaleOption('tableNumberingTemplate')}
      rows={10}
      rowsPerPageOptions={[10, 20, 50]}
      loading={fetching}
      globalFilter={globalFilter}
      header={tableHeaderTemplate}
      emptyMessage={getLocaleOption('tableNoAvailableOptions')}
    >
      <Column field='name' header={getLocaleOption('file')} body={fileNameBodyTemplate} />
      <Column
        header={getLocaleOption('fileUploadedBy')}
        body={fileUploadedFromBodyTemplate}
      />
      <Column
        align='center'
        header={getLocaleOption('fileUploadedOn')}
        body={fileUploadedOnBodyTemplate}
      />
      <Column
        className='interfaceColumn'
        header={getLocaleOption('remarks')}
        body={(file: UserFile) => (<FileRemarksBody setFiles={setFiles} files={files} file={file} label={file.remarks ? file.remarks : getLocaleOption('assign')} />)}
        field='remarks'
      />
      {isInterfaceEnabled && (
        <Column
          align='center'
          header={getLocaleOption('transferFileToClinicSystem')}
          body={transferUserFileBodyTemplate}
        />
      )}
      {isSeePrivateDocumentsAllowed && (
        <Column
          header={getLocaleOption('downloadFile')}
          body={downLoadFileBodyTemplate}
        />
      )}
    </DataTable>
  );
};

export default UserFiles;
