import { useState } from 'react';

import { useAPI } from 'context/api-context';
import { useLocale } from 'context/locale-context';
import { useSettings } from 'context/settings-context';

import { Button } from 'primereact/button';
import { Input } from 'components/Inputs';

import { TableUser } from '../UsersList';

interface AssignPartnerPatientKeyProps {
  user: TableUser;
  users: TableUser[];
  setUsers: React.Dispatch<React.SetStateAction<TableUser[]>>;
}

const AssignPartnerPatientKey = ({
  user,
  users,
  setUsers,
}: AssignPartnerPatientKeyProps) => {
  const { fetchAPI } = useAPI();
  const { getLocaleOption } = useLocale();
  const { showToast } = useSettings();

  const [partnerPatientKey, setPartnerPatientKey] = useState(user.partnerPatientKey);

  const submitHandler = async () => {
    const json = await fetchAPI(`/users/${user.id}/partnerPatientKey/`, {
      method: 'PATCH',
      withToken: true,
      body: JSON.stringify({ partnerPatientKey, rowVersion: 0 }),
    });

    if (json.code) {
      // error
      return showToast({
        type: 'error',
        title: getLocaleOption('error'),
        text: json.message,
      });
    }

    const usersMap = users.map((u) => (u.id === json.id ? json : u));
    setUsers(usersMap);

    showToast({
      type: 'success',
      title: getLocaleOption('success'),
      text: 'Assigned',
    });
  };

  return (
    <div className='relative grid mb-0'>
      <div className='col-12'>
        <Input
          id='partner-patient-key'
          label='Patient ID'
          value={partnerPatientKey}
          setValue={setPartnerPatientKey}
        />
        <Button label={getLocaleOption('submit')} onClick={submitHandler} />
      </div>
    </div>
  );
};

export default AssignPartnerPatientKey;
