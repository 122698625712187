import { useAPI } from 'context/api-context';
import { useLocale } from 'context/locale-context';
import { useSettings } from 'context/settings-context';
import { DownloadPDFProps } from './DownloadPDF.types';

const handleDownloadSuccess = (response: Blob, fileName: string) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = window.URL.createObjectURL(response);
  downloadLink.setAttribute('download', fileName);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

export const useDownloadPDF = ({
    url,
    fileName
  }: DownloadPDFProps) => {
  const { fetchFileAPI } = useAPI();
  const { getLocaleOption } = useLocale();
  const { showToast } = useSettings();

  const downloadPDF = async () => {
    try {
      const blob: Blob = await fetchFileAPI(url, {
        method: 'POST',
        customHeaders: {
          'Content-Type': 'application/pdf'
        }
      });

      handleDownloadSuccess(blob, fileName);

      return blob;
    } catch (e) {
      showToast({
        type: 'error',
        title: getLocaleOption('error'),
        text: getLocaleOption('somethingWentWrong'),
      });
      return;
    }
  };

  return { downloadPDF };
};
