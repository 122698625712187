import { useCallback, useState, useEffect } from 'react';

import { useAPI } from 'context/api-context';
import { useSettings } from 'context/settings-context';

import { ChatStreamResponse, ChatStream } from 'shared/interfaces/Chat';

export const useFetchUserGroups = (clinicId: number) => {
  const { fetchAPI } = useAPI();
  const { somethingWentWrongToast } = useSettings();

  const [userGroups, setUserGroups] = useState<ChatStream[]>([]);

  const fetchUserGroups = useCallback(async () => {
    const response: ChatStreamResponse = await fetchAPI(
      `/chatstreams/clinics/${clinicId}/`,
      {
        withToken: true,
      }
    );

    if (response?.items) {
      setUserGroups(response.items);
    } else {
      somethingWentWrongToast();
    }
  }, [clinicId, fetchAPI, setUserGroups, somethingWentWrongToast]);

  useEffect(() => {
    fetchUserGroups();
  }, [fetchUserGroups]);

  return { fetchUserGroups, userGroups };
};
