import { useEffect, useState, useCallback } from 'react';

import { useAPI } from 'context/api-context';
import { useLocale } from 'context/locale-context';

import Layout from 'components/Layout/Layout';
import FilePreview from 'components/FilePreview';
import { CustomFileUpload } from 'components/Inputs';
import ContentResponsive from 'components/Layout/ContentResponsive';

import { UserFile } from 'shared/interfaces';

const DiagnosticFindings = () => {
  const { fetchAPI } = useAPI();
  const { getLocaleOption } = useLocale();

  const [files, setFiles] = useState<UserFile[]>([]);

  const getFiles = useCallback(async () => {
    const json = await fetchAPI('/users/_me/files/', {
      withToken: true,
    });

    if (json.items) setFiles(json.items);
  }, [fetchAPI]);

  useEffect(() => {
    getFiles();
  }, [getFiles]);

  const uploadHandler = async (files: UserFile[]) => {
    await fetchAPI('/users/_me/files/', {
      method: 'POST',
      withToken: true,
      body: JSON.stringify({ files }),
    });

    getFiles();
  };

  return (
    <Layout>
      <div className='flex flex-wrap grid'>
        <ContentResponsive className='col-9'>
          <CustomFileUpload
            label={getLocaleOption('myDiagnosticFindings')}
            onUpload={uploadHandler}
          />
        </ContentResponsive>
        <ContentResponsive className='col-3 pt-5'>
          <FilePreview files={files} />
        </ContentResponsive>
      </div>
    </Layout>
  );
};

export default DiagnosticFindings;
