import { useState, memo } from 'react';

import { useLocale } from 'context/locale-context';

import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';

interface InputAreaComponentProps {
  fetching: boolean;
  sendMessage: (message: string) => Promise<void>;
  disabled: boolean;
}

const InputAreaComponent = ({ fetching, sendMessage, disabled }: InputAreaComponentProps) => {
  const { getLocaleOption } = useLocale();

  const [inputValue, setInputValue] = useState('');

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.ctrlKey && e.key === 'Enter' && inputValue.trim() !== '') {
      sendMessage(inputValue);
      setInputValue('');
    }
  };

  const handleSendMessage = () => {
    setInputValue('');
    sendMessage(inputValue);
  };

  return (
    <div className='flex flex-column'>
      <InputTextarea
        className='mb-3'
        disabled={disabled}
        rows={5}
        placeholder={getLocaleOption('typeMessage')}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={(e) => handleKeyPress(e)}
      />
      <Button
        disabled={disabled || fetching || inputValue.trim() === ''}
        loading={fetching}
        label={getLocaleOption('send')}
        className='ml-auto p-button-lg w-full md:w-auto'
        onClick={handleSendMessage}
      />
    </div>
  );
};

export const InputArea = memo(InputAreaComponent);
