import { useState, useEffect } from 'react';

import { useLocale } from 'context/locale-context';
import { useAPI } from 'context/api-context';
import { useAuth } from 'context/auth-context';
import { useClinics } from 'context/clinics-context';
import { useQuestionnaireRunner } from 'containers/Questionnaire/useQuestionnaireRunner';
import { useFetchUserGroups } from 'containers/Clinics/components/HandleUserGroups/useFetchUserGroups';

import Questionnaires from '../Questionnaires';
import { CustomFileUpload } from 'components/Inputs';
import UserFiles from '../UserFiles';
import Partner from '../Partner';
import { Chat } from 'components/Chat';
import { UserDataItem } from './UserDataItem';
import { AssignUserGroups } from '../AssignUserGroups';

import { userOptions } from '../../User.helpers';
import { User as UserInterface, UserFile } from 'shared/interfaces';
import { isObject } from 'shared/helpers/isObject';

interface PreviewProps {
  user: UserInterface;
  users: UserInterface[];
  adminView: boolean;
}

type AdditionalDataAnswers = Record<string, string | string[]>;
type AdditionalData = ReturnType<typeof userOptions>;

function buildAdditionalData(answers: any): any {
  return Object.entries(answers)
    .filter(
      ([key, value]) => key !== 'pageNo' && !key.includes('_trigger') && !isObject(value)
    )
    .map(([key, value]) => ({
      title: key,
      value: Array.isArray(value) ? value.join(', ') : value,
    }));
}

export const Preview = ({ user, users }: PreviewProps) => {
  const { fetchAPI } = useAPI();
  const { getLocaleOption } = useLocale();
  const { checkUserPrivileges, login: refreshUnreadMessages } = useAuth();
  const { assignedClinic } = useClinics();

  const { id: userId, person, coupleId, chatStreams, clinicId, messages } = user;
  console.log(user);
  console.log(assignedClinic);
  const { userGroups } = useFetchUserGroups(clinicId);
  const { surveyModel } = useQuestionnaireRunner(userId);
  const chatStreamsItems = chatStreams?.items;

  const [additionalData, setAdditionalData] = useState<AdditionalData>([]);
  const [fetchFiles, setFetchFiles] = useState(new Date());

  const canSeeFiles = checkUserPrivileges('SEE_FOREIGN_FILES');
  const canUploadFiles = checkUserPrivileges('UPLOAD_FOREIGN_FILES');
  const isAdmin = checkUserPrivileges('IS_FERTILLY');
  const isClinic = checkUserPrivileges('IS_CLINIC');
  const uploadUserFiles = async (files: UserFile[]) => {
    const response = await fetchAPI(`/users/${userId}/files/`, {
      withToken: true,
      method: 'POST',
      body: JSON.stringify({ files }),
    });

    if (response.items) {
      setFetchFiles(new Date());
    }
  };

  useEffect(() => {
    const answers: AdditionalDataAnswers | undefined =
      surveyModel?.answers && JSON.parse(surveyModel.answers);

    if (answers) setAdditionalData(buildAdditionalData(answers));
  }, [surveyModel?.answers]);

  useEffect(() => {
    refreshUnreadMessages();
  }, [refreshUnreadMessages]);
  const mainCond: boolean = isClinic ? !!assignedClinic?.chatEnabled && !isAdmin : (!!assignedClinic?.chatEnabled && !isAdmin) && assignedClinic.chatFromUserEnabled
  const isChatEnabled: boolean = mainCond
      ? !!userGroups.length
        ? !!chatStreams
        : true
      : false;

  return (
    <div className='grid'>
      <div className=' flex flex-column w-full mb-2 md:flex-row'>
        {user.person && (
          <div className='col-12 md:col-6 xl:col-6'>
            <div className="card pb-0 flex flex-wrap">
              <h3 className='text-2xl mb-5 w-12'>{getLocaleOption('profileData')}</h3>
              {userOptions(user, getLocaleOption).map(
                (option, index) =>
                  option.value && (
                    <UserDataItem
                      key={index}
                      title={getLocaleOption(option.title)}
                      value={option.value}
                    />
                  )
              )}
              {additionalData.map(
                (option, index) =>
                  option.value && (
                    <UserDataItem key={index} title={option.title} value={option.value} />
                  )
              )}
            </div>
            
          </div>
        )}
        {isChatEnabled && (
          <div className='col-12 md:col-6 xl:col-6'>
            <Chat
              disabled={!isChatEnabled}
              userId={userId}
              chatStreams={chatStreamsItems}
              chatMessages={messages}
            />
          </div>
        )}
      </div>
      <div className='col-12'>
        <div className='mb-5'>
          <Partner userId={userId} users={users} coupleId={coupleId} />
        </div>
        <div className='mb-5'>
          <Questionnaires userId={userId} person={person} />
        </div>
        <div className='mb-5'>
          {canSeeFiles && <UserFiles userId={userId} fetchFiles={fetchFiles} />}
        </div>
        <div className='mb-5'>
          {canUploadFiles && <CustomFileUpload onUpload={uploadUserFiles} />}
        </div>
        {assignedClinic && !!userGroups.length && (
          <AssignUserGroups
            userId={userId}
            userGroups={userGroups}
            chatStreams={chatStreamsItems}
          />
        )}
      </div>
    </div>
  );
};
