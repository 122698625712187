import { useState, useCallback } from 'react';

import { useAPI } from 'context/api-context';
import { useLocale } from 'context/locale-context';
import { useSettings } from 'context/settings-context';

import { Button, ButtonProps } from 'primereact/button';

import { downloadBlobURL } from 'shared/helpers/openBlobURL';

interface DownloadFileProps extends ButtonProps {
  value: string;
}

const DownloadFile = ({ value }: DownloadFileProps) => {
  const { fetchFileAPI } = useAPI();
  const { getLocaleOption } = useLocale();
  const { showToast } = useSettings();

  const [fetching, setFetching] = useState(false);

  const downloadFile = useCallback(async () => {
    setFetching(true);

    const blob: Blob | undefined = await fetchFileAPI(`/files/uuid=${value}/data/`, {
      customHeaders: {
        Accept: 'application/pdf',
      },
    });

    if (blob) {
      downloadBlobURL(blob, '');
    } else {
      showToast({
        type: 'error',
        title: getLocaleOption('error'),
        text: getLocaleOption('somethingWentWrong'),
      });
    }

    setFetching(false);
  }, [fetchFileAPI, getLocaleOption, showToast, value]);

  return (
    <Button
      loading={fetching}
      label={getLocaleOption('download')}
      onClick={downloadFile}
    />
  );
};

export default DownloadFile;
