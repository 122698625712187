const openBlobURL = (blob: Blob) => {
  const createUrl = URL.createObjectURL(blob);

  const newWindow = window.open('/');
  newWindow!.onload = () => {
    newWindow!.location = createUrl;
  };

  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(createUrl);
  }, 100);
};

export const downloadBlobURL = (blob: Blob, fileName: string) => {
  const a = document.createElement('a');
  document.body.appendChild(a);

  const createUrl = URL.createObjectURL(blob);
  // window.open(createUrl, '_blank');

  a.href = createUrl;
  a.download = fileName;
  a.click();

  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(createUrl);
  }, 100);
};

export default openBlobURL;
