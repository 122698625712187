import React from 'react';

import { SplitButton } from 'primereact/splitbutton';

import { useLocale, Locales } from '../../context/locale-context';

const LocaleSelector = () => {
  const { locale, changeLocale } = useLocale();

  const items = [
    {
      label: Locales.EN.toUpperCase(),
      icon: 'pi-flag-fill',
      command: () => {
        changeLocale(Locales.EN);
      },
    },
    {
      label: Locales.DE.toUpperCase(),
      icon: 'pi-flag-fill',
      command: () => {
        changeLocale(Locales.DE);
      },
    },
    {
      label: Locales.FR.toUpperCase(),
      icon: 'pi-flag-fill',
      command: () => {
        changeLocale(Locales.FR);
      },
    },
    {
      label: Locales.IT.toUpperCase(),
      icon: 'pi-flag-fill',
      command: () => {
        changeLocale(Locales.IT);
      },
    },
    {
      label: Locales.ES.toUpperCase(),
      icon: 'pi-flag-fill',
      command: () => {
        changeLocale(Locales.ES);
      },
    },
    {
      label: Locales.TR.toUpperCase(),
      icon: 'pi-flag-fill',
      command: () => {
        changeLocale(Locales.TR);
      },
    },
    {
      label: Locales.AR.toUpperCase(),
      icon: 'pi-flag-fill',
      command: () => {
        changeLocale(Locales.AR);
      },
    },
    {
      label: Locales.PT.toUpperCase(),
      icon: 'pi-flag-fill',
      command: () => {
        changeLocale(Locales.PT);
      },
    },
    {
      label: Locales.NL.toUpperCase(),
      icon: 'pi-flag-fill',
      command: () => {
        changeLocale(Locales.NL);
      }
    },
  ];

  return (
    <div className='locale-selector'>
      <SplitButton label={locale.toUpperCase()} model={items} className=''></SplitButton>
    </div>
  );
};

export default LocaleSelector;
