import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAPI } from 'context/api-context';
import { useSettings } from 'context/settings-context';
import { useAuth } from 'context/auth-context';
import { useLocale } from 'context/locale-context';

import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';

import Layout from 'components/Layout/Layout';
import InviteLink from './InviteLink';
import DownloadPDF from 'components/DownloadPDF/DownloadPDF';

import { Questionnaire, SurveyType } from 'shared/interfaces';
import { QuestionnairesListColumn } from 'shared/types/Questionnaire';

interface QuestionnairesListProps {
  surveyType?: SurveyType;
}

const QuestionnairesList = ({ surveyType }: QuestionnairesListProps) => {
  const navigate = useNavigate();
  const { fetchAPI } = useAPI();
  const { user, checkUserPrivileges } = useAuth();
  const { getLocaleOption } = useLocale();
  const { showToast } = useSettings();

  const [fetching, setFetching] = useState(false);
  const [list, setList] = useState<Questionnaire[]>([]);
  const [selected, setSelected] = useState<Questionnaire | null>(null);
  const [globalFilter, setGlobalFilter] = useState<string | null>(null);

  const seeSameClinicUserQuestionnaire = checkUserPrivileges(
    'SEE_SAME_CLINIC_USER_QUESTIONNAIRE'
  );
  const accessBuilder = checkUserPrivileges('VIS_MENU__ADMIN__QUESTIONNAIRE_SPEC');
  const accessDelete = checkUserPrivileges('DELETE_SAME_CLINIC_QUESTIONNAIRE');
  const accessInvite = checkUserPrivileges('INVITE_USER');
  const isClient = checkUserPrivileges('IS_CLIENT');
  const isFertilly = checkUserPrivileges('IS_FERTILLY');

  useEffect(() => {
    const getQuestionnaires = async () => {
      setFetching(true);

      const url = seeSameClinicUserQuestionnaire ? '/surveys/' : '/usersurveys/';
      const url_additional = surveyType ? `?survey_type=${surveyType}` : '';
      const response = await fetchAPI(`${url}${url_additional}`, {
        withToken: true,
      });

      const questionnaires: Questionnaire[] = response.items;

      if (response.items) {
        const active = questionnaires.filter((item) => item.entityState !== 'DELETED');
        setList(active);
      } else {
        showToast({
          type: 'error',
          title: getLocaleOption('error'),
          text: getLocaleOption('somethingWentWrong'),
        });
      }

      setFetching(false);
    };

    getQuestionnaires();
  }, [seeSameClinicUserQuestionnaire, fetchAPI, surveyType, showToast, getLocaleOption]);

  useEffect(() => {
    if (selected) navigate('/questionnaire-builder', { state: selected });
  }, [selected, navigate, surveyType]);

  const searchHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setGlobalFilter(e.currentTarget.value);
  };

  const tableHeaderTemplate = (
    <div className='table-header flex align-items-center justify-content-between'>
      <h5 className='mx-0 my-1'>{getLocaleOption('questionnaires')}</h5>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          onInput={searchHandler}
          placeholder={`${getLocaleOption('search')}...`}
        />
      </span>
    </div>
  );

  const fillButtonTemplate = (rowData: QuestionnairesListColumn) => (
    <Button
      label={getLocaleOption('fill')}
      onClick={() => navigate(`/questionnaire/${rowData.id}`)}
    />
  );

  const editButtonTemplate = (rowData: QuestionnairesListColumn) => {
    if (typeof rowData === 'object') {
      const result = {
        edit: true,
        ...rowData,
      };

      return (
        <Button label={getLocaleOption('edit')} onClick={() => setSelected(result)} />
      );
    }
  };

  const statusBodyTemplate = (questionnaire: Questionnaire) => {
    const { completed } = questionnaire;
    return (
      <span className={`table-status-badge ${completed ? 'success' : 'alert'}`}>
        {completed ? getLocaleOption('completed') : getLocaleOption('notCompleted')}
      </span>
    );
  };

  const duplicateButtonTemplate = (rowData: QuestionnairesListColumn) => (
    <Button label={getLocaleOption('duplicate')} onClick={() => setSelected(rowData)} />
  );

  const deleteHandler = useCallback(
    async (id: number) => {
      const json = await fetchAPI(`/surveys/${id}/`, {
        method: 'DELETE',
        withToken: true,
      });

      if (json.id) {
        const stateQuestionnaires = [...list];
        const filter = stateQuestionnaires.filter((q) => q.id !== json.id);

        setList(filter);
        showToast({
          type: 'success',
          title: getLocaleOption('delete'),
          text: `${getLocaleOption('delete')} ${getLocaleOption('success')}`,
        });
      }
    },
    [fetchAPI, getLocaleOption, list, showToast]
  );

  const confirmDelete = useCallback(
    (id: number) => {
      confirmDialog({
        message: getLocaleOption('confirmDelete'),
        header: getLocaleOption('confirmation'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => deleteHandler(id),
        reject: () => false,
      });
    },
    [deleteHandler, getLocaleOption]
  );

  const deleteButtonTemplate = (rowData: QuestionnairesListColumn) => {
    const { id } = rowData;

    if (typeof id === 'number') {
      return (
        <Button
          label={getLocaleOption('delete')}
          className='p-button-danger'
          onClick={() => confirmDelete(id)}
        />
      );
    }
  };

  const inviteButtonTemplate = (questionnaire: QuestionnairesListColumn) => (
    <InviteLink id={questionnaire.id!} />
  );

  const { firstName, lastName, birthday } = user?.person || {};
  const downloadButtonTemplate = (questionnaire: QuestionnairesListColumn) => (
    <DownloadPDF
      fileName={`${firstName}_${lastName}_${birthday}`}
      url={`/usersurveys/${questionnaire.id}/_print/`}
    />
  );

  return (
    <Layout>
      <DataTable
        value={list}
        responsiveLayout='scroll'
        showGridlines
        paginator
        paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
        globalFilterFields={['name', 'clinicName']}
        currentPageReportTemplate={getLocaleOption('tableNumberingTemplate')}
        rows={10}
        rowsPerPageOptions={[10, 20, 50]}
        loading={fetching}
        globalFilter={globalFilter}
        header={tableHeaderTemplate}
        emptyMessage={getLocaleOption('tableNoAvailableOptions')}
      >
        <Column
          field='name'
          header={getLocaleOption('questionnaireName')}
          body={(data: QuestionnairesListColumn) => (
            <span className='font-bold'>{data.name}</span>
          )}
        />
        {!seeSameClinicUserQuestionnaire && (
          <Column header={getLocaleOption('fill')} body={fillButtonTemplate} />
        )}
        {(isClient || isFertilly) && (
          <Column
            field='clinicName'
            header={getLocaleOption('clinic')}
            filter
            filterField='clinicName'
            body={(data: Questionnaire) => (
              <span className='font-bold'>{data.clinicName}</span>
            )}
          />
        )}
        {accessBuilder && (
          <Column
            header={getLocaleOption('createdBy')}
            body={(data: QuestionnairesListColumn) => data.createdBy}
          />
        )}
        {accessBuilder && (
          <Column header={getLocaleOption('edit')} body={editButtonTemplate} />
        )}
        {!isFertilly && isClient && (
          <Column
            align='center'
            header={getLocaleOption('status')}
            body={statusBodyTemplate}
          />
        )}

        {accessBuilder && (
          <Column header={getLocaleOption('duplicate')} body={duplicateButtonTemplate} />
        )}
        {accessDelete && checkUserPrivileges('IS_FERTILLY_ADMIN') && (
          <Column header={getLocaleOption('delete')} body={deleteButtonTemplate} />
        )}
        {accessInvite && surveyType !== SurveyType.BASE_DATA && (
          <Column header={getLocaleOption('inviteLink')} body={inviteButtonTemplate} />
        )}
        <Column header={getLocaleOption('download')} body={downloadButtonTemplate} />
      </DataTable>
    </Layout>
  );
};

export default QuestionnairesList;
