import { UserState } from '../interfaces';

export class UserAttributeMapper {
  public static getUserStateTranslationKey(state: UserState): string {
    switch (state) {
      case UserState.INVITED:
        return 'statusInvited';
      case UserState.REGISTERED:
        return 'statusRegistered';
      case UserState.ANAMNESIS_INCOMPLETE:
        return 'statusAnamnesisIncomplete';
      case UserState.ANAMNESIS_COMPLETE:
        return 'completed';
      case UserState.ANAMNESIS_SUBMITTED:
        return 'statusAnamnesisSubmitted';
      case UserState.ANAMNESIS_TRANSFERRED:
        return 'statusAnamnesisTransferred';
      case UserState.ANAMNESIS_PARTIALLY_TRANSFERRED:
        return 'statusAnamnesisPartiallySubmitted';
      default:
        return '';
    }

  }
}
