import { Calendar as PrimeCalendar } from 'primereact/calendar';

import { InputContainer, InputErrorMessage, InputLabel } from './';

export type CalendarDate = Date | Date[] | undefined; // const [ date, setDate ] = useState<CalendarDate>(undefined);

export type DateType = 'DAY' | 'MONTH';

interface CalendarProps {
  date: CalendarDate;
  setDate: React.Dispatch<React.SetStateAction<CalendarDate>>;
  type?: DateType;
  error?: boolean;
  label: string;
  minValue?: string | number | Date;
  maxValue?: string | number | Date;
  noGutter?: boolean;
}

export const Calendar = ({
  date,
  setDate,
  type = 'DAY',
  error = false,
  label,
  minValue,
  maxValue,
  noGutter,
}: CalendarProps) => {
  const getValue = () => {
    return {
      min: minValue ? new Date(`${minValue}`) : undefined,
      max: maxValue ? new Date(`${maxValue}`) : undefined,
    };
  };

  return (
    <InputContainer noGutter={noGutter}>
      <InputLabel error={error}>{label}</InputLabel>
      <PrimeCalendar
        dateFormat={type === 'DAY' ? 'dd/mm/yy' : 'mm/yy'}
        value={date}
        onChange={(e) => setDate(e.value)}
        view={type === 'DAY' ? 'date' : 'month'}
        minDate={getValue().min}
        maxDate={getValue().max}
      />
      {error && <InputErrorMessage />}
    </InputContainer>
  );
};

export default Calendar;
