import { Serializer, Base } from 'survey-core';
import { JsonObjectProperty } from 'survey-react';

interface CustomProperties {
  printText: string;
  isQm: number;
  qmType: string;
  isNote: number;
  import: boolean;
  matchCode: string;
  prevMatchCode: string;
}

type JsonObjectCustomProperty = JsonObjectProperty & CustomProperties;

Serializer.addProperty('question', {
  name: 'importTitle',
  type: 'shorttext',
  category: 'general',
  visibleIndex: 2,
});
Serializer.addProperty('radiogroup', {
  name: 'importTitle',
  type: 'shorttext',
  category: 'general',
  visibleIndex: 2,
});

Serializer.addProperty('question', {
  name: 'printText',
  type: 'shorttext',
  category: 'general',
  visibleIndex: 3,
});

Serializer.addProperty('question', {
  name: 'isQm',
  type: 'number',
  choices: [
    { value: 1, text: 'Yes' },
    { value: 0, text: 'No' },
  ],
  category: 'general',
  onSetValue: (surveyElement: Base, value: number) => {
    if (value === 0) {
      surveyElement.setPropertyValue('qmType', null);
    }
    surveyElement.setPropertyValue('isQm', value);
  },
  visibleIndex: 4,
});

Serializer.addProperty('question', {
  name: 'qmType',
  type: 'shorttext',
  category: 'general',
  dependsOn: ['isQm'],
  visibleIf: (obj: JsonObjectCustomProperty) => obj['isQm'] === 1,
  visibleIndex: 5,
});

Serializer.addProperty('question', {
  name: 'isNote',
  type: 'number',
  choices: [
    { value: 1, text: 'Yes' },
    { value: 0, text: 'No' },
  ],
  category: 'general',
  visibleIndex: 6,
});

Serializer.addProperty('question', {
  name: 'import',
  type: 'boolean',
  category: 'general',
  visibleIndex: 8,
});
Serializer.addProperty('panel', {
  name: 'import',
  type: 'boolean',
  category: 'general',
  visibleIndex: 8,
});

Serializer.addProperty('question', {
  name: 'matchCode',
  type: 'shorttext',
  category: 'general',
  visibleIndex: 8,
});
Serializer.addProperty('panel', {
  name: 'matchCode',
  type: 'shorttext',
  category: 'general',
  visibleIndex: 8,
});
Serializer.addProperty('multipletextitem', {
  name: 'matchCode',
  type: 'shorttext',
  category: 'general',
  visibleIndex: 8,
});

Serializer.addProperty('question', {
  name: 'prevMatchCode',
  type: 'shorttext',
  category: 'general',
  visibleIndex: 9,
});
Serializer.addProperty('panel', {
  name: 'prevMatchCode',
  type: 'shorttext',
  category: 'general',
  visibleIndex: 9,
});
