import { Questionnaire, User } from '../../../shared/interfaces';
import Overlay from '../../../components/Overlay';
import AssignQuestionnaire from './AssignQuestionnaire';

const UsersListAssignBody = (props: {questionnaires: Questionnaire[], errorMessage: string, label: string, user: User}) => {

  // todo check questionnaires attribute performance;
  // todo check callback;
  return <Overlay style={{ width: '400px' }} label={props.label}>
    <AssignQuestionnaire
      user={props.user}
      questionnaires={props.questionnaires}
      error={false}
      errorMessage={props.errorMessage}
    />
  </Overlay>
};

export default UsersListAssignBody;
