export interface HandleUserGroupsProps {
  clinicId: number;
}

export enum UserGroupsDialogOptions {
  add = 'add',
  update = 'update',
}

export type AddUpdateGroupDialogState =
  | UserGroupsDialogOptions.add
  | UserGroupsDialogOptions.update
  | null;
