import { v4 as uuid } from 'uuid';

import { RadioButton, RadioButtonChangeParams } from 'primereact/radiobutton';

import { CheckedElement } from '../../shared/interfaces';

import { InputLabel, InputContainer, InputErrorMessage } from './';

interface RadioProps {
  items: CheckedElement[];
  setItems: React.Dispatch<React.SetStateAction<CheckedElement[]>>;
  label: string;
  error?: boolean;
  radioColumn?: boolean;
  noGutter?: boolean;
}

export const Radio = ({
  items,
  setItems,
  label,
  error,
  radioColumn,
  noGutter,
}: RadioProps) => {
  const changeHandler = (e: RadioButtonChangeParams) => {
    const modifyItems = [...items].map((item) => {
      return {
        ...item,
        checked: item.title === e.value ? true : false,
      };
    });
    setItems(modifyItems);
  };

  return (
    <InputContainer noGutter={noGutter}>
      <InputLabel error={error}>{label}</InputLabel>
      <div className={`flex ${radioColumn ? 'flex-column' : ''} flex-wrap mt-3`}>
        {items.map(({ title, checked }, index) => {
          const id = uuid();
          return (
            <div className='field-radiobutton mr-5' key={index}>
              <RadioButton
                inputId={id}
                name='option'
                value={title}
                checked={checked}
                onChange={changeHandler}
              />
              <label htmlFor={id}>{title}</label>
            </div>
          );
        })}
      </div>
      {error && <InputErrorMessage />}
    </InputContainer>
  );
};

export default Radio;
