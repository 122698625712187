import React from 'react';

import Layout from 'components/Layout/Layout';
import DashboardButton from './Home/DashboardButton';

import { useAuth } from 'context/auth-context';
import { useLocale } from 'context/locale-context';

import background from 'assets/images/dashboard.png';

const Dashboard = () => {
  const { getUserName } = useAuth();
  const userNames = getUserName();
  const { getLocaleOption } = useLocale();

  return (
    <Layout>
      <div className='dashboard absolute left-0 top-0 w-full h-screen overflow-hidden'>
        <div className='absolute right-0 top-0 bottom-0'>
          <img src={background} alt='background' className='dashboard-background' />
        </div>
        <div className='h-full'>
          <div className='h-full flex align-items-center relative'>
            <div className='w-full container relative px-3'>
              <div className='dashboard-container'>
                <div className='dashboard-text'>
                  <h2 className='text-4xl font-normal mb-5'>
                    {getLocaleOption('hello')} {userNames[0]}
                  </h2>
                  <p className='text-xl font-normal mb-5'>
                    {getLocaleOption('dashboardText')}
                  </p>
                  <DashboardButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
