import { useEffect, useState } from 'react';
import { useAPI } from 'context/api-context';
import { useSettings } from 'context/settings-context';
import { useLocale } from 'context/locale-context';
import { Button } from 'primereact/button';
import { Dropdown } from 'components/Inputs';
import { User as UserInterface, Questionnaire, CheckedElement } from 'shared/interfaces';

interface AssignQuestionnaireProps {
  user: UserInterface;
  questionnaires: Questionnaire[];
  error: boolean;
  errorMessage: string;
}

const AssignQuestionnaire = ({
  user,
  questionnaires,
  error,
  errorMessage,
}: AssignQuestionnaireProps) => {
  const { fetchAPI } = useAPI();
  const { showToast } = useSettings();
  const { getLocaleOption } = useLocale();

  const [questionnairesList, setQuestionnairesList] = useState<CheckedElement[]>([]);
  const [selectedQuestionnaire, setSelectedQuestionnaire] =
    useState<CheckedElement | null>(null);
  const [selectedQuestionnaireEmpty, setSelectedQuestionnaireEmpty] = useState(false);

  useEffect(() => {
    if (questionnaires.length === 0) return;

    const mapQuestionnaires: CheckedElement[] = questionnaires.map(({ name, id }) => {
      return {
        title: name,
        checked: false,
        name: id!, // we "hack" property here, id will be stored as name - id is required later when clicking assign button
      };
    });

    setQuestionnairesList(mapQuestionnaires);
  }, [questionnaires]);

  useEffect(() => {
    const findSelected = questionnairesList.find(
      (questionnaire) => questionnaire.checked
    );
    findSelected && setSelectedQuestionnaire(findSelected);
  }, [questionnairesList]);

  const assignQuestionnaireHandler = async () => {
    if (!selectedQuestionnaire) return setSelectedQuestionnaireEmpty(true);

    const questionnaireId = selectedQuestionnaire.name;
    const userId = user.id;

    const json = await fetchAPI(
      `/surveys/${questionnaireId}/user_id=${userId}/_assign/`,
      {
        method: 'POST',
        withToken: true,
      }
    );

    if (json.error || json.code) {
      showToast({
        type: 'error',
        title: getLocaleOption('fail'),
        text: json.message,
      });
    } else {
      accept();
    }
  };

  const accept = () => {
    setSelectedQuestionnaireEmpty(false);

    showToast({
      type: 'success',
      title: getLocaleOption('assign'),
      text: `${getLocaleOption('youHaveAssigned')} ${
        selectedQuestionnaire!.title
      } ${getLocaleOption('youHaveAssignedTo')} ${user.username}`,
    });
  };

  return error ? (
    <h5 className='mb-0'>{errorMessage}</h5>
  ) : (
    <div className='relative grid mb-0'>
      <div className='col-12'>
        <Dropdown
          label={getLocaleOption('assignQuestionnaire')}
          options={questionnairesList}
          setOptions={setQuestionnairesList}
          error={selectedQuestionnaireEmpty}
        />
        <Button label={getLocaleOption('assign')} onClick={assignQuestionnaireHandler} />
      </div>
    </div>
  );
};

export default AssignQuestionnaire;
