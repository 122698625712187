import { createContext, useContext, useCallback } from 'react';

import { API_URL } from '../shared/Constants';

import { getToken, Token } from '../shared/helpers/localStorage';

import { useSettings } from './settings-context';
import { useLocale } from './locale-context';

type FetchFunction = (
  url: string,
  obj?: {
    method?: string;
    withToken?: boolean;
    customHeaders?: {};
    body?: BodyInit;
  }
) => Promise<any>;

interface API {
  fetchAPI: FetchFunction;
  fetchFileAPI: (
    url: string,
    obj?: {
      method?: string;
      customHeaders?: {};
    }
  ) => Promise<any>;
}

export const APIContext = createContext<API>(null!);

const APIContextProvider: React.FC = ({ children }) => {
  const { errorHandler } = useSettings();
  const { locale } = useLocale();

  const fetchAPI: FetchFunction = useCallback(
    async (url, obj) => {
      try {
        const token = getToken(Token.ACCESS_TOKEN);

        const defaultHeaders = {
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        };

        const headers = obj?.withToken
          ? { Authorization: `Bearer ${token}`, ...defaultHeaders, ...obj.customHeaders! }
          : obj?.customHeaders
          ? { ...obj.customHeaders! }
          : defaultHeaders;

        const request = await fetch(API_URL + url, {
          method: obj?.method ? obj.method : 'GET',
          headers,
          body: obj?.body,
        });

        if (request.ok && obj?.method === 'DELETE') return { deleted: true };
        if (request.ok && request.url.endsWith('/rollout')) return { accepted: true };

        const json = await request.json();

        return json;
      } catch (err) {
        console.error(err);
        errorHandler(err);
      }
    },
    [locale, errorHandler]
  );

  const fetchFileAPI = async (
    url: string,
    obj?: {
      method?: string;
      customHeaders?: {};
    }
  ) => {
    try {
      const token = getToken(Token.ACCESS_TOKEN);

      const defaultHeaders = {
        Authorization: `Bearer ${token}`,
        'Accept-Language': locale,
      };

      const request = await fetch(API_URL + url, {
        method: obj?.method ? obj.method : 'GET',
        headers: { ...defaultHeaders, ...obj?.customHeaders },
      });

      if (!request.ok) return undefined;

      const blob: Blob = await request.blob();

      return blob;
    } catch (err) {
      return undefined;
    }
  };

  return (
    <APIContext.Provider
      value={{
        fetchAPI,
        fetchFileAPI
      }}
    >
      {children}
    </APIContext.Provider>
  );
};

export const useAPI = () => useContext(APIContext);

export default APIContextProvider;
