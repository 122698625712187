import { useState, useCallback } from 'react';

import { useLocale } from 'context/locale-context';
import { useAPI } from 'context/api-context';
import { useSettings } from 'context/settings-context';

import { Button } from 'primereact/button';

import { downloadBlobURL } from 'shared/helpers/openBlobURL';

interface TitleProps {
  name: string;
  adminView: boolean;
  id: number;
  isProfileFilled: boolean;
  isGDTClinic: boolean;
}

const Title = ({ name, adminView, id, isProfileFilled, isGDTClinic }: TitleProps) => {
  const { getLocaleOption } = useLocale();
  const { fetchFileAPI } = useAPI();
  const { somethingWentWrongToast } = useSettings();

  const [fetching, setFetching] = useState(false);

  const pdfDownloadHandler = useCallback(async () => {
    setFetching(true);

    const response = await fetchFileAPI(`/users/${id}/_print/`, {
      method: 'POST',
      customHeaders: {
        Accept: 'application/pdf',
      },
    });

    if (response) {
      downloadBlobURL(response, `${name}.pdf`);
    } else {
      somethingWentWrongToast();
    }

    setFetching(false);
  }, [fetchFileAPI, id, name, somethingWentWrongToast]);

  const gdtDownloadHandler = useCallback(async () => {
    setFetching(true);

    const response = await fetchFileAPI(`/usersurveys/user_id=${id}/_gdt/`, {
      method: 'POST',
      customHeaders: {
        Accept: 'application/zip',
      },
    });

    if (response) {
      downloadBlobURL(response, `${name}.zip`);
    } else {
      somethingWentWrongToast();
    }

    setFetching(false);
  }, [fetchFileAPI, id, name, somethingWentWrongToast]);

  return (
    <div className='grid align-items-center'>
      <div className='flex align-items-center space-between col-12 flex-column lg:flex-row pt-0 pr-0'>
        <h3 className='mb-2 p-0 break-all col-12 lg:col-6'>{name}</h3>
        <div className='col-12 lg:col-6 flex justify-content-center lg:justify-content-end'>
          {adminView && isProfileFilled && isGDTClinic && (
            <Button
              label={getLocaleOption('gdtDownload')}
              onClick={gdtDownloadHandler}
              loading={fetching}
            />
          )}
          {adminView && isProfileFilled && (
            <Button
              label={getLocaleOption('download')}
              onClick={pdfDownloadHandler}
              loading={fetching}
              className='ml-4'
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Title;
