import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Dropdown as PrimeDropdown, DropdownChangeParams } from 'primereact/dropdown';

import { CheckedElement } from '../../shared/interfaces';

import { InputContainer, InputErrorMessage, InputLabel } from './';

interface DropdownProps {
  label: string;
  placeholder?: string;
  error?: boolean;
  options: CheckedElement[];
  setOptions: React.Dispatch<React.SetStateAction<CheckedElement[]>>;
  advanced?: boolean;
  filterBy?: 'title' | 'name';
  noGutter?: boolean;
}

interface DropdownOptionInterface extends CheckedElement {
  innerId: string;
}

export const Dropdown = ({
  label,
  placeholder = label,
  error,
  options,
  setOptions,
  advanced,
  filterBy = 'title',
  noGutter,
}: DropdownProps) => {
  const [dropdownOptions, setDropdownOptions] = useState<DropdownOptionInterface[]>([]);
  // const [ checkedOption, setCheckedOption ] = useState();

  useEffect(() => {
    const modifyOptions: DropdownOptionInterface[] = options.map((option) => {
      return {
        ...option,
        innerId: uuidv4(), // find checked by innerId
      };
    });

    setDropdownOptions(modifyOptions);
  }, [options]);

  const checkedOption = dropdownOptions.find((option) => option.checked);

  const changeHandler = (e: DropdownChangeParams) => {
    const modifyOptions: CheckedElement[] = dropdownOptions.map((option) => {
      // e.value === undefined means clear filter is clicked

      return {
        ...(option.id && { id: option.id }),
        title: option.title,
        name: option.name,
        checked: e.value === undefined ? false : option.innerId === e.value.innerId,
      };
    });

    setOptions(modifyOptions);
  };

  const valueTemplate = (option: CheckedElement, props: { placeholder: string }) => {
    if (option) {
      return (
        <div className='country-item country-item-value flex align-items-center'>
          {/* <img alt={option.title} src={img} className={`flag flag-${option.title.toLowerCase()}`} /> */}
          <div>{option.title}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const itemTemplate = (option: CheckedElement) => {
    return (
      <div className='country-item flex align-items-center'>
        {/* <img alt={option.title} src={img}  className={`flag flag-${option.title.toLowerCase()}`} /> */}
        <div>{option.title}</div>
      </div>
    );
  };

  return (
    <InputContainer noGutter={noGutter}>
      <InputLabel error={error}>{label}</InputLabel>
      <PrimeDropdown
        className={error ? 'p-invalid' : ''}
        optionLabel={filterBy}
        value={checkedOption}
        options={dropdownOptions}
        onChange={changeHandler}
        placeholder={placeholder}
        filter={advanced}
        showClear={advanced}
        filterBy={filterBy}
        valueTemplate={advanced && valueTemplate}
        itemTemplate={advanced && itemTemplate}
        resetFilterOnHide={advanced}
      />
      {error && <InputErrorMessage />}
    </InputContainer>
  );
};

export default Dropdown;
