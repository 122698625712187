import { memo, useCallback, useState } from 'react';
import { ChatMessage } from 'shared/interfaces/Chat';
import { useAPI } from '../../../context/api-context';

interface MessageProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  data: ChatMessage;
  isClinic: boolean;
}

const MessageComponent = ({ data, isClinic, ...props }: MessageProps) => {
  const { fetchAPI } = useAPI();
  const [read, setRead] = useState<boolean>(data.seen);
  const isSender =
    (isClinic && data.chatDirection === 'FROM_CLINIC') ||
    (!isClinic && data.chatDirection === 'FROM_PATIENT');
  const className =
    'chat-message  p-2 border-round-md ' +
    (isSender ? 'ml-auto bg-blue-50' : 'bg-orange-50');
  const [date, time] = data.createdOn.split('T');

  const onMessageUnread = useCallback(async (message: ChatMessage) => {
    const response: ChatMessage = await fetchAPI(`/chats/${message.id}/_unseen/`, {
      withToken: true,
      method: 'POST',
    });
    data.seen = response.seen;
    setRead(data.seen);
  }, []);

  return (
    <div className={className} {...props}>
      <i>{data.createdBy}</i>
      <p className='m-0 mb-2'>
        <i>
          {date} {time}
        </i>
      </p>
      <p className='text-lg font-semibold'>{data.message}</p>
      {read && <div onClick={() => onMessageUnread(data)} className="flex flex-row w-100 justify-content-end cursor-pointer"><i className="pi pi-check"></i></div>}
    </div>
  );
};

export const Message = memo(MessageComponent);
