import { Button } from 'primereact/button';

import {
  Input,
  InputContainer,
  CalendarDate,
  DateType,
  Calendar,
  InputLabel,
  InputErrorMessage,
} from './';

import { useLocale } from '../../context/locale-context';

interface MultiValueProps {
  type: 'text' | 'date';
  dateType?: DateType;
  label: string;
  inputs?: string[];
  setInputs?: React.Dispatch<React.SetStateAction<string[]>>;
  dates?: CalendarDate[];
  setDates?: React.Dispatch<React.SetStateAction<CalendarDate[]>>;
  error: boolean;
  noGutter?: boolean;
  minValue?: string | number | Date;
  maxValue?: string | number | Date;
}

export const MultiValue = ({
  inputs,
  setInputs,
  type,
  dateType,
  label,
  dates,
  setDates,
  error,
  noGutter,
  minValue,
  maxValue,
}: MultiValueProps) => {
  const { getLocaleOption } = useLocale();

  const changeValueHandler = (value: string | CalendarDate, index: number) => {
    if (type === 'text') {
      const stateInputs = [...inputs!];
      stateInputs[index] = value as string;
      setInputs!(stateInputs);
    }
    if (type === 'date') {
      const stateDates = [...dates!];
      stateDates[index] = value as CalendarDate;
      setDates!(stateDates);
    }
  };

  const addInputHandler = () => {
    if (type === 'text') {
      const stateInputs = [...inputs!];
      stateInputs.push('');
      setInputs!(stateInputs);
    }
    if (type === 'date') {
      const stateDates = [...dates!];
      stateDates.push(undefined);
      setDates!(stateDates);
    }
  };

  const removeItemHandler = (index: number) => {
    if (type === 'text') {
      const stateInputs = [...inputs!];
      const filter = stateInputs.filter((input, i) => index !== i);
      setInputs!(filter);
    }
    if (type === 'date') {
      const stateDates = [...dates!];
      const filter = stateDates.filter((input, i) => index !== i);
      setDates!(filter);
    }
  };

  return (
    <InputContainer noGutter={noGutter}>
      <InputLabel>{label}</InputLabel>
      {type === 'text' &&
        inputs!.map((input, index) => {
          const isLastItem = index === inputs!.length - 1;

          return (
            <div className='flex align-items-center' key={index}>
              <div className='flex-1'>
                <Input
                  value={input}
                  setValue={(val) => changeValueHandler(val as string, index)}
                  id=''
                  label=''
                />
              </div>
              <div
                className='ml-2 flex justify-content-end'
                style={{ width: '130px', transform: 'translateY(-7px)' }}
              >
                {isLastItem ? (
                  <Button
                    label={`+ ${getLocaleOption('add')}`}
                    onClick={addInputHandler}
                  />
                ) : (
                  <Button
                    icon='pi pi-times'
                    className='p-button-rounded p-button-danger'
                    onClick={() => removeItemHandler(index)}
                  />
                )}
              </div>
            </div>
          );
        })}
      {type === 'date' &&
        dates!.map((date, index) => {
          const isLastItem = index === dates!.length - 1;

          return (
            <div className='flex align-items-center' key={index}>
              <div className='flex-1'>
                <Calendar
                  date={date}
                  setDate={(val) => changeValueHandler(val as CalendarDate, index)}
                  label=''
                  type={dateType}
                  minValue={minValue}
                  maxValue={maxValue}
                />
              </div>
              <div
                className='ml-2 flex justify-content-end'
                style={{ width: '130px', transform: 'translateY(-7px)' }}
              >
                {isLastItem ? (
                  <Button
                    label={`+ ${getLocaleOption('add')}`}
                    onClick={addInputHandler}
                  />
                ) : (
                  <Button
                    icon='pi pi-times'
                    className='p-button-rounded p-button-danger'
                    onClick={() => removeItemHandler(index)}
                  />
                )}
              </div>
            </div>
          );
        })}
      {error && <InputErrorMessage message={getLocaleOption('eachMultiValueRequired')} />}
    </InputContainer>
  );
};

export default MultiValue;
