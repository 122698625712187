import React, { useState } from 'react';

import Auth, {
  AuthContainer,
  AuthLink,
  AuthButton,
  FormContainer,
  AuthMessage,
} from '../Auth';
import { Input, InputError } from 'components/Inputs';

import { useAPI } from 'context/api-context';
import { useLocale } from 'context/locale-context';

import inputValidation from 'shared/helpers/inputsValidation';

const Login = () => {
  const { fetchAPI } = useAPI();
  const { getLocaleOption } = useLocale();

  const [fetching, setFetching] = useState(false);
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState<InputError>({ email: false });

  const submitHandler: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    const { hasError, errors } = inputValidation({ email });
    setError(errors);
    if (hasError) return;

    setFetching(true);
    setMessage('');

    const json = await fetchAPI('/auth/password/_reset_token/', {
      method: 'POST',
      body: JSON.stringify({ email }),
    });

    // will always return http200

    if (json) {
      setFetching(false);
      setEmail('');
      setMessage(getLocaleOption('passwordResetSuccess'));
    }
  };

  return (
    <Auth title={getLocaleOption('forgotPassword')}>
      <AuthContainer>
        <form onSubmit={submitHandler}>
          <FormContainer>
            <Input
              type='email'
              value={email}
              setValue={setEmail}
              id='email'
              label={getLocaleOption('email')}
              error={error.email}
            />
            <AuthButton label={getLocaleOption('submit')} loading={fetching} />
            <AuthMessage>{message}</AuthMessage>
          </FormContainer>
        </form>
        <AuthLink to='/login'>{getLocaleOption('login')}</AuthLink>
      </AuthContainer>
    </Auth>
  );
};

export default Login;
