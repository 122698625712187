import { InputText } from 'primereact/inputtext';
import ExportTable from './ExportTable';
import { useLocale } from '../../../context/locale-context';
import { ILazyLoadingParams } from '../UsersList';

const UsersListTableHeader = (params: ILazyLoadingParams, isArchived: boolean, totalElements: number, onChange: (event: React.ChangeEvent<HTMLInputElement>) => void) => {
  // @ts-ignore:
  const value = params.filters['global'] ? params.filters['global'].value : '';
  const { getLocaleOption } = useLocale();

  return (
    <div className='table-header flex align-items-center justify-content-between'>
      <h5 className='mx-0 my-1'>{getLocaleOption(isArchived ? 'archivedUsers' : 'users')} {!!totalElements ? `(${totalElements})` : ''}</h5>
      <div className='flex align-items-center'>
          <span className='p-input-icon-left mr-3'>
            <i className='pi pi-search' />
            <InputText
              value={value || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
              type='search'
              placeholder={`${getLocaleOption('search')}...`}
            />
          </span>
        <ExportTable params={params}/>
      </div>
    </div>
  );
};

export default UsersListTableHeader;
