interface ContentResponsiveProps {
  className?: string;
}

const ContentResponsive = ({
  children,
  className,
}: React.PropsWithChildren<ContentResponsiveProps>) => {
  return <div className={`content-responsive ${className || ''}`}>{children}</div>;
};

export default ContentResponsive;
