import { Dialog as PrimeDialog } from 'primereact/dialog';

interface DialogProps {
  header: string;
  visible: boolean;
  onHide: () => void;
}

const Dialog = ({
  children,
  header,
  visible,
  onHide,
}: React.PropsWithChildren<DialogProps>) => {
  return (
    <PrimeDialog
      header={header}
      visible={visible}
      style={{ width: '50vw' }}
      onHide={onHide}
    >
      {children}
    </PrimeDialog>
  );
};

export default Dialog;
