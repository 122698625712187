import { useEffect, useState, useRef, useMemo, memo, useCallback } from 'react';

import { useAPI } from 'context/api-context';
import { useAuth } from 'context/auth-context';
import { useLocale } from 'context/locale-context';

import { TabViewTabChangeParams } from 'primereact/tabview';

import { Message } from './components/Message';
import { InputArea } from './components/InputArea';
import { ChatTabs } from './components/ChatTabs';

import { ChatMessage, ChatResponse, ChatStream } from 'shared/interfaces/Chat';
import { UserMessages } from 'shared/interfaces';

interface ChatProps {
  userId?: number;
  chatStreams?: ChatStream[];
  chatMessages: UserMessages | undefined;
  disabled?: boolean;
}

const MESSAGES_PER_PAGE = 10;
const FIRST_PAGE = 0;
const ONE_MESSAGE = 1;

const ChatComponent = ({ userId, chatStreams, chatMessages, disabled }: ChatProps) => {
  const { fetchAPI } = useAPI();
  const { user: me, checkUserPrivileges, login: refreshUser } = useAuth();
  const { getLocaleOption } = useLocale();

  const filteredChatStreams = useMemo(() => {
    if (chatStreams && chatMessages) {
      const result: ChatStream[] = [];

      chatMessages.forEach((m) => {
        chatStreams.forEach((cs) => {
          if (cs.id === m.chatStreamId) result.push(cs);
        });
      });

      return result;
    } else {
      return null;
    }
  }, [chatMessages, chatStreams]);

  const [currentChatStream, setCurrentChatStream] = useState<ChatStream | null>(
    filteredChatStreams?.[0] || null
  );
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [oldMessages, setOldMessages] = useState<ChatMessage[]>([]);
  const [currentPage, setCurrentPage] = useState(FIRST_PAGE);
  const [totalPages, setTotalPages] = useState(0);

  const [fetching, setFetching] = useState(false);

  const topRef = useRef<HTMLDivElement | null>(null);
  const bottomRef = useRef<HTMLDivElement | null>(null);

  const isClinic = checkUserPrivileges('IS_CLINIC');
  const hasNextPage = currentPage < totalPages;

  const fetchMessages = async (
    page: number,
    size: number,
    fetchOldMessages?: 'fetchOldMessages'
  ) => {
    setFetching(true);

    const url =
      '/chats/' +
      (userId ? `users/${userId}/` : '') +
      (currentChatStream ? `chatstreams/${currentChatStream.id}/` : '') +
      `?&page=${page}&size=${size}&option_mark_read=${true}`;

    const response: ChatResponse = await fetchAPI(url, {
      withToken: true,
      method: 'GET',
    });

    if (response.items) {
      setTotalPages(response.totalPages);

      const sortedMessages = response.items.sort((a, b) => a.id - b.id);

      if (fetchOldMessages) {
        setOldMessages((prevOldMessages) => {
          setMessages((prevMessages) => [...prevOldMessages, ...prevMessages]);
          return sortedMessages;
        });
      } else {
        setMessages((prevState) => [...prevState, ...sortedMessages]);
      }
    }

    setFetching(false);
  };

  useEffect(() => {
    setMessages([]);
    setOldMessages([]);
    fetchMessages(FIRST_PAGE, MESSAGES_PER_PAGE).then(() => {
      bottomRef.current?.scrollIntoView({
        block: 'nearest',
        inline: 'start',
      });
      !isClinic && refreshUser();
    });

    return () => {
      setMessages([]);
      setOldMessages([]);
    };
  }, [currentChatStream, userId]); // eslint-disable-line

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const element = event.target as HTMLDivElement;

    if (fetching) return;

    if (element.scrollTop < 70 && hasNextPage) {
      setCurrentPage((prev) => prev + 1);
      fetchMessages(currentPage + 1, MESSAGES_PER_PAGE, 'fetchOldMessages').then(() => {
        if (topRef.current) {
          element.scrollTo(0, topRef.current.offsetTop - element.offsetTop);
        }
      });
    }
  };

  const sendMessage = async (message: string) => {
    setFetching(true);

    await fetchAPI(`/chats/`, {
      withToken: true,
      method: 'POST',
      body: JSON.stringify({
        attachedFileUUID: null,
        message,
        patientUserId: userId || me?.id,
        rowVersion: 0,
        chatStreamId: currentChatStream?.id,
      }),
    });

    fetchMessages(FIRST_PAGE, ONE_MESSAGE).then(() =>
      bottomRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      })
    );
  };

  const handleTabChange = (e: TabViewTabChangeParams) => {
    if (chatStreams) {
      const { index } = e;
      setActiveTabIndex(index);
      setCurrentChatStream(chatStreams[index]);
    }
  };

  return (
    <div className='flex flex-column h-full'>
      <h3 className={isClinic ? 'text-2xl mb-5' : 'mb-4'}>
        {getLocaleOption('messages')}
      </h3>
      {chatMessages && filteredChatStreams && filteredChatStreams.length > 1 && (
        <ChatTabs
          activeTabIndex={activeTabIndex}
          handleTabChange={handleTabChange}
          chatStreams={filteredChatStreams}
          chatMessages={chatMessages}
        />
      )}
      <div
        className='chat-window flex-1 mb-3 p-3 pb-0 bg-white border-round-md overflow-auto'
        style={{ maxHeight: userId ? '317px' : '55.1vh' }}
        onScroll={handleScroll}
      >
        {oldMessages.map((m, index) => (
          <Message key={m.createdOn + index} data={m} isClinic={isClinic} />
        ))}
        <div ref={topRef} />
        {messages.map((m, index) => (
          <Message key={m.createdOn + index} data={m} isClinic={isClinic} />
        ))}
        <div ref={bottomRef} />
      </div>
      <InputArea disabled={!!disabled} fetching={fetching} sendMessage={sendMessage} />
    </div>
  );
};

export const Chat = memo(ChatComponent);
