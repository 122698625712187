import { useRef, useEffect, useState } from 'react';

import {
  OverlayPanel,
  OverlayPanelEventType,
  OverlayPanelProps,
} from 'primereact/overlaypanel';
import { Button } from 'primereact/button';

interface OverlayProps extends OverlayPanelProps {
  label: string;
  disabled?: boolean;
}

const Overlay = ({
  style,
  className,
  label,
  children,
  disabled,
}: React.PropsWithChildren<OverlayProps>) => {
  const [toggle, setToggle] = useState<OverlayPanelEventType>();

  const op = useRef<any>(null);

  useEffect(() => {
    if (toggle) op.current.toggle(toggle);
  }, [toggle]);

  return (
    <>
      <Button
        className={className}
        type='button'
        label={label}
        onClick={(e) => setToggle(e)}
        disabled={disabled}
      />
      <OverlayPanel ref={op} style={style}>
        {children}
      </OverlayPanel>
    </>
  );
};

export default Overlay;
