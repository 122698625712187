import { Survey } from 'survey-react-ui';
import { Model } from 'survey-core';

import { Button } from 'primereact/button';

import FetchingContainer from 'components/FetchingContainer';
import ContentResponsive from 'components/Layout/ContentResponsive';

interface QuestionnaireRunnerLayoutProps {
  surveyFetching: boolean;
  surveyCompleted: boolean;
  survey: Model;
  onAfterCompleted?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const QuestionnaireRunnerLayout = ({
  surveyFetching,
  surveyCompleted,
  survey,
  onAfterCompleted,
}: QuestionnaireRunnerLayoutProps) => {
  return (
    <FetchingContainer fetching={surveyFetching}>
      <ContentResponsive>
        <Survey model={survey} />
      </ContentResponsive>
      {surveyCompleted && (
        <div className='flex mt-8'>
          <Button className='p-button-lg mx-auto' onClick={onAfterCompleted}>
            Ok
          </Button>
        </div>
      )}
    </FetchingContainer>
  );
};
