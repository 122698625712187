import { useState } from 'react';

import { useAPI } from 'context/api-context';
import { useLocale } from 'context/locale-context';
import { useSettings } from 'context/settings-context';

import { Button } from 'primereact/button';
import { Input } from 'components/Inputs';

import Overlay from 'components/Overlay';

interface InviteLinkProps {
  id: number;
}

const InviteLink = ({ id }: InviteLinkProps) => {
  const { fetchAPI } = useAPI();
  const { getLocaleOption } = useLocale();
  const { showToast } = useSettings();

  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  const inviteHandler: React.FormEventHandler<HTMLFormElement> = async (e) => {
    if (!email) return setError(true);

    e.preventDefault();

    setError(false);

    const json = await fetchAPI('/users/_invite/', {
      method: 'POST',
      withToken: true,
      body: JSON.stringify({ email, surveyId: id }),
    });

    if (json.code) {
      // error
      return showToast({
        type: 'error',
        title: getLocaleOption('error'),
        text: json.message,
      });
    }

    showToast({
      type: 'success',
      title: getLocaleOption('success'),
      text: getLocaleOption('invited'),
    });
  };

  return (
    <Overlay style={{ width: '400px' }} label={getLocaleOption('sendInviteLink')}>
      <div className='relative mb-0'>
        <div className='grid'>
          <div className='col-12'>
            <form onSubmit={inviteHandler}>
              <Input
                id='user-email'
                type='email'
                label={getLocaleOption('email')}
                value={email}
                setValue={setEmail}
                error={error}
              />
              <Button type='submit' label={getLocaleOption('submit')} />
            </form>
          </div>
        </div>
      </div>
    </Overlay>
  );
};

export default InviteLink;
