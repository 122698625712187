import { createContext, useState, useEffect, useContext, useCallback } from 'react';

import { useAPI } from './api-context';

import { getAllTokens, removeTokens, setAllTokens } from '../shared/helpers/localStorage';
import { User, UserPrivileges } from '../shared/interfaces';

interface Auth {
  user: undefined | User;
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  getUserName: () => string[];
  login: () => Promise<void>;
  logout: () => void;
  userCompleted: boolean;
  setUserCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  checkUserPrivileges: (privilege: UserPrivileges) => boolean;
}

export const AuthContext = createContext<Auth>(null!);

const AuthContextProvider: React.FC = ({ children }) => {
  const { fetchAPI } = useAPI();

  const [fetching, setFetching] = useState<boolean>(true);
  const [user, setUser] = useState<undefined | User>(undefined);
  const [userCompleted, setUserCompleted] = useState(false);

  const login = useCallback(async () => {
    const response = await fetchAPI('/auth/_me/', {
      method: 'GET',
      withToken: true,
    });

    if (!response || response.error) {
      setFetching(false);
      return;
    }

    if (!response.error) setUser(response);
  }, [fetchAPI]);

  useEffect(() => {
    const tokens = getAllTokens();

    if (!tokens || user) {
      setFetching(false);
      return;
    }

    const { refreshToken, accessTokenValidUntil, refreshTokenValidUntil } = tokens;

    const refresh = async () => {
      const refresh = await fetchAPI('/auth/_refresh/', {
        method: 'POST',
        customHeaders: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${refreshToken}`,
        },
      });

      if (!refresh || refresh.error) return setFetching(false);

      setAllTokens(
        refresh.access_token,
        refresh.refresh_token,
        refresh.accessTokenValidUntil,
        refresh.refreshTokenValidUntil
      );

      const user = await fetchAPI('/auth/_me/', {
        method: 'GET',
        customHeaders: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${refresh.access_token}`,
        },
      });

      if (!user || user.error) return setFetching(false);

      setUser(user);
    };

    // check if token is of date value - prevent error if user type some random string in tokens
    if (
      isNaN(Date.parse(accessTokenValidUntil)) ||
      isNaN(Date.parse(refreshTokenValidUntil))
    )
      return setFetching(false);

    const currentDate = new Date();
    const accessDate = new Date(Date.parse(accessTokenValidUntil));
    const refreshDate = new Date(Date.parse(refreshTokenValidUntil));

    if (currentDate < accessDate) {
      login();
    } else {
      if (currentDate < refreshDate) {
        refresh();
      } else {
        setFetching(false);
      }
    }
  }, [fetchAPI, user, login]);

  const setUserPrivileges = () => {
    if (user) setUserCompleted(!!user.person?.firstName);
  };

  useEffect(setUserPrivileges, [setUserPrivileges]);

  const getUserName = () => {
    const { person, username } = user!;

    const personFilled = person?.firstName && person.lastName;

    const personName = personFilled ? `${person.firstName} ${person.lastName}` : username;
    const fullName = personFilled ? `${person.firstName} ${person.lastName}` : personName;
    return [personName, fullName];
  };

  const checkUserPrivileges: (privilege: UserPrivileges) => boolean = (privilege) => {
    const hasPrivilege = user!.privileges.find((p) => p === privilege);

    return !!hasPrivilege;
  };

  const logout: () => void = () => {
    setUser(undefined);
    removeTokens();
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        setUserCompleted,
        getUserName,
        logout,
        login,
        userCompleted,
        checkUserPrivileges,
      }}
    >
      {!fetching ? children : null}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthContextProvider;
