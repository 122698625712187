import { useState } from 'react';

import { useLocale } from 'context/locale-context';
import { useAPI } from 'context/api-context';
import { useSettings } from 'context/settings-context';

import { Checkbox, CheckboxChangeParams } from 'primereact/checkbox';

import { ChatStream } from 'shared/interfaces/Chat';

interface UserGroupsProps {
  userId: number;
  chatStreams: ChatStream[] | undefined;
  userGroups: ChatStream[];
}

export const AssignUserGroups = ({
  userGroups,
  userId,
  chatStreams = [],
}: UserGroupsProps) => {
  const { getLocaleOption } = useLocale();
  const { fetchAPI } = useAPI();
  const { somethingWentWrongToast } = useSettings();

  const [selected, setSelected] = useState(() => chatStreams.map((c) => c.id.toString()));

  const handleToggleGroup = async ({ checked, value }: CheckboxChangeParams) => {
    const response: ChatStream = await fetchAPI(
      `/users/${userId}/chatstreams/${value}/${checked ? '_assign' : '_remove'}/`,
      { method: 'PUT', withToken: true }
    );

    if (response.id) {
      const result = [...selected];
      checked ? result.push(value) : result.splice(result.indexOf(value), 1);
      setSelected(result);
    } else {
      somethingWentWrongToast();
    }
  };

  return (
    <>
      <h3 className='text-2xl mb-2 mt-0'>{getLocaleOption('userGroups')}</h3>
      <div className='flex flex-wrap gap-3 bg-white p-3 border-round-md border-1 border-300'>
        {userGroups.map(({ id, name }) => {
          const idString = id.toString();
          const checked = selected.includes(idString);
          const disabled = !checked && selected.length >= 3;

          return (
            <div key={id} className='flex align-items-center'>
              <Checkbox
                inputId={idString}
                name={name}
                value={idString}
                onChange={handleToggleGroup}
                checked={checked}
                disabled={disabled}
                className={disabled ? 'cursor-auto' : ''}
              />
              <label
                htmlFor={idString}
                className={`ml-2 ${disabled ? 'opacity-50' : ''}`}
              >
                {name}
              </label>
            </div>
          );
        })}
      </div>
    </>
  );
};
