import {useCallback, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router';
import {Survey} from 'survey-react-ui';

import {useAPI} from 'context/api-context';
import {useAuth} from 'context/auth-context';
import {useSettings} from 'context/settings-context';
import {useLocale} from 'context/locale-context';

import {TabPanel, TabView, TabViewTabChangeParams} from 'primereact/tabview';

import Layout from 'components/Layout/Layout';
import Person from './components/Person';
import Preview from './components/Preview';
import Title from './components/Title';
import FetchingContainer from 'components/FetchingContainer';
import ContentResponsive from 'components/Layout/ContentResponsive';

import {useQuestionnaireRunner} from 'containers/Questionnaire/useQuestionnaireRunner';

import getISODate, {makeIOSCompatibleDate} from 'shared/helpers/ISODate';
import {CLINIC, getCheckboxTitle, getInputs, getInputsImmutable, isClinicOrFertillyUser,} from './User.helpers';
import {CheckedElement, ClinicProperties, User as UserInterface} from 'shared/interfaces';
import {PersonItem, UserMap} from './User.types';
import {useClinics} from '../../context/clinics-context';

interface UserState {
  user: UserInterface;
  users: UserInterface[];
}

const User = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { getLocaleOption, locale } = useLocale();
  const { fetchAPI } = useAPI();
  const { showToast } = useSettings();
  const { user, setUser, userCompleted } = useAuth();
  const { assignedClinic } = useClinics();

  const state = location.state as UserState; // this is same as user from useAuth hook, but its important that we have or not in state

  const { person } = user!;
  const clinicOrFertillyUser = isClinicOrFertillyUser(user);

  const [inputs, setInputs] = useState<PersonItem[]>([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [userMap, setUserMap] = useState<UserMap | null>(null);
  const [checkbox, setCheckbox] = useState<CheckedElement[]>([]);
  const [agreeError, setAgreeError] = useState(false);

  const saveUserBaseData = async (map: any, surveyCompleted?: boolean) => {
    const payload = Object.assign({}, ...map);

    const json = await fetchAPI(`/users/${user!.id}/person/`, {
      method: 'POST',
      withToken: true,
      body: JSON.stringify({ ...payload }),
    });

    if (json.error || json.code) {
      showToast({
        type: 'error',
        title: getLocaleOption('error'),
        text: json.message,
      });
    } else {
      const updatedUser: UserInterface = { ...user! };
      updatedUser.person = json;

      setUser(updatedUser);
      surveyCompleted && navigate(clinicOrFertillyUser ? '/users' : '/dashboard');
    }
  };

  const redirect = useCallback(
    () => navigate(clinicOrFertillyUser ? '/users' : '/dashboard'),
    [clinicOrFertillyUser, navigate]
  );

  const afterComplete = async () => {
    if (userMap) await saveUserBaseData(userMap);
    redirect();
  };

  const {
    fetching: questionnaireFetching,
    currentSurveyCompleted,
    survey,
    noQuestionnaireData,
  } = useQuestionnaireRunner(user?.id, afterComplete);

  const changeInputHandler = (
    value: React.SetStateAction<CheckedElement[]> | React.SetStateAction<string>,
    type: string,
    id: string
  ) => {
    const stateInputs = getInputsImmutable(inputs, clinicOrFertillyUser);
    const input = stateInputs.find((input) => input.id === id)!;

    if (type === 'string') input.value = value as string;
    if (type === 'dropdown') input.options = value as CheckedElement[];

    if (value !== 'UNKNOWN') input.error = false;

    setInputs(stateInputs);
  };

  const errorsCheck = (cb: () => Promise<void>) => {
    const checkDateFormatError = (date: string) => {
      // we will always have either no date or complete string that can be split into array of 3
      if (!date) return true;
      const [dd, mm, yyyy] = date.split('/');
      if (+dd > 31) return true;
      if (+mm > 12) return true;
      const year = +yyyy;
      const currentYear = new Date().getFullYear();
      if (year < 1900 || year > currentYear) return true;
      return false;
    };

    const stateInputs = getInputsImmutable(inputs, clinicOrFertillyUser);
    // filter
    let hasError = false;
    const map = stateInputs.map((input) => {
      const { mandatory, value, options, type, withISODate, noRender } = input;

      let error = false;
      let render = true;

      // check if input is rendered first
      if (noRender) {
        noRender.forEach((option) => {
          if (option === type) render = false;
          if (option === CLINIC && clinicOrFertillyUser) render = false;
        });
      }

      if (mandatory && render) {
        if (withISODate) {
          const modifyDate = value as string;
          const [dd, mm, yyyy] = modifyDate.split('/');
          const date = getISODate(makeIOSCompatibleDate(`${mm} ${dd} ${yyyy}`));

          const dateError = checkDateFormatError(value as string);
          if (!date || dateError) {
            error = true;
            hasError = true;
          }
        }

        if (type === 'string' && value === '') {
          error = true;
          hasError = true;
        }

        if (type === 'dropdown') {
          const findChecked = options!.find((option) => option.checked);

          if (!findChecked || findChecked.title === 'UNKNOWN') {
            error = true;
            hasError = true;
          }
        }
      }

      return {
        ...input,
        error,
      };
    });

    setInputs(map);

    const agree = checkbox[0].checked;

    setAgreeError(!agree);

    if (!hasError && agree) return cb();

    showToast({
      type: 'error',
      title: getLocaleOption('error'),
      text: getLocaleOption('eachMandatoryRequired'),
    });
  };

  const editProfileHandler = (index?: number) => {
    errorsCheck(async () => {
      const stateInputs = getInputsImmutable(inputs, clinicOrFertillyUser);
      // filter
      const map = stateInputs.map((input) => {
        const { value, id, type, options, withISODate } = input;

        let getValue = value;

        if (type === 'dropdown') {
          const findOption = options!.find((option) => option.checked);
          getValue = findOption?.title || '';
        }

        if (withISODate) {
          // on person it will be iso string always because it passed error check
          // on person2 date is not mandatory so it will return false, and backend require string not boolean
          const modifyDate = value as string;
          const [dd, mm, yyyy] = modifyDate.split('/');
          getValue = getISODate(makeIOSCompatibleDate(`${mm} ${dd} ${yyyy}`)) || '';
        }

        if (getValue === 'UNKNOWN') getValue = '';

        return {
          [id]: getValue,
        };
      });

      !noQuestionnaireData && index && setActiveTabIndex(index);

      if (currentSurveyCompleted || noQuestionnaireData) {
        await saveUserBaseData(map, currentSurveyCompleted);
        noQuestionnaireData && redirect();
      } else {
        setUserMap(map);
        setActiveTabIndex(1);
      }
    });
  };

  const handleTabChange = (e: TabViewTabChangeParams) => {
    const { index } = e;

    index === 1 && !currentSurveyCompleted
      ? editProfileHandler(index)
      : setActiveTabIndex(index);
  };

  useEffect(() => {
    setInputs(getInputs(person, locale, getLocaleOption, clinicOrFertillyUser, inputs));
  }, [locale]); // eslint-disable-line

  useEffect(() => {
    setCheckbox([
      {
        title: '',
        JSX: getCheckboxTitle(locale, !!assignedClinic?.termsAndConditions.trim()),
        checked: userCompleted, // it will be checked on person2 even its not rendered
      },
    ]);
  }, [locale, userCompleted, assignedClinic]); // eslint-disable-line

  return (
    // preview is what admin see, profile is what user see
    <Layout>
      <FetchingContainer fetching={questionnaireFetching}>
        <Title
          name={
            state
              ? state.user.username +
                (state.user.partnerPatientKey
                  ? ` | id: ${state.user.partnerPatientKey}`
                  : '')
              : user!.username
          }
          id={state ? state.user.id : user!.id}
          adminView={!!state}
          isProfileFilled={!!(state && state.user.person?.firstName)}
          isGDTClinic={assignedClinic?.clinicProperty === ClinicProperties.gdt}
        />

        {state ? (
          <Preview
            user={state ? state.user : user!}
            users={state.users}
            adminView={!!state}
          />
        ) : noQuestionnaireData ? (
          <Person
            inputs={inputs}
            changeInputHandler={changeInputHandler}
            clinicOrFertillyUser={clinicOrFertillyUser}
            agreeError={agreeError}
            checkbox={checkbox}
            setCheckbox={setCheckbox}
            editProfileHandler={editProfileHandler}
            completed={userCompleted}
          />
        ) : (
          <div className='transparent-tabs'>
            <TabView activeIndex={activeTabIndex} onTabChange={handleTabChange}>
              <TabPanel header={getLocaleOption('contactData')}>
                <Person
                  inputs={inputs}
                  changeInputHandler={changeInputHandler}
                  clinicOrFertillyUser={clinicOrFertillyUser}
                  agreeError={agreeError}
                  checkbox={checkbox}
                  setCheckbox={setCheckbox}
                  editProfileHandler={editProfileHandler}
                  completed={currentSurveyCompleted}
                />
              </TabPanel>
              <TabPanel header={getLocaleOption('additionalInformation')}>
                <ContentResponsive>
                  <Survey model={survey} />
                </ContentResponsive>
              </TabPanel>
            </TabView>
          </div>
        )}
      </FetchingContainer>
    </Layout>
  );
};

export default User;
