import { useEffect, useState } from 'react';

import { useAPI } from 'context/api-context';
import { useLocale } from 'context/locale-context';
import { Button } from 'primereact/button';

import { getDate } from 'shared/helpers/makeDate';
import { API_URL } from 'shared/Constants';
import { getToken, Token } from 'shared/helpers/localStorage';
import { Questionnaire, SurveyType, Person } from 'shared/interfaces';
import { useDownloadPDF } from '../../../components/DownloadPDF/useDownloadPDF';

interface TransferUserDataProps {
  person: Person | undefined;
  data: Questionnaire;
  userQuestionnaires: Questionnaire[];
  setUserQuestionnaires: React.Dispatch<React.SetStateAction<Questionnaire[]>>;
}

export const TransferUserData = ({
  person,
  data,
  userQuestionnaires,
  setUserQuestionnaires,
}: TransferUserDataProps) => {
  const { fetchAPI } = useAPI();
  const { getLocaleOption } = useLocale();
  const { id, completed, partnerLastSubmissionOn, surveyType } = data;
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);
  const { firstName, lastName, birthday } = person || {};

  const { downloadPDF } = useDownloadPDF({
    url: `/usersurveys/${id}/_print/`,
    fileName: `${firstName}_${lastName}_${birthday}`
  });

  useEffect(() => {
    if (error) setTimeout(() => setError(false), 4000);
  }, [error]);

  const handleSubmit = async () => {
    setFetching(true);

    let response: any;

    if (surveyType === SurveyType.MEDICAL) {
      response = await fetchAPI(`/usersurveys/${id}/_submit/`, {
        method: 'POST',
        withToken: true,
      });
    } else if (surveyType === SurveyType.MEDICAL_PDF) {
      const blob: Blob | undefined = await downloadPDF();

      if (!blob) {
        setError(true);
        setFetching(false);
        return;
      }

      const fileName: number = (userQuestionnaires.find((q: Questionnaire) => q.id === id)?.name || id) as number;
      const file: File = new File([blob], `${fileName}.pdf`, { type: 'application/pdf' });
      const bodyData: FormData = new FormData();
      bodyData.append('multiPartFile', file);

      const sendFile: Response = await fetch(`${API_URL}/usersurveys/${id}/_submit_file/`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${getToken(Token.ACCESS_TOKEN)}`,
        },
        body: bodyData,
      });

      response = await sendFile.json();
    }

    if (response.error || !response) {
      setError(true);
    } else {
      setUserQuestionnaires(
        userQuestionnaires.map((q: Questionnaire) => (q.id === response.id ? response : q))
      );
    }

    setFetching(false);
  };

  return (
    <Button
      disabled={!completed}
      loading={fetching}
      className={`p-button-${
        error ? 'danger' : partnerLastSubmissionOn ? 'success' : 'info'
      }`}
      label={
        error
          ? `${getLocaleOption('dataTransferFailed')}!`
          : partnerLastSubmissionOn
          ? `${getLocaleOption('successfullyTransferred')} ${getDate(
              partnerLastSubmissionOn
            )}`
          : getLocaleOption('dataNotTransferredYet')
      }
      onClick={handleSubmit}
    />
  );
};
