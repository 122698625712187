import { InputError } from '../../components/Inputs';

// user for auth inputs validation

/* 
    const [ errors, setErrors ] = useState<InputError>({
        userName: false,
        password: false
    });

    const { hasError, errors } = inputValidation({ userName, password });
    if(hasError) return setErrors(errors);
*/

interface Inputs {
  [key: string]: string;
}

const validate: (inputs: Inputs) => {
  hasError: boolean;
  errors: InputError;
} = (inputs: Inputs) => {
  const objectEntries = Object.entries(inputs);

  const makeErrorsObject = (array: [string, string][]) => {
    return array.reduce(function (p, c) {
      const empty = c[1] === '' ? true : false;
      // @ts-ignore
      p[c[0]] = empty;
      return p;
    }, {});
  };

  let hasError = false;

  for (const entry of objectEntries) {
    if (entry[1] === '') hasError = true;
  }

  return { hasError, errors: makeErrorsObject(objectEntries) };
};

export default validate;
