import { useState } from 'react';

import { Button } from 'primereact/button';
import { Input } from 'components/Inputs';

import Overlay from 'components/Overlay';

import { useAPI } from 'context/api-context';
import { useLocale } from 'context/locale-context';
import { useSettings } from 'context/settings-context';

interface InviteClinicProps {
  id: number;
}

const InviteClinic = ({ id }: InviteClinicProps) => {
  const { fetchAPI } = useAPI();
  const { getLocaleOption } = useLocale();
  const { showToast } = useSettings();

  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  const inviteHandler = async () => {
    if (!email) return setError(true);

    setError(false);

    const json = await fetchAPI('/users/_invite_clinic/', {
      method: 'POST',
      withToken: true,
      body: JSON.stringify({ email, clinicId: id }),
    });

    if (json.code) {
      // error
      return showToast({
        type: 'error',
        title: getLocaleOption('error'),
        text: json.message,
      });
    }

    showToast({
      type: 'success',
      title: getLocaleOption('success'),
      text: getLocaleOption('invited'),
    });
  };

  return (
    <Overlay style={{ width: '400px' }} label={getLocaleOption('sendInviteLink')}>
      <div className='relative mb-0'>
        <div className='grid'>
          <div className='col-12'>
            <Input
              id='user-email'
              label={getLocaleOption('email')}
              value={email}
              setValue={setEmail}
              error={error}
            />
            <Button label={getLocaleOption('submit')} onClick={inviteHandler} />
          </div>
        </div>
      </div>
    </Overlay>
  );
};

export default InviteClinic;
