import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';

import Auth, {
  AuthContainer,
  AuthLink,
  AuthButton,
  FormContainer,
  AuthMessage,
} from '../Auth';
import { Input, InputError } from 'components/Inputs';

import { useAPI } from 'context/api-context';
import { useLocale } from 'context/locale-context';

import inputValidation from 'shared/helpers/inputsValidation';

const ResetPassword = () => {
  const { fetchAPI } = useAPI();
  const { getLocaleOption } = useLocale();

  const { search } = useLocation();

  const [fetching, setFetching] = useState(false);
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState<InputError>({
    email: false,
    password: false,
    confirmPassword: false,
  });

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const token = searchParams.get('password_reset_token');

  useEffect(() => {
    const emailUrl = searchParams.get('email');
    setEmail(emailUrl ? emailUrl : '');
  }, [searchParams]);

  const submitHandler: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword)
      return setMessage(getLocaleOption('passwordNotSameError'));

    const { hasError, errors } = inputValidation({ email, password, confirmPassword });
    setErrors(errors);
    if (hasError) return;

    setFetching(true);
    setMessage('');

    const json = await fetchAPI('/auth/password/_reset/', {
      method: 'POST',
      body: JSON.stringify({ email, password: btoa(password), token }),
    });

    if (json.error || json.code === 400) {
      setFetching(false);
      return setMessage(json.message);
    }

    if (json.email) {
      setFetching(false);
      setMessage(getLocaleOption('passwordChanged'));
      setEmail('');
      setPassword('');
      setConfirmPassword('');
    }
  };

  return (
    <Auth title={getLocaleOption('resetPassword')}>
      <AuthContainer>
        <form onSubmit={submitHandler}>
          <FormContainer>
            <Input
              type='email'
              value={email}
              setValue={setEmail}
              id='email'
              label={`* ${getLocaleOption('email')}`}
              error={errors.email}
            />
            <Input
              type='password'
              value={password}
              setValue={setPassword}
              id='password'
              label={`* ${getLocaleOption('newPassword')}`}
              error={errors.password}
            />
            <Input
              type='password'
              value={confirmPassword}
              setValue={setConfirmPassword}
              id='password-confirm'
              label={`* ${getLocaleOption('confirmPassword')}`}
              error={errors.confirmPassword}
            />
            <AuthButton label={getLocaleOption('resetPassword')} loading={fetching} />
            <AuthMessage>{message}</AuthMessage>
          </FormContainer>
        </form>
        <AuthLink to='/login'>{getLocaleOption('login')}</AuthLink>
      </AuthContainer>
    </Auth>
  );
};

export default ResetPassword;
