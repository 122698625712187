import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react';

import { useAPI } from './api-context';
import { useAuth } from './auth-context';

import { Clinic as ClinicInterface, ClinicProperties } from '../shared/interfaces/';

interface Clinics {
  clinics: ClinicInterface[];
  getClinics: () => Promise<void>;
  assignedClinic: ClinicInterface | undefined;
  isInterfaceEnabled?: boolean;
}

export const ClinicsContext = createContext<Clinics>(null!);

const ClinicsContextProvider: React.FC = ({ children }) => {
  const { user } = useAuth();
  const { fetchAPI } = useAPI();

  const [clinics, setClinics] = useState<ClinicInterface[]>([]);

  const getClinics = useCallback(async () => {
    const json = await fetchAPI('/clinics/', {
      method: 'GET',
      withToken: true,
    });

    if (json.items) {
      setClinics(json.items);
    }
  }, [fetchAPI]);

  useEffect(() => {
    if (!user) return;
    // check if user is admin

    getClinics();
  }, [fetchAPI, user, getClinics]);

  const assignedClinic = useMemo(
    () => clinics.find((clinic) => clinic.id === user?.clinicId),
    [clinics, user?.clinicId]
  );

  const isInterfaceEnabled = useMemo(
    () => assignedClinic?.clinicProperty !== ClinicProperties.noInterface,
    [assignedClinic?.clinicProperty]
  );

  return (
    <ClinicsContext.Provider
      value={{
        clinics,
        getClinics,
        assignedClinic,
        isInterfaceEnabled,
      }}
    >
      {children}
    </ClinicsContext.Provider>
  );
};

export const useClinics = () => useContext(ClinicsContext);

export default ClinicsContextProvider;
