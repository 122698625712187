import { ProgressSpinner } from 'primereact/progressspinner';

interface FetchingContainerProps {
  fetching: boolean;
}

const FetchingContainer = ({
  children,
  fetching,
}: React.PropsWithChildren<FetchingContainerProps>) => {
  return (
    <>
      {fetching ? (
        <div className='flex justify-content-center'>
          <ProgressSpinner style={{ width: '70px', height: '70px' }} strokeWidth='4' />
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default FetchingContainer;
