import { useState, useEffect, useCallback, createRef, RefObject, useRef, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAPI } from 'context/api-context';
import { useLocale } from 'context/locale-context';
import { useSettings } from 'context/settings-context';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { ColumnBodyType } from 'primereact/column';

import UserStatusBadge from 'containers/UsersList/components/UserStatusBadge';
import Overlay from 'components/Overlay';
import { Input } from 'components/Inputs';

import { User, ErrorResponse, Couple, Person } from 'shared/interfaces';
import {
  AutoComplete,
  AutoCompleteChangeParams,
  AutoCompleteCompleteMethodParams,
  AutoCompleteSelectParams
} from 'primereact/autocomplete';

type UserInterface = ColumnBodyType & User;
type CoupleInterface = Couple & ErrorResponse;

interface PartnerProps {
  userId: number;
  coupleId: number | undefined;
  users: UserInterface[];
}

const Partner = ({ userId, coupleId, users }: PartnerProps) => {
  const navigate = useNavigate();
  const { fetchAPI } = useAPI();
  const { getLocaleOption } = useLocale();
  const { showToast } = useSettings();

  const [fetching, setFetching] = useState(false);
  const [currentCoupleId, setCurrentCoupleId] = useState(coupleId || null);
  const [currentPartner, setCurrentPartner] = useState<UserInterface[]>([]);
  const [email, setEmail] = useState('');
  const [
    removePartnerConfirmationDialogIsVisible,
    setRemovePartnerConfirmationDialogIsVisible,
  ] = useState(false);

  const somethingWentWrong = useCallback(
    () =>
      showToast({
        type: 'error',
        title: getLocaleOption('error'),
        text: getLocaleOption('somethingWentWrong'),
      }),
    [getLocaleOption, showToast]
  );

  useEffect(() => {
    async function getCouple() {
      setFetching(true);

      const couple: CoupleInterface = await fetchAPI(`/couples/${currentCoupleId}/`, {
        withToken: true,
      });

      if (couple.message) somethingWentWrong();

      if (couple.users) {
        const partner = [...couple.users.items].filter((e) => e.id !== userId);
        if (partner) setCurrentPartner(partner);
      }

      setFetching(false);
    }

    if (currentCoupleId) getCouple();
  }, [currentCoupleId, fetchAPI, somethingWentWrong, userId, users]);

  const [partner, setPartner] = useState<string>('');
  const [partnerSuggestions, setPartnerSuggestions] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState<Person & User | null>(null);
  const autocompleteRef: RefObject<any> = useRef();
  const searchForPartners = async (ac: AutoCompleteCompleteMethodParams) => {
    const queryParams: string = [
      'filter_email=' + ac.query,
      'sort=EMAIL',
      'page=0',
      'size=10'
    ].join('&');

    fetchAPI(`/users/?${queryParams}`, {
      method: 'GET',
      withToken: true,
    }).then(res => {
      setPartnerSuggestions(res.items);
    });
  }

  const tableHeaderTemplate = () => {
    return (
      <div className='table-header flex align-items-center justify-content-between'>
        <h5 className='mx-0 my-1'>{getLocaleOption('partner')}</h5>
        <Overlay
          className='p-button-success'
          style={{ width: '400px' }}
          label={getLocaleOption('addPartner')}
          disabled={!!currentPartner.length}
        >
          <div className='grid'>
            <div className='col-12'>
              <form onSubmit={inviteHandler}>
                <AutoComplete onSelect={(e: AutoCompleteSelectParams) => setSelectedPartner(e.value)} onChange={(e: AutoCompleteChangeParams) => setPartner(e.value)} ref={autocompleteRef} field='username' value={partner} completeMethod={searchForPartners} suggestions={partnerSuggestions}/>
                <Button className='block mt-3' type='submit' disabled={!selectedPartner?.id} label={getLocaleOption('add')} />
              </form>
            </div>
          </div>
        </Overlay>
      </div>
    )
  };

  const inviteHandler: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (!selectedPartner) {
      showToast({
        type: 'error',
        title: getLocaleOption('error'),
        text: getLocaleOption('userNotFound'),
      });
    } else {
      setFetching(true);

      const response: CoupleInterface = await fetchAPI('/couples/', {
        method: 'POST',
        withToken: true,
        body: JSON.stringify({ userIds: [userId, selectedPartner.id] }),
      });

      if (response.message) {
        somethingWentWrong();
      } else {
        setCurrentPartner([selectedPartner]);
        setCurrentCoupleId(response.id);
        showToast({
          type: 'success',
          title: getLocaleOption('success'),
          text: getLocaleOption('invited'),
        });
      }

      setFetching(false);
    }
  };

  const deleteCouple = async () => {
    setFetching(true);

    const response = await fetchAPI(`/couples/${currentCoupleId}/delete/`, {
      method: 'DELETE',
      withToken: true,
    });

    if (response.message) {
      somethingWentWrong();
    } else {
      setCurrentPartner([]);
      setCurrentCoupleId(null);
    }

    setFetching(false);
  };

  const partnerNameBodyTemplate = (partner: UserInterface) => {
    const name =
      !partner.person?.firstName && !partner.person?.lastName
        ? partner.username
        : partner.person?.firstName + ' ' + partner.person?.lastName;

    return (
      <Button
        label={name}
        className='p-button-text font-bold'
        onClick={() => navigate('/user', { state: { user: partner, users } })}
      />
    );
  };

  const partnerStatueBodyTemplate = (partner: UserInterface) => (
    <UserStatusBadge status={partner.userState} />
  );

  const actionBodyTemplate = () => (
    <Button
      className='p-button-danger'
      onClick={() => setRemovePartnerConfirmationDialogIsVisible(true)}
    >
      {getLocaleOption('removePartner')}
    </Button>
  );

  return (
    <>
      <DataTable
        value={currentPartner}
        responsiveLayout='scroll'
        showGridlines
        currentPageReportTemplate={getLocaleOption('tableNumberingTemplate')}
        loading={fetching}
        header={tableHeaderTemplate}
        emptyMessage={getLocaleOption('tableNoAvailableOptions')}
      >
        <Column header={getLocaleOption('partnerName')} body={partnerNameBodyTemplate} />
        <Column
          align='center'
          header={getLocaleOption('status')}
          body={partnerStatueBodyTemplate}
        />
        <Column
          align='center'
          header={getLocaleOption('action')}
          body={actionBodyTemplate}
        />
      </DataTable>
      <ConfirmDialog
        visible={removePartnerConfirmationDialogIsVisible}
        onHide={() => setRemovePartnerConfirmationDialogIsVisible(false)}
        header={getLocaleOption('confirmation')}
        icon='pi pi-exclamation-triangle'
        message={getLocaleOption('confirmRemovePartner')}
        rejectLabel={getLocaleOption('cancel')}
        acceptLabel={getLocaleOption('confirm')}
        accept={deleteCouple}
      />
    </>
  );
};

export default Partner;
