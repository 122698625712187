import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAPI } from 'context/api-context';
import { useLocale } from 'context/locale-context';
import { useAuth } from 'context/auth-context';
import { useClinics } from 'context/clinics-context';

import { FilterMatchMode } from 'primereact/api';
import { DataTable, DataTableFilterMeta, DataTableFilterMetaData, DataTablePFSEvent } from 'primereact/datatable';
import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';

import Layout from 'components/Layout/Layout';
import UserStatusBadge from './components/UserStatusBadge';

import { ClinicProperties, DeleteResponse, ErrorResponse, ItemsResponse, Person, Questionnaire, User, UserState } from 'shared/interfaces';
import makeDate from 'shared/helpers/makeDate';
import UsersListTableHeader from './components/UsersListTableHeader';
import UsersListAssignBody from './components/UsersListAssignBody';
import { HandleUserOption } from './models/enums/HandleUserOption';
import UsersListConfirmDialog from './components/UsersListConfirmDialog';
import UserListInterfaceBody from './components/UserListInterfaceBody';
import UserListMessageBody from './components/UserListMessageBody';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { UserAttributeMapper } from '../../shared/helpers/UserAttributeMapper';
import UserRemarksBody from './components/UserRemarksBody';

type Response = ErrorResponse & ItemsResponse & DeleteResponse;
type QuestionnairesResponse = ErrorResponse & ItemsResponse & Questionnaire;
export type TableUserFilters = {[key in keyof TableUser]: DataTableFilterMetaData} & {global: DataTableFilterMetaData};
interface UsersProps {
  archived?: boolean;
}

export type TableUser = User & Person;

type SortOrderType = -1 | 1 | undefined;
interface MultiselectOptions<T = any> {
  label: string;
  value: T;
}

export interface ILazyLoadingParams {
  page: number;
  first: number;
  rows: number;
  totalElements?: number;
  showOnlyArchived: boolean,
  totalPages?: number;
  sortField?: string;
  sortOrder?: SortOrderType;
  filters: DataTableFilterMeta;
}

const handleUserConfirmDialogText = {
  archive: {
    message: 'confirmArchiveUser',
    acceptLabel: 'archiveUser'
  },
  reactivate: {
    message: 'confirmReactivateUser',
    acceptLabel: 'reactivateUser'
  },
  delete: {
    message: 'confirmDeleteUser',
    acceptLabel: 'deleteUser'
  }
};

const dataTableOperatorFilterMetaData = {
  value: '',
  matchMode: FilterMatchMode.CONTAINS
};

const Users = ({ archived }: UsersProps) => {
  const navigate = useNavigate();
  const { fetchAPI } = useAPI();
  const { getLocaleOption, locale } = useLocale();
  const { checkUserPrivileges, login: refreshUnreadMessages } = useAuth();
  const { clinics, assignedClinic } = useClinics();

  const isClinic: boolean = checkUserPrivileges('IS_CLINIC');
  const isFertilly: boolean = checkUserPrivileges('IS_FERTILLY');
  const accessAssign: boolean = checkUserPrivileges('ASSIGN_SAME_CLINIC_QUESTIONNAIRE');
  const accessArchive: boolean = checkUserPrivileges('ARCHIVE_USER');
  const accessReactivate: boolean = checkUserPrivileges('REACTIVATE_USER');
  const accessDelete: boolean = checkUserPrivileges('DELETE_USER');
  const accessClinics: boolean = checkUserPrivileges('VIS_MENU__ADMIN__CLINIC');
  const loadArchived: boolean = !!archived;

  const [users, setUsers] = useState<TableUser[]>([]);
  const [questionnaires, setQuestionnaires] = useState<Questionnaire[]>([]);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);
  const tableSettings: object = JSON.parse(sessionStorage.getItem('user-table-state-local') || '{}') as ILazyLoadingParams;

  const [lazyLoadingParams, setLazyParams] = useState<ILazyLoadingParams>({
    showOnlyArchived: loadArchived,
    page: 0,
    rows: 10,
    first: 0,
    filters: {
      global: {value: null, matchMode: FilterMatchMode.CONTAINS},
      firstName: dataTableOperatorFilterMetaData,
      lastName: dataTableOperatorFilterMetaData,
      username: dataTableOperatorFilterMetaData,
      birthday: dataTableOperatorFilterMetaData,
      partnerPatientKey: dataTableOperatorFilterMetaData,
      userState: dataTableOperatorFilterMetaData,
      surveyAttributeTreatmentPlace: dataTableOperatorFilterMetaData,
      totalUnreadMessages: {value: [], matchMode: FilterMatchMode.EQUALS}
    } as TableUserFilters,
    ...tableSettings
  });

  const getSortingFieldHTTPName = (field: keyof TableUser): string => {
    return ({
      'id': 'ID,',
      'firstName': 'FIRST_NAME',
      'lastName': 'LAST_NAME',
      'username': 'EMAIL',
      'birthday': 'BIRTH_DATE',
      'createdOn': 'INVITE_DATE',
      'lastActionOn': 'LAST_ACTION_DATE',
      'totalUnreadMessages': 'MESSAGE_UNREAD',
      'partnerPatientKey': 'PATIENT_ID',
      'userState': 'STATUS',
    } as any)[field];
  };

  useEffect(() => {
    const getUsers = async () => {
      setUsers([]);
      setFetching(true);
      const filterText: string = (lazyLoadingParams.filters['global'] as any).value || '';
      const sortField: string | undefined= getSortingFieldHTTPName(lazyLoadingParams.sortField as keyof TableUser);
      const sortOrder: number | undefined | null = lazyLoadingParams.sortOrder;
      const sortOrderOption: 'ASC' | 'DESC' = sortOrder === 1 ? 'ASC' : 'DESC';
      const filters: TableUserFilters = lazyLoadingParams.filters as TableUserFilters;
      const page: number = lazyLoadingParams.page;
      const first: number = lazyLoadingParams.first;
      const rows: number = lazyLoadingParams.rows;
      const startingPage: number = page === 0 && first > 0 ? first / rows : page;

      // field filter values
      const firstName: string = filters.firstName.value;
      const lastName: string = filters.lastName.value;
      const email: string = filters.username.value;
      const birthday: string = filters.birthday.value;
      const partnerPatientKey: string = filters.partnerPatientKey.value;
      const userState: string = filters.userState.value;
      const totalUnreadMessages: string = filters.totalUnreadMessages.value;
      const surveyAttributeTreatmentPlace: string = filters.surveyAttributeTreatmentPlace?.value;

      const queryParams: string = [
        'filter_text=' + filterText,
        'page=' + startingPage,
        'size=' + lazyLoadingParams.rows,
        'filter_archived=' + lazyLoadingParams.showOnlyArchived,
        !!sortField ? `sort=${sortField}:${sortOrderOption}` : undefined,
        // field filter params
        firstName ? `filter_first_name=${firstName}` : undefined,
        lastName ? `filter_last_name=${lastName}` : undefined,
        email ? `filter_email=${email}` : undefined,
        birthday ? `filter_birth_date=${birthday}` : undefined,
        partnerPatientKey ? `filter_patient_id=${partnerPatientKey}` : undefined,
        userState ? `filter_states=${userState}` : undefined,
        totalUnreadMessages ? `filter_messages_read_states=${totalUnreadMessages}` : undefined,
        surveyAttributeTreatmentPlace ? `filter_survey_attribute_treatment_place=${surveyAttributeTreatmentPlace}` : undefined,
      ]
        .filter(Boolean)
        .join('&');

      const usersResponse: Response = await fetchAPI(`/users/?${queryParams}`, {
        withToken: true
      });

      if (usersResponse.error) {
        setError(true);
        return setErrorMessage(usersResponse.message);
      }

      let items: TableUser[] = usersResponse.items || [];

      if (!accessClinics) {
        items = [...items].filter(
          (item: TableUser) =>
            item.roles[0]?.name !== 'CLINIC' && item.roles[0]?.name !== 'MEDITEX_CLINIC'
        );
      }

      setUsers(items);
      setTotalRecords(usersResponse.totalElements);
      setFetching(false);
    };

    if (loadArchived !== lazyLoadingParams.showOnlyArchived) {
      setLazyParams({...lazyLoadingParams, ...{showOnlyArchived: loadArchived}});
    } else {
      getUsers();
    }
  }, [lazyLoadingParams, loadArchived, accessClinics, fetchAPI]);

  useEffect(() => {
    const getQuestionnaires = async () => {
      const response: QuestionnairesResponse = await fetchAPI('/surveys/', {
        withToken: true
      });

      const questionnaireItems: Questionnaire[] = response.items;

      if (response.error) {
        setError(true);
        return setErrorMessage(response.message);
      }

      setQuestionnaires(
        questionnaireItems
          .filter((item) => item.entityState !== 'DELETED')
          .sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          })
      );
    };

    getQuestionnaires();
  }, [fetchAPI]);

  const onGlobalFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    let filters: any = {...lazyLoadingParams.filters};

    (filters['global'] as any).value = value;

    setLazyParams({ ...lazyLoadingParams, page: 0, filters });
  };

  const setFilters = (params: DataTablePFSEvent) => {
    setLazyParams({...lazyLoadingParams, filters: params.filters});
  }

  const setSorting = (params: DataTablePFSEvent) => {
    setLazyParams({
      ...lazyLoadingParams,
      sortField: params.sortField,
      sortOrder: params.sortOrder as SortOrderType
    });
  }

  const selectUserHandler = async (userId: number) => {
    const user: TableUser = await fetchAPI(`/users/${userId}/`, { withToken: true });

    navigate('/user', { state: { user, users } });
  };

  const userBodyTemplate = (data: User | Person, navigationUserId: number) => {
    return (
      <Button
        label={(data as User)?.username || ((data as Person)?.firstName || '').concat(' ', (data as Person)?.lastName || '')}
        className='p-button-text'
        onClick={() => selectUserHandler(navigationUserId)}
      />
    );
  };

  const statusBodyTemplate = (data: TableUser) => <UserStatusBadge status={data.userState} />;

  const dateBodyTemplate = (data: TableUser) =>
    data.person?.birthday && makeDate(new Date(data.person.birthday), locale);

  const userCreatedOnDateBodyTemplate = (data: TableUser) =>
    data.createdOn && makeDate(new Date(data.createdOn), locale);

  const latestActionBodyTemplate = (data: TableUser) =>
    data?.lastActionOn && makeDate(new Date(data.lastActionOn), locale);

  const archiveReactivateDeleteHandler = async (type: HandleUserOption, id: number) => {
    const response: TableUser & Response = await fetchAPI(`/users/${id}/${type}/`, {
      method: type === HandleUserOption.delete ? 'DELETE' : 'POST',
      withToken: true
    });

    if (response.message) {
      setError(true);
      setErrorMessage(response.message);
      return;
    }

    if (response.deleted) {
      const filter: TableUser[] = users.filter((user: TableUser) => user.id !== id);
      setUsers(filter);
      return;
    }

    const check =
      type === HandleUserOption.archive ? response.archived : !response.archived;

    if (check) {
      const filteredUsers: TableUser[] = users.filter((user) => user.id !== response.id);
      setUsers(filteredUsers);
    }

    refreshUnreadMessages();
  };

  const handleUserConfirmDialog = (
    type: HandleUserOption,
    accept: (arg1: HandleUserOption, arg2: number) => Promise<void>,
    id: number
  ) => {
    confirmDialog({
      header: getLocaleOption('confirmation'),
      message: getLocaleOption(handleUserConfirmDialogText[type].message),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: getLocaleOption(handleUserConfirmDialogText[type].acceptLabel),
      rejectLabel: getLocaleOption('cancel'),
      accept: () => accept(type, id),
      reject: () => false
    });
  };

  const assignedClinicTemplate = (user: TableUser) => (
    <p>{clinics.find((c) => c.id === user.clinicId)?.name || ''}</p>
  );

  const onPage = (event: any) => {
    setLazyParams({ ...lazyLoadingParams, ...event, ...{showOnlyArchived: loadArchived}});
  };

  const textFilterElement = (options: ColumnFilterElementTemplateOptions) => {
    const value: string = options?.value || '';
    return (<InputText value={value} onChange={e=> options.filterApplyCallback(e.target.value)} />)
  };

  const messagesFilterOptions: MultiselectOptions<string>[] = [
    { value: 'all', label: getLocaleOption('all')},
    { value: 'read', label: getLocaleOption('read')},
    { value: 'unread', label: getLocaleOption('unread')},
  ];

  const statusFilterOptions: MultiselectOptions<string>[] = [
    { value: UserState.INVITED, label: getLocaleOption(UserAttributeMapper.getUserStateTranslationKey(UserState.INVITED))},
    { value: UserState.REGISTERED, label: getLocaleOption(UserAttributeMapper.getUserStateTranslationKey(UserState.REGISTERED))},
    { value: UserState.ANAMNESIS_INCOMPLETE, label: getLocaleOption(UserAttributeMapper.getUserStateTranslationKey(UserState.ANAMNESIS_INCOMPLETE))},
    { value: UserState.ANAMNESIS_COMPLETE, label: getLocaleOption(UserAttributeMapper.getUserStateTranslationKey(UserState.ANAMNESIS_COMPLETE))},
    { value: UserState.ANAMNESIS_SUBMITTED, label: getLocaleOption(UserAttributeMapper.getUserStateTranslationKey(UserState.ANAMNESIS_SUBMITTED))},
    { value: UserState.ANAMNESIS_TRANSFERRED, label: getLocaleOption(UserAttributeMapper.getUserStateTranslationKey(UserState.ANAMNESIS_TRANSFERRED))},
    { value: UserState.ANAMNESIS_PARTIALLY_TRANSFERRED, label: getLocaleOption(UserAttributeMapper.getUserStateTranslationKey(UserState.ANAMNESIS_PARTIALLY_TRANSFERRED))}
  ];

  const multiselectFilterElement = (templateOptions: ColumnFilterElementTemplateOptions, multiselectOptions: MultiselectOptions[]) => {
    const value: string = templateOptions.value || '';
    return (<MultiSelect value={value} options={multiselectOptions} onChange={(e) => templateOptions.filterApplyCallback(e.target.value)} />)
  };

  return (
    <Layout>
      {error ? (
        <h5 className='mb-0'>{errorMessage}</h5>
      ) : (
        <DataTable
          loading={fetching}
          value={users}
          responsiveLayout='scroll'
          showGridlines
          lazy
          header={UsersListTableHeader(lazyLoadingParams, !!archived, totalRecords, onGlobalFilterChange)}
          onFilter={(e: DataTablePFSEvent) => setFilters(e)}
          onSort={(e: DataTablePFSEvent) => setSorting(e)}
          stateStorage='session'
          stateKey='user-table-state-local'
          paginator
          paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
          first={lazyLoadingParams.first}
          currentPageReportTemplate={getLocaleOption('tableNumberingTemplate')}
          rows={lazyLoadingParams.rows}
          rowsPerPageOptions={[10, 20, 50]}
          totalRecords={totalRecords}
          sortOrder={lazyLoadingParams.sortOrder}
          sortField={lazyLoadingParams.sortField}
          filterDelay={500}
          filters={lazyLoadingParams.filters}
          onPage={onPage}
          emptyMessage={getLocaleOption('tableNoAvailableOptions')}
        >
          <Column
            header={getLocaleOption('lastName')}
            body={(data: TableUser) => data.person?.lastName}
            filter
            field='lastName'
            showFilterMenuOptions={false}
            showApplyButton={false}
            showClearButton={false}
            filterElement={textFilterElement}
            sortable
            sortField='lastName'
          />
          <Column
            header={getLocaleOption('firstName')}
            body={(data: TableUser) => data.person?.firstName}
            field="firstName"
            filter
            showFilterMenuOptions={false}
            showApplyButton={false}
            showClearButton={false}
            filterElement={textFilterElement}
            sortable
            sortField='firstName'
          />
          <Column
            field='username'
            header={getLocaleOption('username')}
            body={(user: TableUser) => userBodyTemplate(user, user.id)}
            sortable
            sortField='username'
            filter
            showFilterMenuOptions={false}
            showApplyButton={false}
            showClearButton={false}
            filterElement={textFilterElement}
          />
          <Column
            header={getLocaleOption('birthday')}
            body={dateBodyTemplate}
            field='birthday'
            filter
            showFilterMenuOptions={false}
            showApplyButton={false}
            showClearButton={false}
            filterElement={textFilterElement}
            sortable
            sortField='birthday'
          />
          {accessClinics && (
            <Column
              header={getLocaleOption('assignedUserRole')}
              body={(data: TableUser) => data.roles[0]?.name}
            />
          )}
          {accessClinics && (
            <Column
              header={getLocaleOption('assignedClinics')}
              body={assignedClinicTemplate}
            />
          )}
          <Column
            header={getLocaleOption('invitationDate')}
            body={userCreatedOnDateBodyTemplate}
            sortable
            sortField='createdOn'
          />
          <Column
            header={getLocaleOption('latestAction')}
            body={latestActionBodyTemplate}
            sortable
            sortField='lastActionOn'
          />
          {isClinic && assignedClinic?.chatEnabled && (
            <Column
              align='center'
              header={getLocaleOption('messages')}
              body={UserListMessageBody((user: TableUser) => selectUserHandler(user.id))}
              filter
              field='totalUnreadMessages'
              showFilterMenuOptions={false}
              showApplyButton={false}
              showClearButton={false}
              filterElement={(options: ColumnFilterElementTemplateOptions) => multiselectFilterElement(options, messagesFilterOptions)}
              sortable
              sortField='totalUnreadMessages'
            />
          )}
          {isClinic && assignedClinic?.surveyTreatmentPlaceEnabled && (
            <Column
              header={getLocaleOption('surveyTreatmentPlace')}
              body={(data: TableUser) => data.person?.surveyAttributeTreatmentPlace}
              field="surveyAttributeTreatmentPlace"
              filter
              showFilterMenuOptions={false}
              showApplyButton={false}
              showClearButton={false}
              filterElement={textFilterElement}
            />
          )}

          {(isClinic || isFertilly) && (
            <Column
              align='center'
              header={getLocaleOption('status')}
              body={statusBodyTemplate}
              filter
              field='userState'
              showFilterMenuOptions={false}
              showApplyButton={false}
              showClearButton={false}
              filterElement={(options: ColumnFilterElementTemplateOptions) => multiselectFilterElement(options, statusFilterOptions)}
              sortable
              sortField='userState'
            />
          )}
          <Column
            field='partnerPerson'
            header={getLocaleOption('partnerPerson')}
            body={(user: TableUser) => userBodyTemplate(user.partnerPerson as Person, user.partnerUserId)}
            showFilterMenuOptions={false}
            showApplyButton={false}
            showClearButton={false}
          />
          {isClinic && (assignedClinic?.clinicProperty === ClinicProperties.meditex || assignedClinic?.clinicProperty === ClinicProperties.gdt) && (
            <Column
              className='interfaceColumn'
              header={getLocaleOption('remarks')}
              body={(user: TableUser) => (<UserRemarksBody setUsers={setUsers} users={users} user={user} label={user.remarks ? user.remarks : getLocaleOption('assign')} />)}
              field='remarks'
              filter
              showFilterMenuOptions={false}
              showApplyButton={false}
              showClearButton={false}
              filterElement={textFilterElement}
              sortable
              sortField='remarks'
            />
          )}
          {isClinic && (assignedClinic?.clinicProperty === ClinicProperties.meditex || assignedClinic?.clinicProperty === ClinicProperties.gdt) && (
            <Column
              className='interfaceColumn'
              header={'Patient ID'}
              body={(user: TableUser) => (<UserListInterfaceBody setUsers={setUsers} users={users} user={user} label={user.partnerPatientKey ? user.partnerPatientKey : getLocaleOption('assign')} />)}
              field='partnerPatientKey'
              filter
              showFilterMenuOptions={false}
              showApplyButton={false}
              showClearButton={false}
              filterElement={textFilterElement}
              sortable
              sortField='partnerPatientKey'
            />
          )}
          {!archived && accessAssign && (
            <Column
              header={getLocaleOption('assignQuestionnaire')}
              body={(user: TableUser) => (<UsersListAssignBody user={user} label={getLocaleOption('assign')} errorMessage={errorMessage} questionnaires={questionnaires} />)}
            />
          )}
          {!archived && accessArchive && (
            <Column
              header={getLocaleOption('archiveUser')}
              body={(user: TableUser) => (<UsersListConfirmDialog label={getLocaleOption(HandleUserOption.archive)} option={HandleUserOption.archive} onUserHandleClick={() => handleUserConfirmDialog(HandleUserOption.archive, archiveReactivateDeleteHandler, user.id)} />)}
            />
          )}
          {archived && accessReactivate && (
            <Column
              header={getLocaleOption('reactivateUser')}
              body={(user: TableUser) => (<UsersListConfirmDialog label={getLocaleOption(HandleUserOption.reactivate)} option={HandleUserOption.reactivate} onUserHandleClick={() => handleUserConfirmDialog(HandleUserOption.reactivate, archiveReactivateDeleteHandler, user.id)} />)}
            />
          )}
          {archived && accessDelete && (
            <Column
              header={getLocaleOption('deleteUser')}
              body={(user: TableUser) => (<UsersListConfirmDialog label={getLocaleOption(HandleUserOption.delete)} option={HandleUserOption.delete} onUserHandleClick={() => handleUserConfirmDialog(HandleUserOption.delete, archiveReactivateDeleteHandler, user.id)} />)}
            />
          )}
        </DataTable>
      )}
    </Layout>
  );
};

export default Users;
