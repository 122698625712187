import React, { useState, useCallback } from 'react';
import { useClinics } from 'context/clinics-context';
import { useLocale } from 'context/locale-context';
import { useSettings } from 'context/settings-context';
import { useAPI } from 'context/api-context';
import { InputSwitch } from 'primereact/inputswitch';
import { Clinic as ClinicInterface } from 'shared/interfaces';

interface ToggleInfoPageProps {
  clinicId: number;
  enabled: boolean;
  clinics: ClinicInterface[];
  setClinics: React.Dispatch<React.SetStateAction<ClinicInterface[]>>;
  path: string;
  clinicProp: 'showInfoPage' | 'chatEnabled' | 'surveyTreatmentPlaceEnabled';
}

const ToggleSwitchWithFetching = ({
  clinicId,
  enabled,
  clinics,
  setClinics,
  path,
  clinicProp,
}: ToggleInfoPageProps) => {
  const { getClinics } = useClinics();
  const { getLocaleOption } = useLocale();
  const { fetchAPI } = useAPI();
  const { showToast } = useSettings();

  const [fetching, setFetching] = useState(false);

  const toggleInfoPage = useCallback(async () => {
    setFetching(true);

    const updatedClinic: ClinicInterface = await fetchAPI(
      `/clinics/${clinicId}/${path}/${enabled ? 'disable' : 'enable'}/`,
      {
        method: 'PUT',
        withToken: true,
      }
    );

    if (updatedClinic[clinicProp] === !enabled) {
      const map = clinics.map((c) => (c.id === clinicId ? updatedClinic : c));
      setClinics(map);
      getClinics();
    } else {
      showToast({
        type: 'error',
        title: getLocaleOption('error'),
        text: getLocaleOption('somethingWentWrong'),
      });
    }

    setFetching(false);
  }, [
    clinicId,
    clinics,
    enabled,
    fetchAPI,
    getLocaleOption,
    setClinics,
    showToast,
    getClinics,
    clinicProp,
    path,
  ]);

  return <InputSwitch checked={enabled} onChange={toggleInfoPage} disabled={fetching} />;
};

export default ToggleSwitchWithFetching;
