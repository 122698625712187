import { Button } from 'primereact/button';
import { TableUser } from '../UsersList';

const UserListMessageBody = (onClick: (user: TableUser) => void) => {

  return (user: TableUser) => {
    const { totalUnreadMessages } = user;

    return (
      <Button
        label={
          totalUnreadMessages && totalUnreadMessages > 0
            ? totalUnreadMessages.toString()
            : '0'
        }
        className={`${
          totalUnreadMessages ? 'p-button-danger' : 'p-button-text'
        } text-xl font-bold`}
        onClick={() => onClick(user)}
      />
    );
  };
};

export default UserListMessageBody;
