import React, { useState, useEffect } from 'react';

import { useLocale } from 'context/locale-context';

import { Input } from 'components/Inputs';

import { obscureEmail } from 'shared/helpers/obscureEmail';

interface TFAAuthProps {
  email: string;
  tfaLogin: (code: string) => Promise<void>;
  tfaError: string | null;
  setTfaError: React.Dispatch<React.SetStateAction<string | null>>;
}

const TFAAuth = ({ email, tfaLogin, tfaError, setTfaError }: TFAAuthProps) => {
  const { getLocaleOption } = useLocale();

  const [value, setValue] = useState('');

  useEffect(() => {
    setTfaError(null);
    if (value.trim().length === 8) tfaLogin(value.trim());
  }, [value]); // eslint-disable-line

  return (
    <>
      <h5>{getLocaleOption('2faAuthenticateYourAccount')}</h5>
      <div className='text-center bg-white mx-auto border-1 border-300 flex flex-column align-items-center py-6'>
        <i className='pi pi-unlock mb-6 text-gray-700' style={{ fontSize: '4rem' }} />
        <p className='w-8 text-lg'>{getLocaleOption('2faActivated')}</p>
        <p className='font-bold mb-5 text-xl'>{obscureEmail(email)}</p>
        <div className='w-6 mb-6'>
          <Input id='2fa-input' value={value} setValue={setValue} />
          {tfaError && <p className='p-error'>{tfaError}</p>}
          <p className='text-sm'>{getLocaleOption('2faCanDeactivate')}</p>
        </div>
      </div>
    </>
  );
};

export default TFAAuth;
