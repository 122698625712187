import { Clinic, CheckedElement } from 'shared/interfaces';

export function mapClinicsToCheckedElements(clinics: Clinic[], clinicId: number | null) {
  return clinics.map((clinic) => {
    return {
      title: clinic.name,
      checked: clinicId === clinic.id,
      name: clinic.id // id is "hacked here" it will be stored in name
    };
  });
}

export function findCheckedElement(checkedElements: CheckedElement[]) {
  return checkedElements.find((option) => option.checked)?.name; // its id
}
