import { useCallback, useEffect, useRef, useState } from 'react';
import { classNames } from 'primereact/utils';

import { useAuth } from 'context/auth-context';
import { useLocale } from 'context/locale-context';
import { useClinics } from 'context/clinics-context';
import { useLogo } from 'context/logo-context';

import AppTopbar from './AppTobar';
import AppBreadcrumb from './AppBreadcrumb';
import FloatingNav from './FloatingNav';
import { useLocation } from 'react-router-dom';

export interface MenuItem {
  label?: string;
  icon?: string;
  items?: MenuItem[];
  separator?: undefined | true;
  style?: {};
  disabled?: boolean;
  to?: string;
  className?: string;
  badge?: string;
  badgeStyle?: {};
  target?: string;
  url?: string;
  command?: () => void;
}

const Layout: React.FC = ({ children }) => {
  const { userCompleted, checkUserPrivileges, user } = useAuth();
  const { getLocaleOption } = useLocale();
  const { assignedClinic } = useClinics();
  const { logo } = useLogo();

  const [menuActive, setMenuActive] = useState(false);
  const [colorScheme] = useState('light');
  const [menuTheme] = useState('layout-sidebar-darkgray');
  const [menuMode] = useState('static');
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [inputStyle] = useState('outlined');
  const [ripple] = useState(false);
  const [topbarNotificationMenuActive, setTopbarNotificationMenuActive] = useState(false);
  const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);

  const changeStyleSheetUrl = (id: string, value: string, from: number) => {
    const element = document.getElementById(id)!;
    const urlTokens = element.getAttribute('href')!.split('/');

    if (from === 1) {
      // which function invoked this function
      urlTokens[urlTokens.length - 1] = value;
    } else if (from === 2) {
      // which function invoked this function
      if (value !== null) {
        urlTokens[urlTokens.length - 2] = value;
      }
    } else if (from === 3) {
      // which function invoked this function
      urlTokens[urlTokens.length - 2] = value;
    }

    const newURL = urlTokens.join('/');

    replaceLink(element, newURL);
  };

  const replaceLink = (linkElement: HTMLElement, href: string) => {
    if (isIE()) {
      linkElement.setAttribute('href', href);
    } else {
      const id = linkElement.getAttribute('id')!;
      const cloneLinkElement = linkElement.cloneNode(true) as HTMLElement;

      cloneLinkElement.setAttribute('href', href);
      cloneLinkElement.setAttribute('id', id + '-clone');

      linkElement.parentNode!.insertBefore(cloneLinkElement, linkElement.nextSibling);

      cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        cloneLinkElement.setAttribute('id', id);
      });
    }
  };

  const isIE = () => {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  };

  useEffect(() => {
    changeStyleSheetUrl('layout-css', 'layout-' + colorScheme + '.css', 1);
    changeStyleSheetUrl('theme-css', 'theme-' + colorScheme + '.css', 1);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onDocumentClick = () => {
    if (!userMenuClick) {
      setTopbarUserMenuActive(false);
    }

    if (!menuClick) {
      if (isSlim() || isHorizontal()) {
        setMenuActive(false);
      }

      if (overlayMenuActive || staticMenuMobileActive) {
        hideOverlayMenu();
      }

      unblockBodyScroll();
    }

    userMenuClick = false;
    menuClick = false;
  };

  let userMenuClick = false;

  const onTopbarUserMenuButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    userMenuClick = true;
    setTopbarUserMenuActive((prevTopbarUserMenuActive) => !prevTopbarUserMenuActive);

    hideOverlayMenu();

    event.preventDefault();
  };

  const hideOverlayMenu = () => {
    setOverlayMenuActive(false);
    setStaticMenuMobileActive(false);
    unblockBodyScroll();
  };

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(
        new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'),
        ' '
      );
    }
  };

  const menuItems = useCallback(() => {
    const menu = [
      checkUserPrivileges('VIS_MENU__DASHBOARD') && {
        label: getLocaleOption('dashboard'),
        icon: 'pi pi-fw pi-home',
        to: '/dashboard',
      },
      checkUserPrivileges('VIS_MENU__MY_PROFILE') && {
        label: getLocaleOption('myProfile'),
        icon: 'pi pi-fw pi-id-card',
        to: '/user',
      },
      {
        label: getLocaleOption('accountSettings'),
        icon: 'pi pi-fw pi pi-cog',
        to: '/account-settings',
      },
      checkUserPrivileges('VIS_MENU__MY_QUESTIONNAIRES') &&
        !checkUserPrivileges('VIS_MENU__ADMINISTRATION') && {
          label: getLocaleOption('medicalQuestionnaire'),
          icon: 'pi pi-fw pi-book',
          to: '/questionnaires/patient',
        },
      checkUserPrivileges('VIS_MENU__ADMIN_DIAGNOSTIC__FINDINGS') && {
        label: getLocaleOption('myDiagnosticFindings'),
        icon: 'pi pi-fw pi-book',
        to: '/my-diagnostic-findings',
      },
      checkUserPrivileges('VIS_MENU__ADMIN__QUESTIONNAIRE') && {
        label: getLocaleOption('questionnaires'),
        icon: 'pi pi-fw pi-file',
        items: [
          {
            label: getLocaleOption('questionnairesMedical'),
            icon: 'pi pi-fw pi-file',
            to: '/questionnaires/medical',
          },
          {
            label: getLocaleOption('questionnairesMedicalPdf'),
            icon: 'pi pi-fw pi-file',
            to: '/questionnaires/medical-pdf',
          },
          checkUserPrivileges('VIS_MENU__ADMINISTRATION') && {
            label: getLocaleOption('questionnairesBaseData'),
            icon: 'pi pi-fw pi-file',
            to: '/questionnaires/base-data',
          },
        ],
      },
      !!assignedClinic?.chatEnabled &&
        !checkUserPrivileges('IS_CLINIC') && {
          label: getLocaleOption('messages'),
          icon: 'pi pi-fw pi-envelope',
          ...(user?.totalUnreadMessages &&
            user?.totalUnreadMessages > 0 && {
              badge: user?.totalUnreadMessages,
            }),
          to: '/user-messages',
        },
      checkUserPrivileges('VIS_MENU__ADMIN__QUESTIONNAIRE_SPEC') && {
        label: getLocaleOption('questionnaireBuilder'),
        icon: 'pi pi-fw pi-copy',
        to: '/questionnaire-builder',
      },
      checkUserPrivileges('VIS_MENU__ADMIN__USERS') && {
        label: getLocaleOption('users'),
        icon: 'pi pi-fw pi-user',
        ...(user?.totalUnreadMessagesActive &&
          user?.totalUnreadMessagesActive > 0 && {
            badge: user?.totalUnreadMessagesActive,
          }),
        to: '/users',
      },
      checkUserPrivileges('VIS_MENU__ADMIN__USERS') && {
        label: getLocaleOption('archivedUsers'),
        icon: 'pi pi-fw pi-user-minus',
        ...(user?.totalUnreadMessagesArchived &&
          user?.totalUnreadMessagesArchived > 0 && {
            badge: user?.totalUnreadMessagesArchived,
          }),
        to: '/archived-users',
      },
      checkUserPrivileges('VIS_MENU__ADMIN__CLINIC') && {
        label: getLocaleOption('clinics'),
        icon: 'pi pi-fw pi-building',
        items: [
          {
            label: getLocaleOption('list'),
            icon: 'pi pi-fw pi-building',
            to: '/clinics',
          },
          {
            label: getLocaleOption('createClinic'),
            icon: 'pi pi-fw pi-building',
            to: '/create-clinic',
          },
        ],
      },
      checkUserPrivileges('IS_FERTILLY_ADMIN') && {
        label: getLocaleOption('mails'),
        icon: 'pi pi-fw pi-envelope',
        to: '/mails',
      },
      (checkUserPrivileges('IS_CLIENT') || checkUserPrivileges('IS_FERTILLY')) &&
        assignedClinic?.showInfoPage && {
          label: getLocaleOption('menu-info'),
          icon: 'pi pi-fw pi-info-circle',
          to: '/info',
        },
    ];

    return menu.filter((menuItem) => menuItem !== false);
  }, [
    assignedClinic?.chatEnabled,
    assignedClinic?.showInfoPage,
    checkUserPrivileges,
    getLocaleOption,
    user?.totalUnreadMessages,
    user?.totalUnreadMessagesActive,
    user?.totalUnreadMessagesArchived,
  ]);

  const menu: MenuItem[] = [
    {
      // @ts-ignore:
      items: menuItems(),
    },
    // { separator: true },
  ];

  const containerClassName = classNames(
    'layout-wrapper',
    {
      'layout-overlay': menuMode === 'overlay',
      'layout-static': menuMode === 'static',
      'layout-slim': menuMode === 'slim',
      'layout-horizontal': menuMode === 'horizontal',
      'layout-sidebar-dim': colorScheme === 'dim',
      'layout-sidebar-dark': colorScheme === 'dark',
      'layout-overlay-active': overlayMenuActive,
      'layout-mobile-active': staticMenuMobileActive,
      'layout-static-inactive': staticMenuDesktopInactive && menuMode === 'static',
      'p-input-filled': inputStyle === 'filled',
      'p-ripple-disabled': !ripple,
    },
    colorScheme === 'light' ? menuTheme : ''
  );

  const layoutContentRef = useRef<any>(null);

  let menuClick = false;

  const onMenuClick = () => {
    menuClick = true;
  };

  const isOverlay = () => {
    return menuMode === 'overlay';
  };

  const isHorizontal = () => {
    return menuMode === 'horizontal';
  };

  const isDesktop = () => {
    return window.innerWidth > 1091;
  };

  const isSlim = () => {
    return menuMode === 'slim';
  };

  const onMenuButtonClick = (event: any) => {
    menuClick = true;
    setTopbarUserMenuActive(false);
    setTopbarNotificationMenuActive(false);
    // setRightMenuActive(false);

    if (isOverlay()) {
      setOverlayMenuActive((prevOverlayMenuActive) => !prevOverlayMenuActive);
    }

    if (isDesktop()) {
      setStaticMenuDesktopInactive(
        (prevStaticMenuDesktopInactive) => !prevStaticMenuDesktopInactive
      );
    } else {
      setStaticMenuMobileActive(
        (prevStaticMenuMobileActive) => !prevStaticMenuMobileActive
      );
    }

    event.preventDefault();
  };

  const onRootMenuitemClick = () => {
    setMenuActive((prevMenuActive) => !prevMenuActive);
  };

  const onMenuitemClick = (event: any) => {
    if (!event.item.items) {
      hideOverlayMenu();

      if (isSlim() || isHorizontal()) {
        setMenuActive(false);
      }
    }
  };

  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
  }, [location, displayLocation]);

  const handleAnimationEnd = () => {
    if (transitionStage === "fadeOut") {
      setTransistionStage("fadeIn");
      setDisplayLocation(location);
    }
  }

  return (
    <div
      className={containerClassName}
      data-theme={colorScheme}
      onClick={onDocumentClick}
    >
      <div className='layout-content-wrapper'>
        <AppTopbar
          logo={logo}
          menu={userCompleted ? menu : []}
          topbarNotificationMenuActive={topbarNotificationMenuActive}
          topbarUserMenuActive={topbarUserMenuActive}
          onMenuButtonClick={onMenuButtonClick}
          onSearchClick={() => false}
          onTopbarNotification={() => false}
          onTopbarUserMenu={onTopbarUserMenuButtonClick}
          menuMode={menuMode}
          menuActive={menuActive}
          onMenuClick={onMenuClick}
          onMenuitemClick={onMenuitemClick}
          onRootMenuitemClick={onRootMenuitemClick}
          staticMenuMobileActive={staticMenuMobileActive}
        />
        <div className={`layout-content relative ${transitionStage}`} ref={layoutContentRef} onAnimationEnd={handleAnimationEnd}>
          <div
            className='layout-breadcrumb viewname'
            style={{ textTransform: 'uppercase' }}
          >
            <AppBreadcrumb />
          </div>
            {children}
          <FloatingNav />
        </div>
      </div>
    </div>
  );
};

export default Layout;
