import { memo, useMemo, useEffect, Dispatch, SetStateAction } from 'react';
import { useLocation } from 'react-router';

import { SurveyCreatorComponent, SurveyCreator } from 'survey-creator-react';
import { surveyLocalization } from 'survey-react-ui';
import 'survey-core/survey.i18n';

import './SurveyJSCreatorCustomProperties';

import { useAPI } from 'context/api-context';

import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';

import { Questionnaire as QuestionnaireInterface } from 'shared/interfaces';

surveyLocalization.supportedLocales = ['en', 'de', 'fr', 'it', 'es', 'tr', 'ar'];

const creatorOptions = {
  showLogicTab: true,
  isAutoSave: true,
  showTranslationTab: true,
};

interface SurveyJSCreatorWidgetProps {
  setSurveyData: Dispatch<SetStateAction<string | null>>;
}

const SurveyJSCreatorWidget = ({ setSurveyData }: SurveyJSCreatorWidgetProps) => {
  const { fetchAPI } = useAPI();

  const location = useLocation();
  const state = location.state as QuestionnaireInterface;

  const creator = useMemo(() => new SurveyCreator(creatorOptions), []);

  creator.haveCommercialLicense = true;
  creator.showSurveyTitle = false;

  // We need to fetch data here, because SurveyJS is not happy about passing values as props to it
  useEffect(() => {
    async function fetchData() {
      if (state) {
        const response = (await fetchAPI(`/surveys/${state.id}/`, {
          method: 'GET',
          withToken: true,
        })) as QuestionnaireInterface;

        creator.text = response.surveyJSJson || '';
        setSurveyData(creator.JSON);
      }
    }

    fetchData();
  }, [fetchAPI, state, creator, setSurveyData]);

  creator.saveSurveyFunc = () => {
    creator.validateSelectedElement();
    setSurveyData(creator.JSON);
  };

  return <SurveyCreatorComponent creator={creator} />;
};

export default memo(SurveyJSCreatorWidget);
