import { useState, useEffect } from 'react';

import { useAPI } from 'context/api-context';
import { useLocale } from 'context/locale-context';
import { useClinics } from 'context/clinics-context';
import { useAuth } from 'context/auth-context';
import { useSettings } from 'context/settings-context';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { TransferUserData } from './TransferUserData';
import DownloadPDF from 'components/DownloadPDF/DownloadPDF';
import ProtectedConfirmDialog from 'components/ProtectedConfirmDialog';

import makeDate from 'shared/helpers/makeDate';
import {
  Person,
  Questionnaire,
  QuestionnairesResponse,
  ErrorResponse,
  DeleteResponse,
} from 'shared/interfaces';

import { QuestionnairesListColumn } from 'shared/types/Questionnaire';

interface QuestionnairesProps {
  userId: number;
  person: Person | undefined;
}

const Questionnaires = ({ userId, person }: QuestionnairesProps) => {
  const { fetchAPI } = useAPI();
  const { getLocaleOption, locale } = useLocale();
  const { isInterfaceEnabled } = useClinics();
  const { checkUserPrivileges } = useAuth();
  const { somethingWentWrongToast } = useSettings();

  const isSeePrivateDocumentsAllowed = checkUserPrivileges('DOWNLOAD_FOREIGN_FILES');

  const [userQuestionnaires, setUserQuestionnaires] = useState<Questionnaire[]>([]);
  const [fetching, setFetching] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [globalFilter, setGlobalFilter] = useState<string | null>(null);
  const [confirmationDialogIsVisible, setConfirmationDialogIsVisible] = useState(false);
  const [idToDelete, setIdToDelete] = useState<number | null>(null);

  useEffect(() => {
    const getUserQuestionnaires = async () => {
      setFetching(true);
      setErrorMessage('');

      const response: QuestionnairesResponse & ErrorResponse = await fetchAPI(
        `/usersurveys/?user_id=${userId}`,
        {
          withToken: true,
        }
      );

      if (response.error) {
        return setErrorMessage(response.message);
      }

      const questionnaires = [...response.items].filter((q) => !q.deletedOn);

      setUserQuestionnaires(questionnaires);
      setFetching(false);
    };

    getUserQuestionnaires();

    return () => setUserQuestionnaires([]);
  }, [fetchAPI, userId]);

  const searchHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setGlobalFilter(e.currentTarget.value);
  };

  const tableHeaderTemplate = (
    <div className='table-header flex align-items-center justify-content-between'>
      <h5 className='mx-0 my-1'>{getLocaleOption('questionnaires')}</h5>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          onInput={searchHandler}
          placeholder={`${getLocaleOption('search')}...`}
        />
      </span>
    </div>
  );

  const statusBodyTemplate = (data: QuestionnairesListColumn) => {
    const { completed } = data;

    return (
      <span className={`table-status-badge ${completed ? 'success' : 'alert'}`}>
        {completed ? getLocaleOption('completed') : getLocaleOption('notCompleted')}
      </span>
    );
  };

  const questionnaireSubmittedOnBodyTemplate = (data: QuestionnairesListColumn) =>
    data.completedOn && makeDate(new Date(data.completedOn), locale);

  const questionnaireNameBodyTemplate = (data: QuestionnairesListColumn) => (
    <span className='font-bold'>{data.name}</span>
  );

  const transferUserDataBodyTemplate = (data: QuestionnairesListColumn) => {
    return (
      <TransferUserData
        person={person}
        data={data}
        userQuestionnaires={userQuestionnaires}
        setUserQuestionnaires={setUserQuestionnaires}
      />
    );
  };

  const downloadButtonTemplate = (
    questionnaireId: number,
    person: Person
  ) => (
    <DownloadPDF
      fileName={`${person.firstName}_${person.lastName}_${person.birthday}`}
      url={`/usersurveys/${questionnaireId}/_print/`}
    />
  );

  const handleDeleteQuestionnaire = async (questionnaireId: number | null) => {
    if (questionnaireId) {
      const response: DeleteResponse = await fetchAPI(
        `/usersurveys/${questionnaireId}/`,
        {
          method: 'DELETE',
          withToken: true,
        }
      );

      if (response.deleted) {
        setUserQuestionnaires(
          [...userQuestionnaires].filter((q) => q.id !== questionnaireId)
        );
      } else {
        somethingWentWrongToast();
      }

      setIdToDelete(null);
    }
  };

  const deleteConfirmDialog = (questionnaireId: number | null) => {
    setConfirmationDialogIsVisible(true);
    setIdToDelete(questionnaireId);
  };

  const deleteQuestionnaireBodyTemplate = (data: QuestionnairesListColumn) => (
    <Button
      label={getLocaleOption('delete')}
      className='p-button-danger'
      onClick={() => deleteConfirmDialog(data.id)}
    />
  );

  return errorMessage ? (
    <h5 className='mb-0'>{errorMessage}</h5>
  ) : (
    <>
      <DataTable
        value={userQuestionnaires}
        responsiveLayout='scroll'
        showGridlines
        paginator
        paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
        globalFilterFields={['name']}
        currentPageReportTemplate={getLocaleOption('tableNumberingTemplate')}
        rows={10}
        rowsPerPageOptions={[10, 20, 50]}
        loading={fetching}
        globalFilter={globalFilter}
        header={tableHeaderTemplate}
        emptyMessage={getLocaleOption('tableNoAvailableOptions')}
      >
        <Column
          field='name'
          header={getLocaleOption('questionnaireName')}
          body={questionnaireNameBodyTemplate}
        />
        {person && (
          <Column
            align='center'
            header={getLocaleOption('status')}
            body={statusBodyTemplate}
          />
        )}
        {person && (
          <Column
            align='center'
            header={getLocaleOption('questionnaireSubmittedOn')}
            body={questionnaireSubmittedOnBodyTemplate}
          />
        )}
        {isInterfaceEnabled && person && (
          <Column
            align='center'
            header={getLocaleOption('transferDataToClinicSystem')}
            body={transferUserDataBodyTemplate}
          />
        )}
        {isSeePrivateDocumentsAllowed && person && (
          <Column
            header={getLocaleOption('download')}
            body={(data) =>
              downloadButtonTemplate(
                data.id,
                person
              )
            }
          />
        )}
        <Column
          field='delete'
          header={getLocaleOption('delete')}
          body={deleteQuestionnaireBodyTemplate}
        />
      </DataTable>
      <ProtectedConfirmDialog
        visible={confirmationDialogIsVisible}
        setVisible={setConfirmationDialogIsVisible}
        messageLocaleOption='confirmDeleteQuestionnaire'
        acceptLabelLocaleOption='delete'
        onAccept={() => handleDeleteQuestionnaire(idToDelete)}
      />
    </>
  );
};

export default Questionnaires;
