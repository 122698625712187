import { Dispatch, SetStateAction, useState, useMemo } from 'react';

import { useLocale } from 'context/locale-context';

import { ConfirmDialog } from 'primereact/confirmdialog';

import { Input } from './Inputs';

interface ProtectedConfirmDialogProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  headerLocaleOption?: string;
  messageLocaleOption: string;
  acceptLabelLocaleOption: string;
  rejectLabelLocaleOption?: string;
  confirmationKeyLocaleOption?: string;
  onAccept?(): void;
  onReject?(): void;
}

const ProtectedConfirmDialog = ({
  visible,
  setVisible,
  headerLocaleOption = 'confirmation',
  messageLocaleOption,
  acceptLabelLocaleOption,
  rejectLabelLocaleOption = 'cancel',
  confirmationKeyLocaleOption = 'deleteConfirmationKey',
  onAccept = () => false,
  onReject = () => false,
}: ProtectedConfirmDialogProps) => {
  const { getLocaleOption } = useLocale();

  const [inputValue, setInputValue] = useState('');

  const confirmationKey = useMemo(
    () => getLocaleOption(confirmationKeyLocaleOption).toLocaleLowerCase(),
    [getLocaleOption, confirmationKeyLocaleOption]
  );

  const closeAndClearInput = () => {
    setVisible(false);
    setInputValue('');
  };

  const handleAccept = () => {
    closeAndClearInput();
    onAccept();
  };

  const handleReject = () => {
    closeAndClearInput();
    onReject();
  };

  return (
    <ConfirmDialog
      visible={visible}
      onHide={closeAndClearInput}
      header={getLocaleOption(headerLocaleOption)}
      icon='pi pi-exclamation-triangle'
      message={getLocaleOption(messageLocaleOption)}
      footer={
        <>
          <Input id='confirm-dialog-input' value={inputValue} setValue={setInputValue} />
          <button
            className='p-button p-component p-confirm-dialog-reject p-button-text'
            onClick={handleReject}
          >
            <span className='p-button-label p-c'>
              {getLocaleOption(rejectLabelLocaleOption)}
            </span>
          </button>
          <button
            disabled={inputValue.toLocaleLowerCase() !== confirmationKey}
            className='p-button p-component p-confirm-dialog-accept'
            onClick={handleAccept}
          >
            <span className='p-button-label p-c'>
              {getLocaleOption(acceptLabelLocaleOption)}
            </span>
          </button>
        </>
      }
    />
  );
};

export default ProtectedConfirmDialog;
