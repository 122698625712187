import { useState } from 'react';
import { useLocale } from 'context/locale-context';
import { useDownloadPDF } from './useDownloadPDF';
import { Button } from 'primereact/button';

import { DownloadPDFProps } from './DownloadPDF.types';

const DownloadPDF = (props: DownloadPDFProps) => {
  const { getLocaleOption } = useLocale();

  const [fetching, setFetching] = useState(false);

  const { downloadPDF } = useDownloadPDF(props);

  const handleDownloadPDF = async () => {
    setFetching(true);
    await downloadPDF();
    setFetching(false);
  };

  return (
    <Button
      loading={fetching}
      label={`${getLocaleOption('download')} PDF`}
      onClick={handleDownloadPDF}
    />
  );
};

export default DownloadPDF;
