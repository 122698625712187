import { Link } from 'react-router-dom';

import LocaleSelector from './LocaleSelector';
import AppMenu from './AppMenu';
import { MenuItem } from './Layout';

interface AppTopbarProps {
  menu: MenuItem[];
  menuMode: string;
  menuActive: boolean;
  staticMenuMobileActive: boolean;
  topbarNotificationMenuActive: boolean;
  topbarUserMenuActive: boolean;
  onMenuButtonClick: (event: any) => void;
  onSearchClick: () => void;
  onTopbarNotification: () => void;
  onTopbarUserMenu: (event: React.MouseEvent<HTMLElement>) => void;
  onMenuClick: () => void;
  onMenuitemClick: (event: any) => void;
  onRootMenuitemClick: () => void;
  logo: string | null;
}

const AppTopbar = ({
  menu,
  menuActive,
  menuMode,
  onMenuButtonClick,
  onMenuClick,
  onMenuitemClick,
  onRootMenuitemClick,
  staticMenuMobileActive,
  logo,
}: AppTopbarProps) => {
  return (
    <div className='layout-topbar'>
      <div className='topbar-left'>
        <button type='button' className='menu-button p-link' onClick={onMenuButtonClick}>
          <i className='pi pi-chevron-left' />
        </button>

        {logo && (
          <Link to='/'>
            <img
              id='logo-horizontal'
              className='horizontal-logo'
              src={logo}
              alt='diamond-layout'
            />
          </Link>
        )}

        <span className='topbar-separator' />

        <div
          className='layout-breadcrumb viewname'
          style={{ textTransform: 'uppercase' }}
        />
        {logo && (
          <img id='logo-mobile' className='mobile-logo' src={logo} alt='diamond-layout' />
        )}
      </div>

      <AppMenu
        model={menu}
        menuMode={menuMode}
        active={menuActive}
        mobileMenuActive={staticMenuMobileActive}
        onMenuClick={onMenuClick}
        onMenuitemClick={onMenuitemClick}
        onRootMenuitemClick={onRootMenuitemClick}
        logo={logo}
      />

      <div className='layout-mask modal-in' />

      <div className='topbar-right'>
        <ul className='topbar-menu'>
          <li className='right-sidebar-item'>
            <LocaleSelector />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AppTopbar;
