import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {InputMask} from 'primereact/inputmask';
import {InputNumber} from 'primereact/inputnumber';
import {InputTextarea} from 'primereact/inputtextarea';
import {Divider} from 'primereact/divider';

import {InputLabel, InputContainer, InputErrorMessage} from './';

import {useLocale} from '../../context/locale-context';

export interface InputError {
  [key: string]: boolean;
}

interface InputProps {
  type?: React.HTMLInputTypeAttribute;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  id: string;
  label?: string;
  error?: boolean;
  errorMessage?: string;
  showErrorMessage?: boolean;
  inputMask?: boolean;
  mask?: string;
  slotChar?: string;
  textarea?: undefined | boolean;
  disabled?: boolean;
  maxlength?: number;
  minValue?: string | number | Date;
  maxValue?: string | number | Date;
  passwordFeedback?: boolean;
  placeholder?: string;
  useNumberGrouping?: boolean;
  showNumberButtons?: boolean;
  noGutter?: boolean;
}

export const Input = (
  {
    type,
    value,
    setValue,
    id,
    label,
    error = false,
    showErrorMessage = true,
    errorMessage,
    inputMask,
    mask,
    slotChar,
    textarea,
    disabled,
    maxlength,
    minValue,
    maxValue,
    passwordFeedback = false,
    placeholder,
    useNumberGrouping,
    showNumberButtons = true,
    noGutter,
  }: InputProps) => {
  const {getLocaleOption} = useLocale();

  const passwordHeader = <h6>{getLocaleOption('pickPassword')}</h6>;
  const passwordFooter = (
    <>
      <Divider/>
      <p className='mt-2'>{getLocaleOption('suggestions')}</p>
      <ul className='pl-2 ml-2 mt-0' style={{lineHeight: '1.5'}}>
        <li>{getLocaleOption('suggestionsOneLowercase')}</li>
        <li>{getLocaleOption('suggestionsOneUppercase')}</li>
        <li>{getLocaleOption('suggestionsOneNumeric')}</li>
        <li>{getLocaleOption('suggestionsMinCharacters')}</li>
      </ul>
    </>
  );

  const getInput = () => {
    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) =>
      setValue(e.target.value);
    const errorClass = error ? 'p-invalid' : '';

    if (type === 'password')
      return (
        <Password
          id={id}
          toggleMask
          feedback={passwordFeedback}
          weakLabel={getLocaleOption('weakLabel')}
          mediumLabel={getLocaleOption('mediumLabel')}
          strongLabel={getLocaleOption('strongLabel')}
          promptLabel={getLocaleOption('promptLabel')}
          header={passwordHeader}
          footer={passwordFooter}
          value={value}
          onChange={changeHandler}
          className={`${errorClass}`}
          disabled={disabled}
          placeholder={placeholder}
        />
      );
    if (type === 'number') {
      const getValue = () => {
        return {
          min: minValue ? parseInt(`${minValue}`) : undefined,
          max: maxValue ? parseInt(`${maxValue}`) : undefined,
        };
      };
      return (
        <InputNumber
          id={id}
          value={value === '' ? undefined : parseInt(value)}
          onValueChange={(e) => setValue(`${e.value ? e.value : 0}`)}
          showButtons={showNumberButtons}
          mode='decimal'
          disabled={disabled}
          min={getValue().min}
          max={getValue().max}
          placeholder={placeholder}
          useGrouping={useNumberGrouping}
        />
      );
    }
    if (textarea)
      return (
        <InputTextarea
          value={value}
          id={id}
          onChange={(e) => setValue(e.target.value)}
          autoResize
          rows={3}
          cols={30}
          disabled={disabled}
          placeholder={placeholder}
        />
      ); // <InputTextarea placeholder="Your Message" autoResize rows="3" cols="30"  />

    if (inputMask)
      return (
        <InputMask
          mask={mask}
          id={id}
          value={value}
          onChange={(e) => setValue(e.value)}
          className={`${errorClass}`}
          slotChar={slotChar}
          disabled={disabled}
          placeholder={placeholder}
        />
      );

    return (
      <InputText
        type={type}
        id={id}
        value={value}
        onChange={changeHandler}
        className={`${errorClass}`}
        disabled={disabled}
        placeholder={placeholder}
        maxLength={maxlength}
      />
    );
  };

  return (
    <InputContainer noGutter={noGutter}>
      <InputLabel id={id} error={error}>
        {label}
      </InputLabel>
      <div>{getInput()}</div>
      {error && showErrorMessage && <InputErrorMessage/>}
    </InputContainer>
  );
};

export default Input;
