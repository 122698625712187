import React, { useState } from 'react';

import {
  Input,
  Dropdown,
  Calendar,
  Radio,
  Checkbox,
  Signature,
  MultiValue,
  Divider,
  BMI,
  CustomFileUpload,
} from './Inputs';
import { CalendarDate } from './Inputs';

import { CheckedElement } from '../shared/interfaces';
import Editor from './Inputs/Editor';

const AllComponents = () => {
  const [textDisabled, setTextDisabled] = useState('disabled value');
  const [text, setText] = useState('');
  const [mask, setMask] = useState('');
  const [number, setNumber] = useState('0');

  const [cities, setCities] = useState<CheckedElement[]>([
    { title: 'New York', checked: true },
    { title: 'Rome', checked: false },
    { title: 'London', checked: false },
    { title: 'Istanbul', checked: false },
    { title: 'Paris', checked: false },
  ]);

  const [citiesFilter, setCitiesFilter] = useState<CheckedElement[]>([
    { title: 'New York', checked: false },
    { title: 'Rome', checked: false },
    { title: 'London', checked: false },
    { title: 'Istanbul', checked: false },
    { title: 'Paris', checked: false },
  ]);

  const [month, setMonth] = useState<CalendarDate>(undefined);
  const [date, setDate] = useState<CalendarDate>(undefined);

  const [textarea, setTextarea] = useState('');

  const [radio, setRadio] = useState<CheckedElement[]>([
    { title: 'Chicago', checked: false },
    { title: 'Los Angeles', checked: false },
    { title: 'New York', checked: false },
  ]);

  const [maskDate, setMaskDate] = useState('');
  const [mask11, setMask11] = useState('');
  const [mask12, setMask12] = useState('');

  const [checkbox, setCheckbox] = useState<CheckedElement[]>([
    { title: 'Chicago', checked: false },
    { title: 'Los Angeles', checked: false },
    { title: 'New York', checked: false },
  ]);

  const [multiValueText, setMultiValueText] = useState(['']);
  const [multiValueDate, setMultiValueDate] = useState<CalendarDate[]>([undefined]);

  return (
    <div className='flex'>
      <div className='col-6'>
        <Editor text='' setText={(val) => console.log(val)} label='' />
        <CustomFileUpload label='test' onUpload={(files) => console.log(files)} />
        <BMI updateBMI={({ kg, cm, bmi }) => false} weight={85} height={195} />
        <Divider text='divider example' />
        <MultiValue
          label='Multi text'
          inputs={multiValueText}
          setInputs={setMultiValueText}
          type='text'
          error={true}
        />
        <MultiValue
          label='Whats your childs birthday?'
          dates={multiValueDate}
          setDates={setMultiValueDate}
          type='date'
          error={true}
        />
        <Input
          type='text'
          value={textDisabled}
          setValue={setTextDisabled}
          id='text'
          label='Input Basic ( Disabled )'
          disabled
        />
        <Input
          type='text'
          value={text}
          setValue={setText}
          id='text'
          label='Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio dicta magnam repellendus enim. Id recusandae natus, deserunt culpa iusto eos fuga dolorum saepe architecto libero voluptatem voluptates velit atque! Dolorem?'
        />
        <Input
          inputMask
          mask='99-999999'
          type='text'
          value={mask}
          setValue={setMask}
          id='text'
          label='Input mask - numbers'
        />
        <Input
          type='number'
          value={number}
          setValue={setNumber}
          id='text'
          label='Input number'
        />
        <Dropdown label='Dropdown Basic' options={cities} setOptions={setCities} />
        <Dropdown
          label='Dropdown Advanced with Templating, Filtering and Clear Icon'
          options={citiesFilter}
          setOptions={setCitiesFilter}
          advanced
        />
        <Calendar
          date={month}
          setDate={setMonth}
          type='MONTH'
          label='Calendar - Month Picker'
        />
        <Calendar
          date={date}
          setDate={setDate}
          label='Calendar - Basic'
          maxValue='15 Mar 2022'
        />
        <Checkbox items={checkbox} setItems={setCheckbox} label='Checkbox Items' />
      </div>
      <div className='col-6'>
        <Input
          textarea
          value={textarea}
          setValue={setTextarea}
          id='textarea'
          label='Textarea'
        />
        <Radio items={radio} setItems={setRadio} label='Radio Items' />
        <Input
          inputMask
          value={maskDate}
          mask='99/99/9999'
          slotChar='mm/dd/yyyy'
          setValue={setMaskDate}
          id='mask-date'
          label='Input Mask - Date'
        />
        <Input
          inputMask
          value={mask11}
          mask='(999) 999-9999'
          setValue={setMask11}
          id='mask-phone'
          label='Input Mask - Phone ( 11 numbers )'
        />
        <Input
          inputMask
          value={mask12}
          mask='(999) 999-99999'
          setValue={setMask12}
          id='mask-phone'
          label='Input Mask - Phone ( 12 numbers )'
        />
        <Signature
          id='signature'
          label='Digital Signature'
          value={{ signatureFileId: undefined, signatureText: '123test' }}
          setValue={(value) => console.log(value)}
        />
      </div>
    </div>
  );
};

export default AllComponents;
