import { useEffect, useState } from 'react';

import { useAPI } from 'context/api-context';
import { useLocale } from 'context/locale-context';

import { Button } from 'primereact/button';

import { UserFile } from 'shared/interfaces';

import { getDate } from 'shared/helpers/makeDate';

interface TransferUserFileProps {
  data: UserFile;
  files: UserFile[];
  setFiles: React.Dispatch<React.SetStateAction<UserFile[]>>;
}

export const TransferUserFile = ({ data, files, setFiles }: TransferUserFileProps) => {
  const { fetchAPI } = useAPI();
  const { getLocaleOption } = useLocale();

  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);

  const { id, partnerLastSubmissionOn } = data;

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(false), 4000);
    }
  }, [error]);

  const handleSubmit = async () => {
    setFetching(true);

    const response = await fetchAPI(`/userfiles/${id}/_submit/`, {
      method: 'POST',
      withToken: true,
    });

    if (response.message) {
      setError(true);
    } else {
      const filesMap = files.map((f) => (f.id === response.id ? response : f));
      setFiles(filesMap);
    }

    setFetching(false);
  };

  return (
    <Button
      loading={fetching}
      className={`p-button-${
        error ? 'danger' : partnerLastSubmissionOn ? 'success' : 'info'
      }`}
      label={
        error
          ? `${getLocaleOption('fileTransferFailed')}!`
          : partnerLastSubmissionOn
          ? `${getLocaleOption('fileSuccessfullyTransferred')} ${getDate(
              partnerLastSubmissionOn
            )}`
          : getLocaleOption('fileNotTransferred')
      }
      onClick={handleSubmit}
    />
  );
};
