import { NavLink } from 'react-router-dom';
import { useAuth } from '../../context/auth-context';
import { Badge } from 'primereact/badge';
import { useClinics } from '../../context/clinics-context';

const FloatingNav = () => {
  const { user, checkUserPrivileges } = useAuth();
  const { assignedClinic } = useClinics();

  const userMessageIconEnabled =
    !checkUserPrivileges('IS_CLINIC') && assignedClinic?.chatEnabled;

  return user?.person && user.totalUnreadMessages && userMessageIconEnabled ? (
    <div className='floating-nav fixed bottom-0 right-0 mr-4 mb-4'>
      <NavLink
        to={'/user-messages'}
        className='text-gray-700 hover:text-blue-500 relative'
      >
        <Badge
          value={user.totalUnreadMessages}
          style={{
            transform: 'translate(28px, 0px)',
            fontSize: '1rem',
            position: 'absolute',
          }}
          severity='danger'
        />
        <i
          className='pi pi-comments text-5xl p-3 shadow-1 border-circle'
          style={{ background: '#f7f5f1' }}
        />
      </NavLink>
    </div>
  ) : null;
};

export default FloatingNav;
