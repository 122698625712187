import { Fragment } from 'react';
import Overlay from '../../../components/Overlay';
import { UserFile } from '../../../shared/interfaces';
import FileRemarks from './FileRemarks';

const FileRemarksBody = ({ files, setFiles, label, file }: {files: UserFile[], file: UserFile, setFiles: React.Dispatch<React.SetStateAction<UserFile[]>>, label: string}) => {
  const remarks: string | undefined = file.remarks;
  return (
    <Fragment>
      <Overlay
        style={{ width: '400px' }}
        className={remarks ? 'p-button-text' : ''}
        label={label}
      >
        <FileRemarks file={file} files={files} setFiles={setFiles} />
      </Overlay>
    </Fragment>
  )
};

export default FileRemarksBody;
