interface UserDataItemProps {
  title: string;
  value: string;
}

export const UserDataItem = ({ title, value }: UserDataItemProps) => (
  <div className='flex flex-column mb-3 w-6'>
    <p className='text-lg mr-2 mb-0'>{title}: </p>
    <p className='text-lg font-semibold'>{value}</p>
  </div>
);
