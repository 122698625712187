import { Locales } from '../../context/locale-context';

const monthsEN = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const monthsDE = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
];

const monthsFR = [
  'janvier',
  'février',
  'mars',
  'avril',
  'mai',
  'juin',
  'juillet',
  'août',
  'septembre',
  'octobre',
  'novembre',
  'décembre',
];

const monthsIT = [
  'gennaio',
  'febbraio',
  'marzo',
  'aprile',
  'maggio',
  'giugno',
  'luglio',
  'agosto',
  'settembre',
  'ottobre',
  'novembre',
  'dicembre',
];

const monthsSP = [
  'enero',
  'febrero',
  'marzo',
  'abril',
  'mayo',
  'junio',
  'julio',
  'agosto',
  'septiembre',
  'octubre',
  'noviembre',
  'diciembre',
];

const monthsTR = [
  'ocak',
  'şubat',
  'mart',
  'nīsan',
  'mayıs',
  'hazīran',
  'temmuz',
  'ağustos',
  'eylül',
  'ekim',
  'kasım',
  'aralık',
];

const monthsAR = [
  'يناير',
  'فبراير',
  'يمشي',
  'أبريل',
  'ماي',
  'يونيو',
  'يوليو',
  'أغسطس',
  'سبتمبر',
  'اكتوبر',
  'نوفمبر',
  'ديسمبر',
];

const monthsPT = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

function getMonths(locale: Locales) {
  switch (locale) {
    case Locales.EN:
      return monthsEN;

    case Locales.DE:
      return monthsDE;

    case Locales.FR:
      return monthsFR;

    case Locales.IT:
      return monthsIT;

    case Locales.ES:
      return monthsSP;

    case Locales.TR:
      return monthsTR;

    case Locales.AR:
      return monthsAR;

    case Locales.PT:
      return monthsPT;

    default:
      return monthsEN;
  }
}

const makeDate: (d: Date, locale: Locales) => string = (d, locale) => {
  const date = new Date(d);
  const year = date.getFullYear();
  const month = date.getMonth();

  const months = getMonths(locale);
  const day = date.getDate();

  return d ? `${day} ${months[month]} ${year}` : ``;
};

export const getPersonBirthday = (date: string | undefined) => {
  if (date) {
    // format of birthday 1995-08-30
    const [yyyy, mm, dd] = date.split('-');
    return `${dd}.${mm}.${yyyy}`;
  }
};

export const getDate = (date: string | undefined) => {
  if (date) {
    const [yyyy, mm, dd] = date.split('T')[0].split('-');
    return `${dd}/${mm}/${yyyy}`;
  }
};

export default makeDate;
