import { Navigate, Route, Routes } from 'react-router-dom';

import { useAuth } from 'context/auth-context';
import { useClinics } from 'context/clinics-context';

import Login from 'containers/Auth/components/Login';
import ForgotPassword from 'containers/Auth/components/ForgotPassword';
import ResetPassword from 'containers/Auth/components/ResetPassword';
import Dashboard from 'containers/Dashboard';
import QuestionnaireList from 'containers/QuestionnairesList/QuestionnairesList';
import QuestionnaireRunner from 'containers/Questionnaire/QuestionnaireRunner';
import QuestionnaireBuilder from 'containers/Questionnaire/QuestionnaireBuilder';
import Users from 'containers/UsersList/UsersList';
import User from 'containers/User/User';
import DiagnosticFindings from 'containers/DiagnosticFindings';
import Clinics from 'containers/Clinics/Clinics';
import CreateEditClinic from 'containers/Clinics/components/CreateEditClinic';
import Mails from 'containers/Mails';
import Messages from 'containers/Messages';
import Info from 'containers/Info';

import AllComponents from 'components/AllComponents';
import AccountSettings from './containers/AccountSetings/AccountSettings';
import { SurveyType } from './shared/interfaces';
import TermsAndConditions from './containers/Clinics/TermsAndConditions';

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/login' element={<Login />} />
      <Route path='/invite' element={<Login />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/reset-password' element={<ResetPassword />} />
      <Route path='*' element={<Navigate to='/login' replace />} />
    </Routes>
  );
};

const MainRoutes = () => {
  const { assignedClinic } = useClinics();
  const { userCompleted, checkUserPrivileges, user } = useAuth();

  const navigate = () => {
    if (userCompleted) {
      return checkUserPrivileges('IS_CLIENT') ? '/dashboard' : '/users';
    }

    return '/user';
  };

  return (
    <Routes>
      {userCompleted && user && (
        <>
          <Route path='/' element={<Navigate to='/dashboard' replace />} />
          {checkUserPrivileges('VIS_MENU__ADMIN__QUESTIONNAIRE') && (
            <Route
              path='/questionnaires/medical'
              element={<QuestionnaireList surveyType={SurveyType.MEDICAL} />}
            />
          )}
          {checkUserPrivileges('VIS_MENU__ADMIN__QUESTIONNAIRE') && (
            <Route
              path='/questionnaires/medical-pdf'
              element={<QuestionnaireList surveyType={SurveyType.MEDICAL_PDF} />}
            />
          )}
          {checkUserPrivileges('VIS_MENU__ADMIN__QUESTIONNAIRE') && (
            <Route
              path='/questionnaires/base-data'
              element={<QuestionnaireList surveyType={SurveyType.BASE_DATA} />}
            />
          )}
          {checkUserPrivileges('VIS_MENU__MY_QUESTIONNAIRES') && (
            <Route path='/questionnaires/patient' element={<QuestionnaireList />} />
          )}
          <Route path='/questionnaire/:id' element={<QuestionnaireRunner />} />
          {checkUserPrivileges('VIS_MENU__DASHBOARD') && (
            <Route path='/dashboard' element={<Dashboard />} />
          )}
          {checkUserPrivileges('VIS_MENU__ADMIN__QUESTIONNAIRE_SPEC') && (
            <Route path='/questionnaire-builder' element={<QuestionnaireBuilder />} />
          )}
          {!checkUserPrivileges('IS_CLINIC') &&
            assignedClinic?.chatEnabled &&
            user.messages && (
              <Route
                path='/user-messages'
                element={<Messages user={user} assignedClinic={assignedClinic} />}
              />
            )}
          {checkUserPrivileges('VIS_MENU__ADMIN__USERS') && (
            <Route path='/users' element={<Users />} />
          )}
          {checkUserPrivileges('VIS_MENU__ADMIN__USERS') && (
            <Route path='/archived-users' element={<Users archived />} />
          )}
          {checkUserPrivileges('VIS_MENU__ADMIN_DIAGNOSTIC__FINDINGS') && (
            <Route path='/my-diagnostic-findings' element={<DiagnosticFindings />} />
          )}
          {checkUserPrivileges('VIS_MENU__ADMIN__CLINIC') && (
            <Route path='/clinics' element={<Clinics />} />
          )}
          {checkUserPrivileges('VIS_MENU__ADMIN__CLINIC') && (
            <Route path='/create-clinic' element={<CreateEditClinic />} />
          )}
          {checkUserPrivileges('VIS_MENU__ADMIN__CLINIC') && (
            <Route path='/edit-clinic' element={<CreateEditClinic />} />
          )}
          {(checkUserPrivileges('IS_CLIENT') || checkUserPrivileges('IS_FERTILLY')) && (
            <Route path='/info' element={<Info />} />
          )}
          {checkUserPrivileges('UNRESTRICTED') && (
            <Route path='/components' element={<AllComponents />} />
          )}
          {checkUserPrivileges('IS_FERTILLY_ADMIN') && (
            <Route path='/mails' element={<Mails />} />
          )}
        </>
      )}
      <Route path='/clinic/terms-and-conditions' element={<TermsAndConditions />} />
      <Route path='/user' element={<User />} />
      <Route path='/account-settings' element={<AccountSettings />} />
      <Route path='/user/:partner' element={<User />} />
      <Route path='*' element={<Navigate to={navigate()} replace />} />
    </Routes>
  );
};

const Router = () => {
  const { user } = useAuth();

  return user ? <MainRoutes /> : <AuthRoutes />;
};

export default Router;
