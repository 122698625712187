import React from 'react';

import { v4 as uuid } from 'uuid';

import { Checkbox as PrimeCheckbox, CheckboxChangeParams } from 'primereact/checkbox';

import { CheckedElement } from '../../shared/interfaces';

import { InputContainer, InputErrorMessage, InputLabel } from './';

interface CheckboxProps {
  items: CheckedElement[];
  setItems: React.Dispatch<React.SetStateAction<CheckedElement[]>>;
  label?: string;
  error?: boolean;
  noGutter?: boolean;
}

export const Checkbox = ({
  items,
  setItems,
  label = '',
  error,
  noGutter,
}: CheckboxProps) => {
  const changeHandler = (e: CheckboxChangeParams) => {
    const modifyItems = [...items].map((item) => {
      return {
        ...item,
        checked: item.title === e.value ? !item.checked : item.checked,
      };
    });
    setItems(modifyItems);
  };

  return (
    <InputContainer noGutter={noGutter}>
      <InputLabel error={error}>{label}</InputLabel>
      <div className='flex flex-wrap mt-2'>
        {items.map(({ title, checked, JSX }, index) => {
          const id = uuid();
          return (
            <div className='field-radiobutton mr-5 mb-1' key={index}>
              <PrimeCheckbox
                inputId={id}
                name='option'
                value={title}
                checked={checked}
                onChange={changeHandler}
              />
              <label htmlFor={id}>{JSX ? JSX : title}</label>
            </div>
          );
        })}
      </div>
      {error && <InputErrorMessage />}
    </InputContainer>
  );
};

export default Checkbox;
