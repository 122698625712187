import { useClinics } from '../../context/clinics-context';
import { FC, useEffect, useState } from 'react';

const TermsAndConditions: FC = () => {

  const { assignedClinic } = useClinics();
  const [termsAndConditions, setTermsAndConditions] = useState<string>();

  useEffect(() => {
    if (assignedClinic &&
      assignedClinic.termsAndConditions &&
      assignedClinic.termsAndConditions !== termsAndConditions
    ) {
      setTermsAndConditions(assignedClinic.termsAndConditions);
    }
  }, [assignedClinic, termsAndConditions]);

  return termsAndConditions ? (
      <div className='container pt-8 pb-8'>
        <div dangerouslySetInnerHTML={{ __html: termsAndConditions }}></div>
      </div>
  ) : null;
};

export default TermsAndConditions;