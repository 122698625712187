import { EntityState } from './';

export enum ClinicProperties {
  noInterface = 'NO_INTERFACE',
  meditex = 'MEDITEX',
  vrepro = 'VREPRO',
  gdt = 'GDT',
}

export enum ClinicLogoOptionCodes {
  none = 'NONE',
  clinic = 'CLINIC_LOGO',
  fertilly = 'FERTILLY_LOGO',
}

export enum ClinicLogoOptionNames {
  none = 'None',
  clinic = 'Clinic logo',
  fertilly = 'Fertilly logo',
}

export interface Clinic {
  createdBy: string; //The user, who created this Entity
  createdOn: string; // The created Timestamp for this Entity
  deletedBy: string; // The user, who deleted this Entity
  deletedOn: string; // The deleted Timestamp for this Entity
  entityState: EntityState; //The current Entity State
  id: number; // The database generated Id for this Entity
  logoFileUUID: string; // text value (UUID of FILE)
  name: string; // Name of the Clinic
  secretKey: string;
  rowVersion: number; // The current Version of this Entity; Used for (safe) Update Operations
  updatedBy: string; // The user, who updated this Entity
  updatedOn: string; // The updated Timestamp for this Entity
  termsAndConditions: string;
  clinicProperty: ClinicProperties;
  chatEnabled: boolean;
  chatFromUserEnabled: boolean;
  showLogo: ClinicLogoOptionCodes;
  showInfoPage: boolean;
  surveyTreatmentPlaceEnabled: boolean;
}
