import { useLocale } from 'context/locale-context';

import { UserState } from 'shared/interfaces';

interface UserStatusBadgeProps {
  status: UserState;
}

const getStatusBadge = (status: UserState) => {
  switch (status) {
    case UserState.INVITED:
      return { title: 'statusInvited', color: 'invited' };

    case UserState.REGISTERED:
      return { title: 'statusRegistered', color: 'registered' };

    case UserState.ANAMNESIS_INCOMPLETE:
      return { title: 'statusAnamnesisIncomplete', color: 'partly-completed' };

    case UserState.ANAMNESIS_COMPLETE:
      return { title: 'completed', color: 'completed' };

    case UserState.ANAMNESIS_SUBMITTED:
      return { title: 'statusAnamnesisSubmitted', color: 'submitted' };

    case UserState.ANAMNESIS_TRANSFERRED:
      return { title: 'statusAnamnesisTransferred', color: 'transferred' };

    case UserState.ANAMNESIS_PARTIALLY_TRANSFERRED:
      return { title: 'statusAnamnesisPartiallySubmitted', color: 'partly-transferred' };

    default:
      return { title: '', color: '' };
  }
};

const UserStatusBadge = ({ status }: UserStatusBadgeProps) => {
  const { getLocaleOption } = useLocale();

  const { title, color } = getStatusBadge(status);

  return (
    <span className={`table-status-badge ${color}`}>
      {title && getLocaleOption(title)}
    </span>
  );
};

export default UserStatusBadge;
