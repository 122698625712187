import { useNavigate } from 'react-router';

import { useQuestionnaireRunner } from './useQuestionnaireRunner';

import Layout from 'components/Layout/Layout';
import { QuestionnaireRunnerLayout } from './QuestionnaireRunnerLayout';

const QuestionnaireRunner = () => {
  const { fetching, currentSurveyCompleted, survey } = useQuestionnaireRunner();
  const navigate = useNavigate();

  return (
    <Layout>
      <QuestionnaireRunnerLayout
        surveyFetching={fetching}
        surveyCompleted={currentSurveyCompleted}
        survey={survey}
        onAfterCompleted={() => navigate(-1)}
      />
    </Layout>
  );
};

export default QuestionnaireRunner;
