import React, { useCallback, useState } from 'react';

import { useLocale } from 'context/locale-context';
import { useAuth } from 'context/auth-context';
import { useAPI } from 'context/api-context';
import { useSettings } from 'context/settings-context';

import { Button } from 'primereact/button';

import ProtectedConfirmDialog from 'components/ProtectedConfirmDialog';

import { DeleteResponse } from 'shared/interfaces';

const DeleteButton = () => {
  const { getLocaleOption } = useLocale();
  const { logout, user } = useAuth();
  const { fetchAPI } = useAPI();
  const { showToast } = useSettings();

  const [deletionConfirmationDialogIsVisible, setDeletionConfirmationDialogIsVisible] =
    useState(false);

  const deleteAccountHandler = useCallback(
    async (userId: number) => {
      const response: DeleteResponse = await fetchAPI(`/users/${userId}/delete/`, {
        method: 'DELETE',
        withToken: true,
      });

      if (response.deleted) {
        logout();
      } else {
        showToast({
          type: 'error',
          title: getLocaleOption('error'),
          text: getLocaleOption('somethingWentWrong'),
        });
      }
    },
    [fetchAPI, getLocaleOption, logout, showToast]
  );

  return (
    <>
      <Button
        label={getLocaleOption('deleteAccount')}
        className='p-button-danger'
        onClick={() => setDeletionConfirmationDialogIsVisible(true)}
      />
      <ProtectedConfirmDialog
        visible={deletionConfirmationDialogIsVisible}
        setVisible={setDeletionConfirmationDialogIsVisible}
        messageLocaleOption='confirmDeleteAccount'
        acceptLabelLocaleOption='deleteAccountForSure'
        onAccept={() => deleteAccountHandler(user?.id as number)}
      />
    </>
  );
};

export default DeleteButton;
