import React, { useState } from 'react';

import { useLocale } from 'context/locale-context';
import { useAPI } from 'context/api-context';
import { useSettings } from 'context/settings-context';
import { useAuth } from 'context/auth-context';

import { InputSwitch } from 'primereact/inputswitch';

import { ErrorResponse, User } from 'shared/interfaces';

interface TwoFactorAuthenticationProps {
  mfaEnabled: boolean;
}

const TwoFactorAuthentication = ({ mfaEnabled }: TwoFactorAuthenticationProps) => {
  const { getLocaleOption } = useLocale();
  const { fetchAPI } = useAPI();
  const { showToast } = useSettings();
  const { setUser } = useAuth();

  const [is2FAEnabled, setIs2FAEnabled] = useState(mfaEnabled);
  const [loading, setLoading] = useState(false);

  const toggle2FaHandler = async () => {
    setLoading(true);

    const response: User & ErrorResponse = await fetchAPI(
      `/users/mfa/${is2FAEnabled ? 'disable' : 'enable'}/`,
      {
        method: 'PUT',
        withToken: true,
      }
    );

    if (response.error) {
      showToast({
        type: 'error',
        title: getLocaleOption('error'),
        text: response.message,
      });
    }

    setIs2FAEnabled(response.mfaEnabled);
    setUser(response);
    setLoading(false);
  };

  return (
    <InputSwitch checked={is2FAEnabled} onChange={toggle2FaHandler} disabled={loading} />
  );
};

export default TwoFactorAuthentication;
