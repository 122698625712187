function obscureString(str: string) {
  return str[0] + '*'.repeat(str.length - 2) + str[str.length - 1];
}

export function obscureEmail(email: string) {
  const [name, domain] = email.split('@');
  const [server, subdomain] = domain.split('.');

  const obscuredName = obscureString(name);
  const obscuredServer = obscureString(server);

  return obscuredName + '@' + obscuredServer + '.' + subdomain;
}
