import { useState } from 'react';

import { useAPI } from 'context/api-context';
import { useLocale } from 'context/locale-context';
import { useSettings } from 'context/settings-context';

import { Button } from 'primereact/button';
import { Input } from 'components/Inputs';

import { TableUser } from '../UsersList';

interface UserRemarksProps {
  user: TableUser;
  users: TableUser[];
  setUsers: React.Dispatch<React.SetStateAction<TableUser[]>>;
}

const UserRemarks = ({
                                   user,
                                   users,
                                   setUsers,
                                 }: UserRemarksProps) => {
  const { fetchAPI } = useAPI();
  const { getLocaleOption } = useLocale();
  const { showToast } = useSettings();

  const [remarks, setRemarks] = useState(user.remarks || '');

  const submitHandler = async () => {
    const json = await fetchAPI(`/users/${user.id}/remarks/`, {
      method: 'PATCH',
      withToken: true,
      body: JSON.stringify({ remarks, rowVersion: 0 }),
    });

    if (json.code) {
      // error
      return showToast({
        type: 'error',
        title: getLocaleOption('error'),
        text: json.message,
      });
    }

    const usersMap: TableUser[] = users.map((u: TableUser) => (u.id === json.id ? json : u));
    setUsers(usersMap);

    showToast({
      type: 'success',
      title: getLocaleOption('success'),
      text: 'Assigned',
    });
  };

  return (
    <div className='relative grid mb-0'>
      <div className='col-12'>
        <Input
          id='remarks'
          label={getLocaleOption('remarks')}
          value={remarks}
          setValue={setRemarks}
        />
        <Button label={getLocaleOption('submit')} onClick={submitHandler} />
      </div>
    </div>
  );
};

export default UserRemarks;
