import { Button } from 'primereact/button';
import { HandleUserOption } from '../models/enums/HandleUserOption';

const UsersListConfirmDialog = (props: {label: string, option: HandleUserOption, onUserHandleClick: () => void}) => {
  const className: string = props.option === HandleUserOption.reactivate ? 'p-button-success' : 'p-button-danger';
  return (
    <Button
      label={props.label}
      className={className}
      onClick={() => props.onUserHandleClick()}
    />
  );
};

export default UsersListConfirmDialog;
